<template>
  <div class="newsCardArea" @click="showNewsDetailModal(item.post_id)">

    <div class="newsThumbnail">
      <img
        src="../../assets/images/transparent/600x400.png"
        :style="{ backgroundImage: `url(${(item.img[0].post_img_hd_wm_path)?Utils.replaceImageUrl(item.img[0].post_img_hd_wm_path, item.post_id, 1):'image/transparent/600x400.png'})` }"
      >

      <div class="newsDate">
        {{ DatepickerHelper.dateStr2thFormat(item.post_create_date) }}
      </div>

      <div class="newsAuthor">
        <div class="newsAuthorProfile">
          <img
            src="../../assets/images/transparent/400x400.png"
            :style="{ backgroundImage: `url(${(item.member_img_profile)?Utils.replaceImageUrl(item.member_img_profile, item.post_id, 2):'image/navbar/profileIcon.png'})` }"
          >
        </div>

          {{ item.member_displayname }}

        <div class="newsAuthorBadge">
          <img
            v-for="badge in item.badge"
            :src="badge.badge_img_url"
          >
        </div>
      </div>
    </div>

    <div class="newsDetailArea">
      <div class="newsHeadline">
        {{ item.post_header }}
      </div>
      <div class="newsShortDetail">
        {{ item.post_detail }}
      </div>
      <div class="newsHashtag">
        {{ item.tag_display }}
      </div>
    </div>

    <div class="row newsCountArea g-0">
      <div class="col">
        <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'eye']" /> {{ item.post_view_count }}</div>
      </div>
      <div class="col">
        <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'heart']" /> {{ item.post_like_count }}</div>
      </div>
      <div class="col">
        <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'message']" /> {{ item.post_comment_count }}</div>
      </div>
    </div>

  </div>
</template>


<script setup>
  import { ref, onMounted, defineProps, watch, getCurrentInstance } from 'vue';
  import DatepickerHelper from '@/plugins/datepickerHelper.js';
  import Utils from '@/plugins/utils.js';
  import eventBus from '@/eventBus/eventBus.js';

  // variable zone
  const props = defineProps(['item', 'lastPage']);
  const instance = getCurrentInstance();
  const { lastTopicID, s3DevUrl, s3RedesignUrl } = instance.appContext.config.globalProperties.$globalVar;
 
  // end variable zone

  // function zone
  function showNewsDetailModal (topicID) {
    const obj = {
      postID: topicID,
      lastPage: props.lastPage,
      memberID: props.item.post_by
    };
    eventBus.emit('hideReporterDetailAddNewsModal'); 
    eventBus.emit('showNewsDetailModal', obj); 
  }
  // end function zone

</script>