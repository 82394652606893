<template>
  <div class="modal fade" id="followerListModal" tabindex="-1" role="dialog" aria-labelledby="followerListLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="followerListLabel">{{t('following')}}</h5>
          <button 
            type="button" 
            data-bs-dismiss="modal" 
            aria-label="Close"
            class="btn-close">
          </button>
        </div>
        <div class="modal-body">
          <div class="row" v-for="item in followerList">
            <div class="col-xl-12 col-lg-12 col-md-12 profileFollowerList hoverUp">
              <div class="d-flex">
                <img 
                  class="imgThumbMedium imgBg me-2 rounded-circle" 
                  src="../../assets/images/transparent/400x400.png" 
                  :style="{ backgroundImage: `url(${Utils.replaceImageUrl(item.member_img_profile, 0, 2) })` }"
                  @click="showReporterDetailModal(item.member_id_follow)"
                >
                <div class="align-self-center">
                  <p class="prd-name">{{ item.member_displayname }}</p>
                  <button type="button" class="btn btn-danger btn-sm py-1 px-2" @click="unFollow(item)">
                    <font-awesome-icon :icon="['fas', 'user-xmark']" class="me-2" /> {{t('unfollow')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  // import zone
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import Utils from '@/plugins/utils.js';
  import { useI18n } from 'vue-i18n';
  import eventBus from '@/eventBus/eventBus.js';
  // variable zone
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const { t } = useI18n();
  const followerListModal = ref(null);
  const followerList = ref([]);
  // end valiable zone

  // function zone
  const showReporterDetailModal = (memberID) => {
    hideModal();
    const params = {
      lastPage: 'followerListModal',
      postID: 0,
      memberID: memberID
    }
    eventBus.emit('showReporterDetailModal', params);
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal'); 
  };

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal'); 
    }, 500);
  };

  const showModal = () => {
    showLoadingModal();
    getFollowerList();
    if (!followerListModal.value) {
      followerListModal.value = new bootstrap.Modal(document.getElementById('followerListModal'), {});
    }
    followerListModal.value.show();
  };

  const hideModal = () => {
    if (followerListModal.value) {
      followerListModal.value.hide();
    }
  };

  const getFollowerList = async () => {
    try {
      const res = await callApi({
          svName: 'memberService',
          fnName: 'getFollower'
      });
      if (res && res.status === 200) {
        followerList.value = res.data.result;
      }
      hideLoadingModal();
    } catch (error) {
        console.log(error);
    }
  };

  const unFollow = async (data) => {
    try {
      showLoadingModal();
      const confirm = await new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: t('unfollow')+' '+data.member_displayname,
        showCancelButton: true,
        confirmButtonText: t('submit'),
        cancelButtonText: t('cancel'),
        reverseButtons: true
      });
      if (confirm.isConfirmed) {
        const res = await callApi({
          svName: 'memberService',
          fnName: 'unFollow',
          param: { idFollow: data.member_id_follow }
        });
        if (res && res.status === 200) {
          showModal();
        }
      }
      hideLoadingModal();
    } catch (error) {
        console.log(error);
    }
  };

  // end function zone

  onMounted(() => {
    followerListModal.value = new bootstrap.Modal(document.getElementById('followerListModal'), {});

    eventBus.on('showFollowerListModal', () => {
      showModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>