<template>
  <div class="modal fade csiteMenuModal reporterDetailModal authenAreaModal" id="surveyHistoryModal" tabindex="-1" aria-labelledby="surveyHistoryLabel" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered ">
        <div class="modal-content coinModalDetailArea">
          <button type="button" class="btnClose" data-bs-toggle="modal" data-bs-target="#surveyMenuInfoModal">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" />
          </button>
          <!-- <div class="modal-header">
            <h5 class="modal-title">
              {{ t('survey_history') }}
            </h5>
            <button type="button" class="btnClose" data-bs-toggle="modal" data-bs-target="#surveyMenuInfoModal">
              <font-awesome-icon :icon="['fas', 'circle-xmark']" />
            </button>
          </div> -->

          <div class="modal-body">
            <span v-if="nexusAnswerList.length === 0">ไม่มีประวัติการตอบ</span>
            <table class="table table-bordered" v-if="nexusAnswerList.length > 0">
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">{{ t('date_label') }}</th>
                    <th class="text-center">{{ t('time_label') }}</th>
                    <th class="text-center">{{ t('view') }}</th>
                    <th class="text-center">{{ t('edit') }}</th>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in nexusAnswerList">
                  <tr>
                    <td class="oneBtnTd">{{ index+1 }}</td>
                    <td>{{ item.last_edit_date_dt }}</td>
                    <td>{{ item.last_edit_date_tm }}</td>
                    <td class="oneBtnTd"><button type="button" class="btn btn-primary rounded-circle" @click="viewSurveyans(item, true)" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'eye']" /></button></td>
                    <td class="oneBtnTd"><button type="button" class="btn btn-primary rounded-circle" @click="viewSurveyans(item, false)" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'pen']" /></button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer d-flex justify-content-start">
              <button type="button" class="btn btn-warning me-1" data-bs-toggle="modal" data-bs-target="#surveyMenuInfoModal">
                <font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1"  /> {{ t('txt_back') }}
              </button>
            </div>
       </div>
      </div>
    </div>
</template>
<script setup>
  import { onMounted, ref, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';
  import eventBus from '@/eventBus/eventBus.js';
  import { useI18n } from 'vue-i18n';

  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);

  const { t } = useI18n();
  const surveyHistoryModal = ref(null);
  const nexusAnswerList = ref([]); 
  const nexusData = ref(null); 
  const instance = getCurrentInstance();
  const { csiteWebUrl } = instance.appContext.config.globalProperties.$globalVar;
  const memberData = ref(store.getters['auth/getMember']);
  // end valiable zone

  // function zone
  const showModal = async (data) => {
    nexusData.value = data;
    // if (!surveyHistoryModal.value) {
    //   surveyHistoryModal.value = new bootstrap.Modal(document.getElementById('surveyHistoryModal'), {});
    // }

    await getNexusAnswerList();
  };

  const hideModal = () => {
    if (surveyHistoryModal.value) {
      surveyHistoryModal.value.hide();
    }
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal'); 
  }

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal'); 
    }, 500);
  };

  const getNexusAnswerList = async () => {
    showLoadingModal();
    nexusAnswerList.value = [];

    try {
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getNexusAnswerList',
        param: nexusData.value
      });
      
      if (res.status === 200) {
        nexusAnswerList.value = res.data.result;
        console.log(nexusAnswerList.value);
      }
      
      hideLoadingModal();
      surveyHistoryModal.value.show();
    } catch (e) {
      console.log(e);
    }
  };  

  const viewSurveyans = (data, isView) => {
    let token = (isView)?data.token_view:data.token_edit;
    let strToken = '';

    if (memberData.value) {
      strToken = `&member_id=${memberData.value.member_id}&member_token=${memberData.value.member_token}`;
    }

    const url = `${csiteWebUrl}/nexusansedit?token=${token}${strToken}`;
    window.open(url, "_blank");
  };

  onMounted(() => {
    surveyHistoryModal.value = new bootstrap.Modal(document.getElementById('surveyHistoryModal'), {});
  });

  defineExpose({
    showModal,
    hideModal,
  });
</script>