<template>
  <div class="modal fade csiteMenuModal reporterDetailModal authenAreaModal" id="newsbroadcastInfoModal" tabindex="-1"
    aria-labelledby="newsbroadcastInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered ">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon
            :icon="['fas', 'circle-xmark']" /></button>
        <div class="modal-body">
          <img src="../../assets/images/transparent/600x400.png"
            :style="{ backgroundImage: `url(${Utils.replaceImageUrl(newsBroadcast.newsBroadcastThumbnailImgUrl, 0, 1)})` }"
            class="w-100 mb-3 imgBg">
          <h4>{{ newsBroadcast.newsBroadcastHeader }}</h4>
          <p>
            {{ newsBroadcast.newsBroadcastDetail }}
          </p>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            @click="openUrlNewTab(newsBroadcast.newsBroadcastUrl)"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <font-awesome-icon :icon="['fas', 'eye']" class="me-1"/>
            อ่านเพิ่มเติม
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // import zone
  import { onMounted, ref, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';
  import eventBus from '@/eventBus/eventBus.js';
  import Utils from '@/plugins/utils.js';
  import { useI18n } from 'vue-i18n';

  // variable zone
  const store = useStore();
  const { t } = useI18n();
  const newsbroadcastInfoModal = ref(null);
  const newsBroadcast = ref({
    createDate: null,
    lastPage: null,
    newsBroadcastDetail: null,
    newsBroadcastUrl: null,
    memberID: null,
    newsBroadcastHeader: null,
    newsBroadcastThumbnailImgUrl: null
  });
  const instance = getCurrentInstance();
  const memberData = ref(store.getters['auth/getMember']);
  const isMember = ref(false);

  // end valiable zone

  // function zone
  const showModal = () => {
    // isMember.value = (memberData.value) ? true : false;

    // if (!newsbroadcastInfoModal.value) {
    //   newsbroadcastInfoModal.value = new bootstrap.Modal(document.getElementById('newsbroadcastInfoModal'), {});
    // }
    newsbroadcastInfoModal.value.show();
  };

  const hideModal = () => {
    if (newsbroadcastInfoModal.value) {
      newsbroadcastInfoModal.value.hide();
    }
  };

  const setupData = (data) => {
    newsBroadcast.value = {};

    newsBroadcast.value.createDate = data.createDate;
    newsBroadcast.value.memberID = data.memberID;
    newsBroadcast.value.newsBroadcastUrl = data.newsBroadcastUrl;
    newsBroadcast.value.newsBroadcastDetail = data.newsBroadcastDetail;
    newsBroadcast.value.newsBroadcastHeader = data.newsBroadcastHeader;
    newsBroadcast.value.newsBroadcastThumbnailImgUrl = data.newsBroadcastThumbnailImgUrl;
    newsBroadcast.value.lastPage = data.lastPage;


    const obj = {
      createDate: data.create_date,
      lastPage: 'profile',
      memberID: data.member_id,
      newsBroadcastDetail: data.news_broadcast_detail,
      newsBroadcastHeader: data.news_broadcast_header,
      newsBroadcastThumbnailImgUrl: data.news_broadcast_thumbnail_img_url
    };
  };

  const openUrlNewTab = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  // end function zone

  onMounted(() => {
    newsbroadcastInfoModal.value = new bootstrap.Modal(document.getElementById('newsbroadcastInfoModal'), {});
    eventBus.on('showNewsbroadcastInfoModal', (obj) => {
      setupData(obj);
      showModal();
    });

    eventBus.on('hideNewsbroadcastInfoModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>
