/* global localStorage */

import { authService } from '../services';

const member = JSON.parse(localStorage.getItem('member'));
const initialState = member
  ? { loggedIn: true, member }
  : { loggedIn: false, member: null };

const authModule = {
  namespaced: true,
  // state: {
  //   initialState
  // },
  state: initialState,
  mutations: {
    loginSuccess  (state, member) {
      state.loggedIn = true;
      state.member = member;
    },
    loginFailure  (state) {
      state.loggedIn = false;
      state.member = null;
    },
    SET_MEMBER (state, data) {
      state.member = data;
    },
  },
  actions: {
    async loginMember ({ commit }, data) {
      try {
        const result = await authService.login(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async register ({ commit }, data) {
      try {
        const result = await authService.register(data);
        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginByCsite ({ commit }, data) {
      try {
        const result = await authService.loginByCsite(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginWithSocial ({ commit }, data) {
      try {
        const result = await authService.loginWithSocial(data);

        if (result.data !== null) {
          commit('loginSuccess', result.data);
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async emailVerification ({ commit }, data) {
      try {
        const result = await authService.emailVerification(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async updatePassword ({ commit }, data) {
      try {
        const result = await authService.updatePassword(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async requestForgetPassword ({ commit }, data) {
      try {
        const result = await authService.requestForgetPassword(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async verifyForgetPasswordToken ({ commit }, data) {
      try {
        const result = await authService.verifyForgetPasswordToken(data);
        return result;
      } catch (error) {
        if (error.response) {
          return error.response;
        }
      }
    },
    async loginFB ({ commit }, data) {
      try {
        const result = await authService.fbLogin(data);

        if (result.data !== null) {
          if (result.data.member.type === 3) {
            // register by FB
            return result;
          } else {
            // login FB success
            commit('loginSuccess', result.data);
            return result;
          }
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async requestOTP ({ commit }, data) {
      try {
        const result = await authService.requestOTP(data);
        return result;
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    async validateOTPLogin ({ commit }, data) {
      try {
        const result = await authService.validateOTPLogin(data);

        if (result.data !== null) {
          // for validate success in login mode
          commit('loginSuccess', result.data);
          return result;
        } else {
          // for validate success in register mode
          return result;
        }
      } catch (error) {
        commit('loginFailure');
        if (error.response) {
          return error.response;
        }
      }
    },
    setMember ({ commit }, member) {
      commit('SET_MEMBER', member);
    },
    setMemberProfileImage ({ commit }, newIng) {
      if (newIng) {
        member.member_img_profile = newIng;
      }
      commit('SET_MEMBER', member);
    }
  },
  getters: {
    getLoginState (state) {
      return state.loggedIn;
    },
    getMember (state) {
      return state.member;
    }
  }
};

export default authModule;
