<template>
  <!-- <div class="newsCardArea">
    <div class="newsThumbnail">
      <img
        src="../../assets/images/transparent/600x400vdo.png"
        style="background-image:url('https://s3-ap-southeast-1.amazonaws.com/com.csitereport.s3/public_img/3fca432431de9c37d00e2435f6075d8db52874dd.jpg')"
      >
      <div class="newsDate">27 ก.ย. 2566</div>
      <div class="newsAuthor">
        <div class="newsAuthorProfile">
          <img
            src="../../assets/images/transparent/400x400.png"
            style="background-image:url('https://s3-ap-southeast-1.amazonaws.com/com.csitereport.s3/profile/fae50648f054a0d25354c402865b7809.jpg')"
          >
        </div>
          อนันต์ ThaiPBS
        <div class="newsAuthorBadge">
          <img src="../../assets/images/badge/1.png">
          <img src="../../assets/images/badge/2.png">
          <img src="../../assets/images/badge/3.png">
          <img src="../../assets/images/badge/4.png">
        </div>
      </div>
    </div>

    <div class="newsDetailArea onlyHeadLine">
      <div class="newsHeadline">
        (สระแก้ว) - ทหารเข้าช่วยระงับเพลิงไฟไหม้บ้าน 2 หลังและโกดัง จ.สระแก้ว
      </div>
    </div>
  </div> -->

  <div class="newsCardArea" @click="showNewsDetailModal(item.post_id)">
    <div class="newsThumbnail">
      <img
        src="../../assets/images/transparent/600x400vdo.png"
        :style="{ backgroundImage: `url(${(item.img[0].post_img_hd_wm_path)?Utils.replaceImageUrl(item.img[0].post_img_hd_wm_path, item.post_id, 1):'image/transparent/600x400.png'})` }"
      >

      <div class="newsDate">
        {{ DatepickerHelper.dateStr2thFormat(item.post_create_date) }}
      </div>

      <div class="newsAuthor">
        <div class="newsAuthorProfile">
          <img
            src="../../assets/images/transparent/400x400.png"
            :style="{ backgroundImage: `url(${(item.member_img_profile)?Utils.replaceImageUrl(item.member_img_profile, item.post_id, 2):'image/navbar/profileIcon.png'})` }"
          >
        </div>
          {{ item.member_displayname }}
        <div class="newsAuthorBadge" >
          <img 
            v-for="badge in item.badge"
            :key="badge.badge_id"
            :src="badge.badge_img_url"
            :alt="badge.badge_name"
          >
        </div>
      </div>
    </div>

    <div class="newsDetailArea onlyHeadLine">
      <div class="newsHeadline">
        {{ item.post_header }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, getCurrentInstance } from 'vue';
  import DatepickerHelper from '@/plugins/datepickerHelper.js';
  import Utils from '@/plugins/utils.js';
  import eventBus from '@/eventBus/eventBus.js';

  // variable zone
  const props = defineProps(['item','lastPage']);
  const instance = getCurrentInstance();
  const { lastTopicID, s3DevUrl, s3RedesignUrl } = instance.appContext.config.globalProperties.$globalVar;
 
  // end variable zone

  // function zone
  function showNewsDetailModal (topicID) {
    eventBus.emit('hideReporterDetaiVDOModal'); 
    const obj = {
      postID: topicID,
      lastPage: props.lastPage,
      memberID: props.item.post_by
    };
    eventBus.emit('showNewsDetailModal', obj); 
  }
  // end function zone

</script>
