<template>
  <div class="modal fade" id="reporterDetailAddNewsModal" tabindex="-1" role="dialog" aria-labelledby="reporterDetailAddNewsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="reporterDetailAddNewsModalModalLabel">{{ $t('post_news_list' )}} {{ displayName }}</h5>
                <button 
                  type="button" 
                  data-dismiss="modal" 
                  class="btn-close" 
                  data-bs-target="#reporterDetailModal" 
                  data-bs-toggle="modal">
                </button>
            </div>
            <!-- @scroll.stop="handleScroll" -->
            <div class="modal-body" @scroll.stop="handleScroll">
                <div class="row align-items-center g-2">
                    <div class="col-6 col-md-4 col-lg-4" v-for="item in userTopicList">
                        <newsBox 
                          :item="item"
                          :lastPage="'showReporterDetailAddNewsModal'"
                        ></newsBox>
                        <!-- <div class="newsCardArea">
                            <div class="newsThumbnail">
                                <img 
                                    src="../../assets/images/transparent/600x400.png"
                                    :style="{ backgroundImage: `url(${UtilsImg.replaceImageUrl(list.img[0].post_img_hd_wm_path, list.post_id, 1) })` }"
                                >
                                <div class="newsDate">{{ Utils.dateStr2thFormat(list.post_create_date) }}</div>
                                <div class="newsAuthor">
                                    <div class="newsAuthorProfile">
                                        <img 
                                            src="../../assets/images/transparent/400x400.png"
                                            :style="{ backgroundImage: `url(${UtilsImg.replaceImageUrl(list.member_img_profile, 0, 2) })` }"                                    >
                                    </div>
                                    {{ list.member_displayname }}
                                    <div class="newsAuthorBadge">
                                        <img src="https://www.csitereport.com/assets/image/userbadge/star.png">
                                        <img src="https://www.csitereport.com/assets/image/userbadge/trophy.png">
                                        <img src="https://www.csitereport.com/assets/image/userbadge/mic.png">
                                    </div>
                                </div>
                            </div>
                            <div class="newsDetailArea">
                                <div class="newsHeadline">
                                    {{ list.post_header }}                            
                                </div>
                                <div class="newsShortDetail">
                                    {{ list.post_detail }}
                                </div>
                                <div class="newsHashtag">{{ list.tag_display }}</div>
                            </div>
                            <div class="row newsCountArea g-0">
                                <div class="col">
                                <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'eye']" /> {{ list.post_view_count }}</div>
                                </div>
                                <div class="col">
                                <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'heart']" /> {{ list.post_like_count }}</div>
                                </div>
                                <div class="col">
                                <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'message']" /> {{ list.post_comment_count }}</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
    // import zone
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import eventBus from '@/eventBus/eventBus.js';
    import newsBox from '@/components/list/newsboxComponent.vue';
    // variable zone
    const store = useStore();
    const callApi = (param) => store.dispatch('api/apiCall', param);
    const reporterDetailAddNewsModal = ref(null);
    const previousData = ref({
        lastPage: '',
        postID: 0,
        memberID: 0,
        displayName: ''
    });

    const page = ref(0);
    const maxPerPage = ref(9);
    const isMoreTopic = ref(true);
    const userTopicList = ref([]);
    const isLoadSuccess = ref(false);
    const displayName = ref('');
    // end valiable zone

    // function zone
    const showLoadingModal = () => {
        eventBus.emit('showLoadingModal'); 
    };

    const hideLoadingModal = () => {
        eventBus.emit('hideLoadingModal'); 
    };

    const showModal = () => {
        showLoadingModal();
        userTopicList.value = [];
        page.value = 0;
        isMoreTopic.value = true;
        getUserTopicByID(previousData.value.memberID);
        if (!reporterDetailAddNewsModal.value) {
            reporterDetailAddNewsModal.value = new bootstrap.Modal(document.getElementById('reporterDetailAddNewsModal'), {});
        }
        reporterDetailAddNewsModal.value.show();
    };

    const hideModal = () => {
        if (reporterDetailAddNewsModal.value) {
            reporterDetailAddNewsModal.value.hide();
        }
    };

    const getUserTopicByID = async (id) => {
        if (isMoreTopic.value) {
            try {
                isLoadSuccess.value = false;
                const obj = {
                    postBy: id,
                    page: page.value,
                    maxPerPage: maxPerPage.value
                };
                const res = await callApi({
                    svName: 'publicService',
                    fnName: 'getUserTopic',
                    param: obj
                });
                if (res?.status === 200) {
                    for(let i = 0; i < res.data.result.length ; i++){
                        userTopicList.value.push(res.data.result[i]);
                    }

                    if (res.data.result.length == 0) {
                        isMoreTopic.value = false;
                    }
                    isLoadSuccess.value = true;
                } 
                setTimeout(function () {
                    hideLoadingModal();
                }, 500);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleScroll = async (event) => {
        const container = event.target;
        if (container.scrollTop != 0 && container.clientHeight != 0 && container.scrollHeight != 0 && isLoadSuccess.value && isMoreTopic.value) {
            if (((container.scrollTop + container.clientHeight+2) >= container.scrollHeight) ) {
                showLoadingModal();
                page.value++;
                await getUserTopicByID(previousData.value.memberID);
                setTimeout(function () {
                    hideLoadingModal();
                }, 500);
            }
        }
    };

    const getDisplayname = async (id) => {
        try {
            const res = await callApi({
                svName: 'publicService',
                fnName: 'getDisplayName',
                param: id
            });
            if (res?.status === 200) {
                displayName.value = res.data.result.member_displayname;
            } 
        } catch (error) {
            console.log(error);
        }
    };

    // end function zone

    onMounted(() => {
        reporterDetailAddNewsModal.value = new bootstrap.Modal(document.getElementById('reporterDetailAddNewsModal'), {});
        eventBus.on('showReporterDetailAddNewsModal', (obj) => {
            previousData.value = obj;
            getDisplayname(previousData.value.memberID);
            showModal();
        });

        eventBus.on('hideReporterDetailAddNewsModal', () => {
            hideModal();
        });
    });

    defineExpose({
        showModal,
        hideModal
    });
</script>