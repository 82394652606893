<template>
  <div class="modal fade" id="redeemDetailConfirmModal" tabindex="-1" role="dialog" aria-labelledby="redeemDetailConfirmModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><font-awesome-icon :icon="['fas', 'circle-info']" class="text-black" /> {{ $t('confirm_redeem') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row g-2 d-flex align-items-center mb-4 productImageAndDetailArea">
                <div class="col-6 m-0 p-0">
                  <img 
                    src="../../assets/images/transparent/400x400.png" 
                    class="card-img-top imgBg" 
                    :style="{ backgroundImage: `url(${(redeemData.rewardImg)})` }"
                  />
                </div>
                <div class="col-6 h-100 text-center">
                  <h5>{{ redeemData.rewardName }}<br>{{ $t('quantity') }} {{ redeemData.qty }} {{ $t('item') }}</h5>
                  <div class="coinUsageArea d-flex align-items-center">
                    <div class="coinImg"><img src="../../assets/images/c-coin.svg"></div>
                    <div class="coinUsage">{{ redeemData.rewardPoint }} x {{ redeemData.qty }} = {{redeemData.rewardPoint*redeemData.qty }}</div>
                  </div>
                </div>
            </div>
            <table class="w-100">
              <!-- ชื่อ-นามสกุล ที่อยู่ ผู้รับสินค้า -->
              <tr>
                <td>{{ $t('name_surname') }} {{ $t('recipient_address') }}</td>
                <td> : </td>
                <td>{{ redeemData.redeemAddress}} </td>
              </tr>
              <tr>
                <td>{{ $t('contact_number') }}</td>
                <td> : </td>
                <td>{{ redeemData.tel}} </td>
              </tr>
            </table>
            </div>

            <div class="modal-footer d-flex justify-content-between">
                <button type="button" class="btn btn-warning" @click="back()">
                  <font-awesome-icon :icon="['fas', 'pen']"  class="me-1"/> {{ $t('edit_information') }}</button>                
                <button type="button" class="btn btn-success" @click="addRedeem()">
                  <font-awesome-icon :icon="['far', 'circle-check']"  class="me-1"/> {{ $t('confirm_redeem') }}</button>                
            </div>
          </div>
        </div>
      </div>
</template>
<script setup>
  import { onMounted, ref, watch } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  // variable zone ============================================================================================
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const redeemData = ref({
    rewardID: 0,
    rewardName: '',
    rewardImg: '',
    rewardPoint: '',
    qty: '',
    redeemAddress: '',
    tel: ''
  });
  // end variable zone ========================================================================================

  // function zone ============================================================================================
  const back = () => {
    redeemData.value.type = 2;
    eventBus.emit('showRedeemDetailModal', redeemData.value);
    hideModal();
  };

  const showModal = () => {
    if (dialogRef.value) {
      dialogRef.value.show();
    }
  }

  const hideModal = () => {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  }
  
  const addRedeem = async () => {
    try {
      const obj = {
        _id: redeemData.value.rewardID,
        redeemAddress: redeemData.value.redeemAddress,
        redeemQty: redeemData.value.qty,
        tel: redeemData.value.tel
      };
      const res = await callApi({
        svName: 'memberService',
        fnName: 'addRedeem',
        param: obj
      });
      if (res.status === 200) {
        const confirm = await new window.Swal({
          icon: 'success',
          title: t('product_redeem_confirmed'),
          text: t('succuss_redeem_test'),
          showCancelButton: false,
          confirmButtonText: t('submit'),
          reverseButtons: true
        });
        if (confirm.isConfirmed) {
          window.location.reload();
        }
      }
    } catch (error) {
      alertWarningMsg(error.response.data.message);
    }
  }

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
        console.log(error);
    }
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal');
  };

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal');
    }, 1000);
  };

  // end function zone =======================================================================================
  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('redeemDetailConfirmModal'), {});
    eventBus.on('showRedeemDetailConfirmModal', (data) => {
      redeemData.value = data;
      showModal();
    });

    eventBus.on('hideRedeemDetailConfirmModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>