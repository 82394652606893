<template>
<div class="modal fade" id="personaModal" tabindex="-1" role="dialog" aria-labelledby="personaModalModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close closeBtnModalCustom01"></button>
        <div class="modal-body">
          <!-- body -->
            <div class="surveyAnsArea p-0 m-0 shadow-none">
              <div class="surveyTitleAndDetail">
                <!-- <h4>การทำ Persona เพื่อจัดเก็บข้อมูลบุคลิกลักษณะของผู้ใช้และสามารถตอบสนองความต้องการได้ตรงกลุ่มเป้าหมาย</h4> -->
                <h4>การได้รู้จักคุณจะช่วยให้พวกเรา<br>แนะนำเนื้อหาและหลักสูตรออนไลน์<br>ที่เหมาะสมกับคุณได้</h4>
              </div>
              <div class="surveyAnsChoose">
                <div class="surveyQuestion"><div class="surveyQuestionNo"> 1 </div>
                <div class="surveyQuestionText">คุณใช้งาน C-site ในนามบุคคล เครือข่าย องค์กร</div>
                  <div class="surveyChooice">
                    <div class="radio-primary custom-control custom-radio" v-for="item1 in question1">
                      <input 
                        type="radio" 
                        class="custom-control-input"
                        :name="'question1_'+item1.value"
                        :id="'question1_'+item1.value"
                        :value="item1.value"
                        v-model="answer.answer1"
                      >
                      <label class="custom-control-label" :for="'question1_'+item1.value">{{ item1.text }}</label>
                    </div>

                    <!-- <div class="radio-primary custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="1_chkb_2" name="aa">
                      <label class="custom-control-label" for="1_chkb_2">เครือข่าย/องค์กร</label>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="surveyAnsChoose">
                <div class="surveyQuestion"><div class="surveyQuestionNo"> 2 </div>
                  <div class="surveyQuestionText">คุณใช้ C-Site วัตถุประสงค์อะไร</div>
                    <div class="surveyChooice">
                      <div class="checkbox-primary custom-control custom-checkbox" v-for="item2 in question2">
                        <input 
                          type="checkbox" 
                          class="custom-control-input" 
                          :id="'question2_'+item2.id"
                          :value="item2.value"
                          v-model="answer.answer2"
                        >
                        <label class="custom-control-label" :for="'question2_'+item2.id">{{ item2.text }}</label>
                      </div>
                      <!-- <div class="radio-primary custom-control custom-radio">
                        <input type="checkbox" class="custom-control-input" id="2_chkb_2" name="bb">
                        <label class="custom-control-label" for="1_chkb_2">เขียน Text เรื่องเล่า</label>
                      </div>
                      <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="2_chkb_3" name="bb">
                        <label class="custom-control-label" for="1_chkb_4">รายงานข่าว</label>
                      </div> -->
                    </div>
                </div>
              </div>

              <div class="surveyAnsChoose">
                <div class="surveyQuestion"><div class="surveyQuestionNo"> 3 </div>
                  <div class="surveyQuestionText">คุณมีทักษะการทำสื่ออยู่ในระดับใด</div>
                    <div class="surveyChooice">
                      <div class="radio-primary custom-control custom-radio" v-for="item3 in question3">
                        <input 
                          type="radio" 
                          class="custom-control-input" 
                          :name="'question3_'+item3.value"
                          :id="'question3_'+item3.value"
                          :value="item3.value"
                          v-model="answer.answer3"
                        >
                        <label class="custom-control-label" :for="'question3_'+item3.value">{{ item3.text }}</label>
                      </div>

                      <!-- <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="3_chkb_2" name="cc">
                        <label class="custom-control-label" for="3_chkb_2">Junior ประสบการณ์ปานกลาง (พอทำได้บ้าง)</label>
                      </div>

                      <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="3_chkb_3" name="cc">
                        <label class="custom-control-label" for="3_chkb_3">Senior มีประสบการณ์ (เคยรายงานข่าวกับไทยพีบีเอส)</label>
                      </div> -->
                    </div>
                </div>
              </div>

              <div class="surveyAnsChoose">
                <div class="surveyQuestion"><div class="surveyQuestionNo"> 4 </div>
                  <div class="surveyQuestionText">เรื่อง/ประเด็นที่คุณสนใจ</div>
                    <div class="surveyChooice">
                      <div class="checkbox-primary custom-control custom-checkbox" v-for="item4 in question4">
                        <input 
                          type="checkbox" 
                          class="custom-control-input" 
                          :id="'question4_'+item4.id"
                          :value="item4.value"
                          v-model="answer.answer4"
                        >
                        <label class="custom-control-label" :for="'question4_'+item4.id">{{ item4.text }}</label>
                      </div>

                      <!-- <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="6_chkb_2" name="dd">
                        <label class="custom-control-label" for="6_chkb_2">สุขภาพยั่งยืน</label>
                      </div>

                      <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="6_chkb_3" name="dd">
                        <label class="custom-control-label" for="6_chkb_3">สังคมสูงวัย</label>
                      </div>

                      <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="6_chkb_4" name="dd">
                        <label class="custom-control-label" for="6_chkb_4">เศรษฐกิจทั่วถึงและเป็นธรรม</label>
                      </div>

                      <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="6_chkb_5" name="dd">
                        <label class="custom-control-label" for="6_chkb_5">ทรัพยากรธรรมชาติและสิ่งแวดล้อม</label>
                      </div>

                      <div class="radio-primary custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="6_chkb_6" name="dd">
                        <label class="custom-control-label" for="6_chkb_6">ธรรมาภิบาลและการกระจายอำนาจ</label>
                      </div> -->
                    </div>
                </div>
              </div>

              <div class="surveyAnsChoose">
                <div class="surveyQuestion"><div class="surveyQuestionNo"> 5 </div>
                  <div class="surveyQuestionText">ช่วยเลือกจังหวัด,อำเภอ,ตำบล ที่อาศัยอยู่ปัจจุบัน</div>
                  <div class="surveyChooice">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="fullName">จังหวัด</label>
                          <!-- <select class="form-select">
                            <option>เลือกจังหวัด</option>
                          </select> -->
                          <select
                            v-model="answer.provinceID"
                            @change="getAmphur()"
                            class="form-select"
                          >
                            <option value="">เลือกจังหวัด</option>
                            <option
                              v-for="province in provinces"
                              :key="province.PROVINCE_ID"
                              :value="province.PROVINCE_ID"
                            >
                              {{ province.PROVINCE_NAME }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="fullName">อำเภอ</label>
                          <!-- <select class="form-select"><option>เลือกอำเภอ</option></select> -->
                          <select
                            v-model="answer.amphurID"
                            @change="getTambol"
                            class="form-select"
                          >
                            <option value="">เลือกอำเภอ</option>
                            <option
                              v-for="amphur in amphurs"
                              :key="amphur.AMPHUR_ID"
                              :value="amphur.AMPHUR_ID"
                            >
                              {{ amphur.AMPHUR_NAME }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="fullName">ตำบล</label>
                          <!-- <select class="form-select"><option>เลือกตำบล</option></select> -->
                          <select
                            v-model="answer.districtID"
                            class="form-select"
                          >
                            <option value="">เลือกตำบล</option>
                            <option
                              v-for="tambol in tambols"
                              :key="tambol.DISTRICT_ID"
                              :value="tambol.DISTRICT_ID"
                            >
                              {{ tambol.DISTRICT_NAME }}
                            </option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <div class="row d-flex align-items-center justify-content-between text-center">
            <div class="col-auto">
              <button type="button" class="btn btn-success" @click="savePersona()">
                <font-awesome-icon :icon="['fas', 'floppy-disk']" class="me-1" /> ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  // variable zone
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);

  const emit = defineEmits(['callback']);
  const personaModalRef = ref(null);
  const isExistPersona = ref(false);
  const memberData = ref(store.getters['auth/getMember']);
  const question1 = ref([
    { text: 'บุคคล', value: 1 },
    { text: 'เครือข่าย/องค์กร', value: 2 }
  ]);

  const question2 = ref([
    { 
      id: 1,
      text: 'อ่าน',
      value: 1
    }, { 
      id: 2,
      text: 'เขียน Text เรื่องเล่า',
      value: 2
    }, { 
      id: 3,
      text: 'รายงานข่าว',
      value: 3
    }
  ]);

  const question3 = ref([
    { text: 'Freshy น้องใหม่ (ยังไม่มีประสบการณ์ทำสื่อ)', value: 1 },
    { text: 'Junior ประสบการณ์ปานกลาง (พอทำได้บ้าง)', value: 2 },
    { text: 'Senior มีประสบการณ์ (เคยรายงานข่าวกับไทยพีบีเอส)', value: 3 }
  ]);

  const question4 = ref([
    { 
      id: 1,
      text: 'การศึกษา',
      value: 1
    }, { 
      id: 2,
      text: 'สุขภาพยั่งยืน',
      value: 2
    }, { 
      id: 3,
      text: 'สังคมสูงวัย',
      value: 3
    }, { 
      id: 4,
      text: 'เศรษฐกิจทั่วถึงและเป็นธรรม',
      value: 4
    }, { 
      id: 5,
      text: 'ทรัพยากรธรรมชาติและสิ่งแวดล้อม',
      value: 5
    }, { 
      id: 6,
      text: 'ธรรมาภิบาลและการกระจายอำนาจ',
      value: 6
    }
  ]);

  const provinces = ref(null);
  const amphurs = ref(null);
  const tambols = ref(null);

  const answer = ref({
    answer1: 0,
    answer2: [],
    answer3: 0,
    answer4: [],
    provinceID: '',
    amphurID: '',
    districtID: ''
  });
  // end variable zone

  // function zone
  const initFunc = async () => {
    await getIsExistPersona();
    eventBus.on('showPersonaModal', () => {
      if (!isExistPersona.value) {
        showModal();
      }
    });
  }

  function showModal() {
    getProvince();
    if (personaModalRef.value) {
      personaModalRef.value.show();
    }
  }

  function hideModal() {
    if (personaModalRef.value) {
      personaModalRef.value.hide();
    }
  }

  const getIsExistPersona = async() => {
    try {
      const res = await callApi({
          svName: 'memberService',
          fnName: 'isExistPersona'
      });
      if (res && res.status===200) {
        isExistPersona.value = res.data.result.is_exist_persona;
      }   
    } catch (error) {
        console.log(error);
    }
  };

  const validationAnswer = () => {
    if (answer.value.answer1 === 0) {
      alertWarningMsg('กรุณาเลือกว่าคุณใช้งาน C-site ในนามบุคคล เครือข่าย องค์กร');
      return false;
    }

    if (answer.value.answer2.length === 0) {
      alertWarningMsg('กรุณาเลือกวัตถุประสงค์อย่างน้อย 1 ข้อ');
      return false;
    }

    if (answer.value.answer3 === 0) {
      alertWarningMsg('กรุณาเลือกระดับทักษะการทำสื่อ');
      return false;
    }

    if (answer.value.answer4.length === 0) {
      alertWarningMsg('กรุณาเลือกเรื่อง/ประเด็นที่คุณสนใจอย่างน้อย 1 ข้อ');
      return false;
    }

    if (Number(answer.value.provinceID) === 0) {
      alertWarningMsg('กรุณาระบุจังหวัด');
      return false;
    }

    if (Number(answer.value.amphurID) === 0) {
      alertWarningMsg('กรุณาระบุอำเภอ');
      return false;
    }

    return true;
  };

  const savePersona = async () => {
    try {
      if (validationAnswer()) {
        const tmp = {
          usedOnBehalf: answer.value.answer1,
          objective: answer.value.answer2,
          mediaSkillsLevel: answer.value.answer3,
          issuesInterest: answer.value.answer4,
          provinceID: Number(answer.value.provinceID),
          amphurID: Number(answer.value.amphurID),
          districtID: Number(answer.value.districtID)
        };
        const confirm = await new window.Swal({
          icon: 'warning',
          title: t('txt_alert'),
          text: t('confirm_save'),
          showCancelButton: true,
          confirmButtonText: t('submit'),
          cancelButtonText: t('cancel'),
          reverseButtons: true
        });

        if (confirm.isConfirmed) {
          const res = await callApi({
            svName: 'memberService',
            fnName: 'addPersona',
            param: tmp
          });

          if (res.status === 200) {
            new window.Swal({
              icon: 'success',
              title: t('txt_alert'),
              text: t('success'),
              showCancelButton: false,
              confirmButtonText: t('submit'),
              reverseButtons: true
            }).then(() => {
              window.location.reload();
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      showLoadingModal();
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getProvince'
      });
      if (res.status === 200) {
        provinces.value = res?.data?.result;
      }
      hideLoadingModal();
    } catch (error) {
      console.log(error);
    }
  };

  const getAmphur = async () => {
    try {
      showLoadingModal();
      answer.value.amphurID = '';
      answer.value.districtID = '';
      const tmp = {
        provinceID: answer.value.provinceID
      };
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getAmphur',
        param: tmp
      });
      if (res.status === 200) {
        amphurs.value = res?.data?.result;
      }
      hideLoadingModal();
    } catch (error) {
      console.log(error);
    }
  };

  const getTambol = async () => {
    try {
      showLoadingModal();
      if (answer.value) {
        answer.value.districtID = '';
        const tmp = {
          amphurID: answer.value.amphurID
        };
        const res = await callApi({
          svName: 'publicService',
          fnName: 'getTambol',
          param: tmp
        });
        if (res.status === 200) {
          tambols.value = res?.data?.result;
        }
        hideLoadingModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal'); 
  };

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal'); 
    }, 500);
  };

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: 'แจ้งเตือน',
        text: txt,
        confirmButtonText: 'ตกลง'
      });
    } catch (error) {
      console.log(error);
    }
  }
  // end function zone

  onMounted(() => {
    personaModalRef.value = new bootstrap.Modal(document.getElementById('personaModal'), {});
    // init event bus
    // console.log(isExistPersona.value);
    if (memberData.value) {
      initFunc();
    }
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>