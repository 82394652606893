import HttpRequest from './core/http_request';

class NotiProvider extends HttpRequest {
  getNotification (data) {
    return this.request('get', 'private/notification', data);
  }

  removeAllNoti () {
    return this.request('post', 'private/notification');
  }

  removeNotiByID (id) {
    return this.request('post', 'private/notification/id/' + id);
  }

  getnewAlert (data) {
    return this.request('get', '/private/notification/newAlert', data);
  }

  updateIsView (id) {
    return this.request('post', 'private/notification/update/' + id);
  }
}

export default NotiProvider;
