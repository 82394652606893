<template>
 <div class="position-relative">
    <textarea
      class="form-control"
      rows="4"
      :placeholder="$t('comment')"
      v-model="commentData.commentText"
    >
    </textarea>
    <div class="commentBtnArea">
      <button type="button" class="btn btn-light rounded-circle me-1 btn-emoji" @click="isShowEmoji=!isShowEmoji">😀</button>
      <button type="button" class="btn btn-dark rounded-circle me-1" @click="uploadImage()">
        <font-awesome-icon :icon="['far', 'image']" />
      </button>
      <input
        type="file"
        :id="idInputfile"
        @change="onFileChange($event, imgData)"
        class="btn btn-default"
        multiple
        style="display: none;"
        accept="image/jpeg"
      >
      <button type="button" class="btn btn-success rounded-circle" @click="saveComment()">
        <font-awesome-icon :icon="['fas', 'angle-right']" />
      </button>
    </div>
  </div>
    
  <div class="editCommentBtnArea">
    <button type="button" class="btn btn-outline-warning btn-sm rounded-circle me-1" v-if="commentID !== 0" @click="undoComment()">
      <font-awesome-icon :icon="['fas', 'redo-alt']" />
    </button>
  </div>
  
  <div class="emojiArea" v-if="isShowEmoji">
    <div class="emojiBtn" v-for="list in emojiList" @click="addEmoji(list)"> {{ list }}</div>
  </div>

  <!-- upload image -->
  <div
    class="addpostimg"
    v-if="imgData && imgData.url"
  >
    <div class="boxImage">
      <img src="../../assets/images/transparent/600x400.png"
        class="previewImage"
        :style="{ backgroundImage: `url(${imgData.url})` }"
      >
      <div class="closeObj">
        <a 
          href="javascript:void(0);"
          class="delete-item"
          @click="removeImage(imgData, -1)"
          v-show="isView === false"
        >
        <font-awesome-icon :icon="['fas', 'circle-xmark']" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineProps, getCurrentInstance, watch } from 'vue';
  import { useStore } from 'vuex';
  import eventBus from '@/eventBus/eventBus.js';

  // variable zone
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const props = defineProps({
    commentID: Number,
    item: Object,
    postID: Number,
    isEdit: Boolean,
    idInputfile: String,
    imgData: Object,
    isView: Boolean,
    fnUploadName: String,
    fnServicedName: String,
  });
  const emojiList = ref(['😀', '😃', '😄', '😁', '😆', '😅', '😂', '😊', '😇', '😉', '😌', '😍', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '😎', '😏', '😒', '😞', '😔', '😟', '😕', '😣', '😖', '😫', '😩', '😢', '😭', '😮‍💨', '😤', '😠', '😡', '😳', '🥶', '😱', '😨', '😰', '😥', '🤥', '😐', '😑', '😬', '😯', '😦', '😧', '😮', '😲', '😴', '😪', '😵', '😵‍💫', '😷', '😈', '👿', '👹', '👺', '💩', '👻', '💀', '☠️', '👽', '👾', '🎃', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿']);
  const emit = defineEmits(['callBackCommentlist', 'callBackSetEditMode']);
  // const isEdit = ref(false);
  const commentData = ref({
    commentText: ''
  });

  const memberData = ref(store.getters['auth/getMember']);
  const isShowEmoji = ref(false);
  // end variable zone

  // function zone
  function undoComment () {
    // isEdit.value = (isEdit.value)?false:true;
    commentData.value.commentText = props.item.post_comment_text;
    emit('callBackSetEditMode');
  }

  async function saveComment () {
    if (props.commentID === 0) {
      // add
      await addComment();
    } else {
      // edit
      await saveEditComment();
    }
  }

  async function addComment () {
    if (memberData.value !== null) {
      try {
        if (commentData.value.commentText !== '') {
          const obj = {
            postID: props.postID,
            commentText: commentData.value.commentText
          };
          if (props.imgData.url) {
            obj.realFilename = props.imgData.realFilename; // เก็บชื่อไฟล์จริงๆ
            obj.fileName = props.imgData.fileName; // เก็บชื่อไฟล์ที่ได้มากการอัปโหลด
          }
          // return;
          const res = await callApi({
            svName: 'topicService',
            fnName: 'addComment',
            param: obj
          });
          if (res && res.status === 200) {
            new window.Swal({
              icon: 'success',
              title: 'แจ้งเตือน',
              text: 'Comment Success',
              confirmButtonText: 'ตกลง'
            });
            
            commentData.value.commentText = '';
            props.imgData.realFilename = null;
            props.imgData.fileName = null;
            props.imgData.url = null;

            emit('callBackCommentlist');
          }
        } else {
          alertWarningMsg('กรุณาระบุความคิดเห็น');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alertForLogin();
    }
  }

  async function saveEditComment () {
    try {
      if (commentData.value.commentText !== '') {
        const obj = {
          postID: props.postID,
          commentText: commentData.value.commentText,
          commentID: props.item.post_comment_id
        };
        if (props.imgData.url) {
          obj.realFilename = props.imgData.realFilename; // เก็บชื่อไฟล์จริงๆ
          obj.fileName = props.imgData.fileName; // เก็บชื่อไฟล์ที่ได้มากการอัปโหลด
        }
        const res = await callApi({
          svName: 'topicService',
          fnName: 'editComment',
          param: obj
        });
        if (res && res.status === 200) {
          new window.Swal({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'Edit Comment Success',
            confirmButtonText: 'ตกลง'
          });

          emit('callBackCommentlist');
          emit('callBackSetEditMode');
          // isEdit.value = false;
        }
      }else {
        alertWarningMsg('กรุณาระบุความคิดเห็น');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const addEmoji = (emoji) => {
    commentData.value.commentText += emoji;
  }

  const setUpEdit = (data) => {
    commentData.value.commentText = data.commentText;
  } 

  // img zone ============================================================================================
  function uploadImage () {
    document.getElementById(props.idInputfile).click();
  }

  async function onFileChange (event, ansData) {
    const files = event.target.files;
    let notFileType = false;
    let hadBigFile = false;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i] !== null) {
          if (files[i].type !== 'image/jpeg') {
            notFileType = true;
            break;
          } else if (files[i].size >= 100000000) { // 100MB
            hadBigFile = true;
            break;
          }
        }
      }

      if (notFileType) {
        alertWarningMsg('กรุณาเลือกไฟล์รูปภาพประเภท JPEG');
      } else if (hadBigFile) {
        alertWarningMsg('ขนาดไฟล์ไม่เกิน 100MB');
      } else {
        // single
      if (files[0]) {
          const resUpload = await upLoadFile(files[0]);
          if (resUpload) {
            // ansData.fileID = resUpload[0];
            ansData.url = resUpload[1];
            ansData.fileName = resUpload[2];
            ansData.realFilename = resUpload[3];
          } else {
            alertWarningMsg('อัพโหลดรูปไม่สำเร็จ');
          }
        }
      }
    }
  }

  async function upLoadFile (files) {
    try {
      let serviceName = 'adminService';
      if (props.fnServicedName) {
        serviceName = props.fnServicedName;
      }
      const obj = {
        svName: serviceName,
        fnName: props.fnUploadName,
        param: files
      }
      const upLoadImgFile = await callApi(obj);
      if (upLoadImgFile.status === 200) {
        return [upLoadImgFile.data.result._id, upLoadImgFile.data.result.url, upLoadImgFile.data.result.fileName, upLoadImgFile.data.result.originalName];
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  function removeImage (images, index) {
    if (index === -1) {
      if (images.post_img_id) {
        emit('resRemoveFile', images.post_img_id);
      }
      images.file = null;
      images.url = null;
    } else {
      if (images[index].post_img_id) {
        emit('resRemoveFile', parseInt(images[index].post_img_id));
      }

      images.splice(index, 1);
    }
  }

  // end img zone ============================================================================================
  function alertWarningMsg (txt) {
    try {
      new window.Swal({
          icon: 'warning',
          title: 'แจ้งเตือน',
          text: txt,
          confirmButtonText: 'ตกลง'
      });
    } catch (error) {
        console.log(error);
    }
  }

  async function alertForLogin () {
    const confirm = await new window.Swal({
      icon: 'warning',
      title: 'แจ้งเตือน',
      text: 'กรุณาลงชื่อเข้าใช้ หรือสมัครสมาชิก',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    });

    if (confirm.isConfirmed) {
      // call login modal
      eventBus.emit('showLoginModal');
    } 
  }

  // end function zone

  watch(() => props.postID, (newVal, oldVal) => {
    commentData.value.commentText = '';
    isShowEmoji.value = false;
    props.imgData.url = '';
    props.imgData.fileName = '';
    props.imgData.realFilename = '';
  });

  onMounted(() => {
  });

  defineExpose({
    setUpEdit
  });


</script>