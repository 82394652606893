<template>
  <div class="modal fade" id="coinHistoryTransferModal" tabindex="-1" role="dialog" aria-labelledby="coinTransferHistoryLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="coinTransferHistoryLabel">{{ $t('coin_receive_history') }}</h5>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>
                        <!-- <input type="text" class="form-control form-control-sm" placeholder="ค้นหาช่วงวันที่" v-model="searchHistory.searchDate"> -->
                        <flat-pickr
                          v-model="searchDate"
                          class="form-control flatpickr active"
                          :placeholder="$t('search_date_range')"
                          :config="{mode: 'range', dateFormat: 'd/m/Y'}"
                          @change="searchData()"
                          style="background-color:#FFFFFF!important;"
                        >
                        </flat-pickr>
                      </th>
                      <th>
                        <select class="form-select form-select-sm" v-model="searchHistory.searchStatus" style="width: 100px;" @change ="searchData()">
                          <option v-for="option in statusOptions" :value="option.id" :key="option.id" >
                            {{ option.text }}
                          </option>
                        </select>
                      </th>
                      <th colspan="2"></th>
                      <th>
                        <!-- <input type="text" class="form-control form-control-sm"  v-model="searchHistory.searchName" @change ="searchData()"> -->
                      </th>
                    </tr>
                    <tr>
                      <th>{{ $t('date_label') }}</th>
                      <th class="w100">{{ $t('type') }}</th>
                      <th class="w85">{{ $t('receive') }}</th>
                      <th class="w85">{{ $t('send') }}</th>
                      <th>{{  $t('detail') }}</th>
                    </tr>
                  </thead>
                  <tbody v-for="list in historyList">
                    <tr>
                      <td>{{ Utils.dateStr2DMY2(list.create_date) }}</td>
                      <td>{{ (list.point_type==='sender_point')? $t('send') : $t('receive') }}</td>
                      <td>{{ (list.point_type!=='sender_point')?list.point:'' }}</td>
                      <td>{{ (list.point_type==='sender_point')?list.point:'' }}</td>
                      <td>{{ checkStatusTransfer(list.point_type, list.point_detail.member_displayname) }}</td>                    
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- pagination zone -->
            <div class="ms-3 me-3">
              <pagination
                v-model="page"
                :records="total"
                :per-page="perpage"
                @paginate="pageOnChange($event)"
                :options="optionsPagination"
                :hasEdgeNav="true"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  // import zone
  import { onMounted, ref, computed  } from 'vue';
  import { useStore } from 'vuex';
  import Utils from '@/plugins/datepickerHelper.js';

  //flatpickr
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import '@/assets/sass/forms/custom-flatpickr.css';
  import moment from 'moment';
  import eventBus from '@/eventBus/eventBus.js';
  import Pagination from 'v-pagination-3';
  import MyPagination from '@/components/Pagination.vue';
  import { useI18n } from 'vue-i18n';
  // variable zone
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const { t } = useI18n();
  const coinHistoryTransferModal = ref(null);
  const memberData = ref(store.getters['auth/getMember']);
  const searchHistory = ref({
    searchDateStart: '',
    searchDateEnd: '',
    searchStatus: -1,
    searchName: ''
  });
  const searchDate = ref(null);

  const statusOptions = computed(() => [
    { text: t('all'), id: -1 },
    { text: t('receive'), id: 0 },
    { text: t('send'), id: 1 }
  ]);

  const page = ref(1);
  const perpage = ref(10);
  const total = ref(0);

  const optionsPagination = ref({
    template: MyPagination
  });

  const historyList = ref([]);
  /**
   * pointType
    add_post_point = 'สร้างโพส'
    comment_post_point = 'คอมเมนต์โพส'
    share_post_point = 'แชร์โพส'
    view_post_point = 'อ่านโพส'
    receiver_point = 'ได้รับ coin'
    sender_point = 'ได้รับ coin'
    add_audience_point = ตอบแบบสอบถาม
    add_vote_ref_point = ได้รับจาก reference vote code
    member_register_point = สมัครสมาชิก
   */
  // end valiable zone

  // function zone
  function showModal () {
    searchHistory.value.searchDateStart = '';
    searchHistory.value.searchDateEnd = '';
    searchHistory.value.searchStatus = -1;
    searchHistory.value.searchName = '';
    searchDate.value = null;
    page.value = 1;
    getHistoryTransfer();
    if (!coinHistoryTransferModal.value) {
      coinHistoryTransferModal.value = new bootstrap.Modal(document.getElementById('coinHistoryTransferModal'), {});
    }
    coinHistoryTransferModal.value.show();
  }

  function hideModal () {
    if (coinHistoryTransferModal.value) {
      coinHistoryTransferModal.value.hide();
    }
  }

  const getHistoryTransfer = async () => {
    try {
      const obj = {
        page: page.value-1,
        maxPerPage: perpage.value,
        search: {
          dateEnd: searchHistory.value.searchDateEnd,
          dateStart: searchHistory.value.searchDateStart,
          status: searchHistory.value.searchStatus,
          username: searchHistory.value.searchName
        }
      };
      const res = await callApi({
        svName: 'memberService',
        fnName: 'getHistoryTransfer',
        param: obj
      });
      if (res.status === 200) {
        historyList.value = res.data.result;
        total.value = res.data.total;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchData = () => {
    if (searchDate.value) {
      const outputFormat = "YYYY-MM-DD";
      if (searchDate.value.includes(' to ')) {
        const [dateStart, dateEnd] = searchDate.value.split(' to ');

        searchHistory.value.searchDateStart = moment(dateStart, "DD-MM-YYYY").format(outputFormat);
        searchHistory.value.searchDateEnd = moment(dateEnd, "DD-MM-YYYY").format(outputFormat);
      } else {
        const convertedDate = moment(searchDate.value, "DD/MM/YYYY").format(outputFormat);

        searchHistory.value.searchDateStart = convertedDate;
        searchHistory.value.searchDateEnd = convertedDate;
      }
    } else {
      searchHistory.value.searchDateStart = '';
      searchHistory.value.searchDateEnd = '';
    }
    page.value = 1;
    getHistoryTransfer();
  };

  const pageOnChange = (currentPage) => {
    page.value = currentPage;
    getHistoryTransfer();
  }

  const checkStatusTransfer = (status, name) => {
    let statusText = '';
    if (status === 'add_post_point') {
      statusText = t('post_news');
    } else if (status === 'comment_post_point') { 
      statusText = t('comment_post');
    } else if (status === 'share_post_point') { 
      statusText = t('share_post');
    } else if (status === 'view_post_point') { 
      statusText = t('read_news');
    } else if (status === 'receiver_point') { 
      statusText = t('receive_from')+name;
    } else if (status === 'sender_point') { 
      statusText = t('transfer_to')+name;
    } else if (status === 'add_audience_point') { 
      statusText = t('answer_survey');
    } else if (status === 'add_vote_ref_point') { 
      statusText =  t('receive_from')+'Reference vote code';
    } else if (status === 'member_register_point') { 
      statusText = t('register');
    }
    return statusText;
  }
  // end function zone

  onMounted(() => {
    coinHistoryTransferModal.value = new bootstrap.Modal(document.getElementById('coinHistoryTransferModal'), {});
    eventBus.on('showCoinHistoryTransferModal', () => {
      showModal();
    });

    eventBus.on('hideCoinHistoryTransferModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>
