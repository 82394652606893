<template>
  <div class="modal fade" id="reporterDetailVDOModal" tabindex="-1" role="dialog" aria-labelledby="reporterDetailVDOModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="reporterDetailVDOModalModalLabel">{{ $t('post_vdo_list') }} {{ displayName }}</h5>
                <button 
                  type="button" 
                  data-dismiss="modal" 
                  class="btn-close" 
                  data-bs-target="#reporterDetailModal" 
                  data-bs-toggle="modal">
                </button>
            </div>
            <!-- @scroll.stop="handleScroll" -->
            <div class="modal-body" @scroll.stop="handleScroll">
                <div class="row align-items-center g-2">
                    <div class="col-6 col-md-4 col-lg-4" v-for="item in topicVDOList">
                        <vdoBox 
                          :item="item"
                          :lastPage="'showReporterDetailVDOModal'"
                        ></vdoBox>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
    // import zone
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import eventBus from '@/eventBus/eventBus.js';
    import vdoBox from '@/components/list/newsvdoboxComponent.vue';
    // variable zone
    const store = useStore();
    const callApi = (param) => store.dispatch('api/apiCall', param);
    const reporterDetailVDOModal = ref(null);
    const previousData = ref({
        lastPage: '',
        postID: 0,
        memberID: 0,
        displayName: ''
    });

    const page = ref(0);
    const maxPerPage = ref(9);
    const isMoreTopic = ref(true);
    const topicVDOList = ref([]);
    const isLoadSuccess = ref(false);
    const displayName = ref('');
    // end valiable zone

    // function zone
    const showLoadingModal  = () => {
        eventBus.emit('showLoadingModal'); 
    };

    const hideLoadingModal = () => {
        eventBus.emit('hideLoadingModal'); 
    };

    const showModal = () => {
        showLoadingModal();
        topicVDOList.value = [];
        page.value = 0;
        isMoreTopic.value = true;
        getUserVDOTopicByID(previousData.value.memberID);
        if (!reporterDetailVDOModal.value) {
            reporterDetailVDOModal.value = new bootstrap.Modal(document.getElementById('reporterDetailVDOModal'), {});
        }
        reporterDetailVDOModal.value.show();
    };

    const hideModal = () => {
        if (reporterDetailVDOModal.value) {
            reporterDetailVDOModal.value.hide();
        }
    };

    const getUserVDOTopicByID = async (id) => {
        if (isMoreTopic.value) {
            try {
                isLoadSuccess.value = false;
                const obj = {
                    page: page.value,
                    maxPerPage: maxPerPage.value,
                    memberID: id
                };
                const res = await callApi({
                    svName: 'publicService',
                    fnName: 'getUserVDOTopic',
                    param: obj
                });
                if (res?.status === 200) {
                    for(let i = 0; i < res.data.result.length ; i++){
                    topicVDOList.value.push(res.data.result[i]);
                    }

                    if (res.data.result.length == 0) {
                        isMoreTopic.value = false;
                    }
                    isLoadSuccess.value = true;
                } 
                setTimeout(function () {
                    hideLoadingModal();
                }, 500);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleScroll = async (event) => {
        const container = event.target;
        if (container.scrollTop != 0 && container.clientHeight != 0 && container.scrollHeight != 0 && isLoadSuccess.value && isMoreTopic.value) {
            if (((container.scrollTop + container.clientHeight+2) >= container.scrollHeight) ) {
                showLoadingModal();
                page.value++;
                await getUserVDOTopicByID(previousData.value.memberID);
                setTimeout(function () {
                    hideLoadingModal();
                }, 500);
            }
        }
    };

    const getDisplayname = async (id) => {
        try {
            const res = await callApi({
                svName: 'publicService',
                fnName: 'getDisplayName',
                param: id
            });
            if (res?.status === 200) {
                displayName.value = res.data.result.member_displayname;
            } 
        } catch (error) {
            console.log(error);
        }
    };

    // end function zone

    onMounted(() => {
        reporterDetailVDOModal.value = new bootstrap.Modal(document.getElementById('reporterDetailVDOModal'), {});
        eventBus.on('showReporterDetailVDOModal', (obj) => {
            previousData.value = obj;
            getDisplayname(previousData.value.memberID);
            showModal();
        });

        eventBus.on('hideReporterDetaiVDOModal', () => {
            hideModal();
        });
    });

    defineExpose({
        showModal,
        hideModal
    });
</script>