<template>
  <div
    :id="mapID"
    style="width: 100%; height:100%;"
  >
  </div>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance, watch, toRaw } from 'vue';

  const props = defineProps({
    dataList: Array
  })

  // variable zone
  let mapID = 'mapID_'+Math.floor(Math.random() * 1000);
  let gmap = null;
  const mapOptions = {
    center: {
      lat: 13.75440677084856,
      lng: 100.49549047718747
    },
    zoom: 6,
    disableDefaultUI: true,
    mapId: '6662a9d6bb92d200', // CSite Redesign Default Theme
    gestureHandling: 'greedy'
  };
  let markerList = [];
  let dataList = [];
  // end variable zone

  // function zone
  function initMapLoader () {
    try {
      const instance = getCurrentInstance();
      if (instance) {
        const googleMapsLoader = instance.appContext.config.globalProperties.$googleMapsLoader;

        googleMapsLoader.load().then(() => {
          gmap = new google.maps.Map(document.getElementById(mapID), mapOptions);
          genMarker();
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function genMarker () {
    try {
      markerList.forEach((marker) => {
        marker.setMap(null);
      });
      markerList = [];

      for (let i = 0; i < dataList.length; i++) {
        const latLng = dataList[i];

        const tmp = new google.maps.marker.AdvancedMarkerView({
          map: toRaw(gmap),
          position: latLng
        });
        
        markerList.push(tmp);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setMapCenter (lat, lng) {
    gmap.setCenter({ lat, lng });
    gmap.setZoom(12);
  }
  // end function zone

  watch(() => props.dataList, (newVal, oldVal) => {
    dataList = newVal;
    genMarker();
  });

  onMounted(() => {
    dataList = props.dataList;
    initMapLoader();
  });

  defineExpose({
    setMapCenter
  });
</script>