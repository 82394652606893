<template>
  <div class="reporterArea">
    <div class="row g-2 justify-content-between align-items-start">
      <div class="col-auto">
        <div class="reporterThumb">
          <img
            src="../../assets/images/transparent/400x400.png"
            :style="{ backgroundImage: `url(${(item.member_img_profile)?Utils.replaceImageUrl(item.member_img_profile, 0, 2):'image/navbar/profileIcon.png'})` }"
            @click="emit('callbackShowReporterDetailModal', item.post_comment_by)"
            >
        </div>
      </div>
      <div class="col">
        <div class="reporterNameAndFollowBtn px-0 mb-1">
          <div class="reporterName">{{ item.member_displayname }}</div>
        </div>
        <div class="commentedDetail" v-if="!item.isEdit">
          {{ item.post_comment_text }}
          <div
            class="commentedImage"
            v-if="item.post_comment_file_original_path !== '' && parseInt(item.post_comment_file_type)=== 10"
          >
            <img :src="Utils.replaceImageUrl(item.post_comment_file_original_path, item.post_comment_post_id, 1)">
          </div>
          <div class="commentDatetime">
            <span class="me-2"><font-awesome-icon :icon="['far', 'calendar']" /> {{ DatepickerHelper.dateStr2DMY(item.post_comment_create_date) }}</span>
            <span class="me-2"><font-awesome-icon :icon="['far', 'clock']" /> {{ DatepickerHelper.dateStr2hhmm(item.post_comment_create_date) }}</span>
          </div>
          <div class="editCommentBtnArea" v-show="isMyComment">
            <button class="btn btn-outline-warning btn-sm rounded-circle me-1" @click="editComment()"><font-awesome-icon :icon="['fas', 'edit']" /></button>
            <button class="btn btn-outline-danger btn-sm rounded-circle me-1" @click="delComment()"><font-awesome-icon :icon="['fas', 'trash-alt']" /></button>
          </div>
          
        </div>
      </div>
    </div>
    <div class="mt-2" v-show="item.isEdit">
      <div class="commentedDetail">
        <AddCommentBox
          :commentID="item.post_comment_post_id"
          :item="item"
          :postID="postID"
          ref="addCommentBoxRef"
          @callBackSetEditMode="item.isEdit=!item.isEdit"
          @callBackCommentlist="$emit('callBackCommentlist');"
          :imgData="imgData"
          :idInputfile="'uploadFileComment'+item.post_comment_id"
          fnUploadName="uploadCommentFile"
          fnServicedName="uploadFileService"
        >
        </AddCommentBox>
      </div>
    </div>
  </div>
</template>


<script setup>
  import { ref, onMounted, defineProps, getCurrentInstance, watch } from 'vue';
  import DatepickerHelper from '@/plugins/datepickerHelper.js';
  import Utils from '@/plugins/utils.js';
  import { useStore } from 'vuex';
  import AddCommentBox from '@/components/list/addCommentBoxComponent.vue';
  import { useI18n } from 'vue-i18n';
  // variable zone
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const props = defineProps({
    item: Object,
    postID: Number
  });

  const instance = getCurrentInstance();
  const { lastTopicID, s3DevUrl, s3RedesignUrl } = instance.appContext.config.globalProperties.$globalVar;
  const isEdit = ref(false);
  const emit = defineEmits(['callBackCommentlist','callBackResetCommentList']);
  const commentEdit = ref({
    commentText: ''
  });

  const imgData = ref({
    url: '',
    fileName: '',
    realFilename: ''
  });

  const isMyComment = ref(false);
  const memberData = ref(store.getters['auth/getMember']);
  const addCommentBoxRef = ref(null);
  // end variable zone

  // function zone
  function editComment () {
    emit('callBackResetCommentList');

    commentEdit.value.commentText = props.item.post_comment_text;
    // isEdit.value = (isEdit.value)?false:true;
    isEdit.value = props.item.isEdit;

    if (props.item) {
      imgData.value.url = Utils.replaceImageUrl(props.item.post_comment_file_original_path, props.postID, 1);
      imgData.value.realFilename = props.item.post_comment_file_name;
      imgData.value.fileName = props.item.post_comment_file_original_path_name;
    }
    // set up data for edit comment
    addCommentBoxRef.value.setUpEdit(commentEdit.value);
  }


  const delComment = async () => {
    try {
      const obj = {
        postID: props.postID,
        commentID: props.item.post_comment_id
      };
      const confirm = await new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: t('confirm_del'),
        showCancelButton: true,
        confirmButtonText: t('submit'),
        cancelButtonText: t('cancel'),
        reverseButtons: true
      });

      if (confirm.isConfirmed) {
        const res = await callApi({
        svName: 'topicService',
        fnName: 'deleteComment',
        param: obj
        });
        if (res && res.status === 200) {
          new window.Swal({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'ลบคอมเมนต์สำเร็จ',
            confirmButtonText: 'ตกลง'
          });
          emit('callBackCommentlist');
          isEdit.value = false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // end function zone

  onMounted(() => {
    if (memberData.value && (Number(memberData.value.member_id) === Number(props.item.post_comment_by))) {
      isMyComment.value = true;
    } else {
      isMyComment.value = false;
    }
  });

</script>