<template>
  <div class="modal fade" id="newsfeedModal" tabindex="-1" role="dialog" aria-labelledby="newsfeedModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable hideTrackScroll" role="document">
      <div class="modal-content">
        <button type="button" data-bs-dismiss="modal" aria-label="Close"
          class="btn-close closeBtnModalCustom01" @click="backPage()"></button>
        <div class="modal-body">
          <div class="row newsDetailModal">
            <div class="col-12">
              <div class="newsHeadlineModal">{{ topicData.post_header }}</div>
              <div class="newsdateTimeViewModal">
                <div class="row g-1">
                  <div class="col-12 col-md-auto">
                    <span class="me-2"><font-awesome-icon :icon="['far', 'calendar']" /> {{ DatepickerHelper.dateStr2DMY(topicData.post_create_date) }}</span>
                    <span class="me-2"><font-awesome-icon :icon="['far', 'clock']" /> {{ DatepickerHelper.dateStr2hhmm(topicData.post_create_date) }}</span>
                    <span class="me-2"><font-awesome-icon :icon="['far', 'eye']" /> {{ topicData.post_view_count }} </span>
                    <!-- <span class="me-2"><font-awesome-icon :icon="['far', 'thumbs-up']" /> {{ topicData.post_like_count }} </span> -->
                    <!-- <span class="me-2"><font-awesome-icon :icon="['far', 'bookmark']" /> {{ topicData.post_bookmark_count }}</span> -->
                  </div>
                  <div class="col-12 col-md-auto">
                    <span class="hashtagBtn" v-for="tag in topicData.tag" @click="searchByTag(tag.post_tag_detail);">{{ tag.post_tag_detail }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div id="carouselExampleIndicators" class="carousel slide imageSlideshowArea" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <!-- video -->
                  <div class="carousel-item active" v-if="hadVideo && vdoUrl !== ''">
                    <img src="../../assets/images/transparent/600x400.png" class="imgBg">
                    <video
                      class="vdoFitScale position-absolute top-0 start-0 w-100 h-100"
                      controls
                      id="newsVideo"
                      ref="vdoPlayer"
                    >
                      <source :src="vdoUrl" type="video/mp4">
                    </video>
                    <img
                      src="../../assets/images/transparent/600x400vdo.png"
                      class="imgBg position-absolute top-0 start-0 w-100 h-100 bg-transparent"
                      @click="playVideo()"
                      v-if="!vdoPlaying"
                    >
                  </div>

                  <!-- image -->
                  <div class="carousel-item" :class="(key === 0 && !hadVideo)?'active':''" v-for="(item, key) in topicData.img">
                    <img class="d-block w-100" src="../../assets/images/transparent/600x400.png"
                    :style="{ backgroundImage: `url(${(item.post_img_hd_path)?item.post_img_hd_path:'image/transparent/600x400.png'})` }" />
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>

            <div class="col-12 reporterArea">
              <div class="row justify-content-between align-items-center ">
                <div class="col-auto d-flex align-items-center">
                  <div class="reporterThumb">
                    <img
                      src="../../assets/images/transparent/400x400.png"
                      :style="{ backgroundImage: `url(${(topicData.member_img_profile)?Utils.replaceImageUrl(topicData.member_img_profile, topicData.post_id, 2):'image/navbar/profileIcon.png'})` }"
                      @click="showReporterDetailModal(topicData.post_by)"
                    >
                  </div>
                  <div class="reporterNameAndFollowBtn">
                    <div class="reporterName">{{ topicData.member_displayname }} 
                      <button type="button" class="btn btn-xs btn-primary smBtn"
                        @click="followMember()"
                        v-if="!isMember && parseInt(topicData.post_anonymous) !== 10"
                      >
                        {{(follow)?$t('unfollow'):$t('follow')}}
                      </button>
                    </div>
                    <div class="newsAuthorBadge">
                      <!-- <img v-for="badge in topicData.badge" :src="badge.badge_img_url"  data-bs-toggle="tooltip" :title="badge.badge_name"> -->
                      <img v-for="badge in topicData.badge" :src="badge.badge_img_url">
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="newsActionBtnModal"
                    @click="translation()"
                  ><font-awesome-icon :icon="translate ? ['fas', 'redo-alt'] : ['fas', 'globe']" />
                    {{ translate ? $t('reset') : $t('translate') }}
                  </button>
                </div>
              </div>
            </div>

            <div class="col-12" >
              <div class="newsContentAreaModal" v-html="topicData.post_detail"></div>
              <div class="otherBtnArea mt-3">
                <button type="button" class="newsActionBtnModal redBtn smBtn" @click="toProtestform();">
                  <font-awesome-icon :icon="['fas', 'circle-info']" /> {{ $t('dispute_form') }}
                </button>
              </div>
              <div class="newsMapAreaModal mt-3">
                <button type="button" class="newsActionBtnModal w-100" @click="setShowMap()"><font-awesome-icon :icon="['fas', 'map-location-dot']" />
                  {{ $t('show_location_news') }}</button>
                <div class="newsMapPin" style="height: 300px;"  v-show="isShowMap">
                  <SimpleMap
                    ref="mapRef"
                    :dataList=markerData
                  ></SimpleMap>
                </div>
              </div>

              <!-- <div class="commentAreaModal"> -->
                <CommentArea
                  ref="addCommentBoxRef"
                  :postID="topicData.post_id"
                  :commentList="topicData.comment"
                  @callbackShowReporterDetailModal="showReporterDetailModal"
                ></CommentArea>
              <div class="otherNews" v-if="suggestList.length > 0">
                <div class="summaryHeadline">{{ $t('related_news') }}</div>
                <div class="row align-items-center g-2" >
                  <div class="col-6 col-md-4 col-lg-4" v-for="item in suggestList">
                    <div class="newsCardArea" @click="showModal(item.post_id)">
                      <div class="newsThumbnail">
                        <img src="../../assets/images/transparent/600x400.png"
                          :style="{ backgroundImage: `url(${(item.img[0].post_img_hd_wm_path)?Utils.replaceImageUrl(item.img[0].post_img_hd_wm_path, item.post_id, 1):'image/transparent/600x400.png'})` }"
                        >                        
                        <div class="newsDate">{{ DatepickerHelper.dateStr2thFormat(item.post_create_date) }}</div>
                        <div class="newsAuthor">
                          <div class="newsAuthorProfile">
                            <img src="../../assets/images/transparent/400x400.png"
                              :style="{ backgroundImage: `url(${(item.member_img_profile)?Utils.replaceImageUrl(item.member_img_profile, item.post_id, 2):'image/navbar/profileIcon.png'})` }"
                            >                          
                          </div>
                          {{ item.member_displayname }}
                          <div class="newsAuthorBadge">
                            <img
                              v-for="badge in item.badge"
                              :src="badge.badge_img_url"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="newsDetailArea">
                        <div class="newsHeadline">
                          {{ item.post_header }}
                        </div>
                        <div class="newsShortDetail">
                          {{ item.post_detail }}
                        </div>
                        <div class="newsHashtag">{{ item.tag_display }}</div>
                      </div>
                      <div class="row newsCountArea g-0">
                        <div class="col">
                          <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'eye']" /> {{ item.post_view_count }}</div>
                        </div>
                        <div class="col">
                          <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'heart']" /> {{ item.post_like_count }}</div>
                        </div>
                        <div class="col">
                          <div class="newsCountDetail"><font-awesome-icon :icon="['far', 'message']" /> {{ item.post_comment_count }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <div class="row d-flex align-items-center justify-content-between text-center">
            <div class="col-12 col-md-auto">
              <button type="button" class="newsActionBtnModal" :class="(topicData.is_like)?'active':''" @click="clickLike()">
                <font-awesome-icon :icon="['far', 'heart']" />
                {{ $t('like') }}
                <div class="likeCountinBtn">{{ topicData.post_like_count }}</div>
              </button>
              <button type="button" class="newsActionBtnModal" :class="(topicData.is_bookmark)?'active':''" @click="clickBookmark()">
                <font-awesome-icon :icon="['far', 'bookmark']" />
                {{ $t('save') }} 
                <div class="likeCountinBtn">{{ topicData.post_bookmark_count }}</div>
              </button>
              <button type="button" class="newsActionBtnModal" @click="sentCointoTopic()" v-show="!isMember">
                <img src="../../assets/images/c-coin.svg" style="height:20px;" class="me-1">
                {{ $t('send_coin_to_news') }} 
                <div class="likeCountinBtn">{{ topicData.post_point }}</div>
              </button>
            </div>

            <div class="col-12 col-md-auto">
              <button type="button" class="newsActionBtnModal" @click="shareFB()">
                <font-awesome-icon :icon="['fas', 'share-from-square']"/>
                {{ $t('share') }} 
              </button>
              <button type="button" class="newsActionBtnModal" @click="copyLink()">
                <font-awesome-icon :icon="['fas', 'link']" />
                {{ $t('copy_url') }} 
              </button>
            </div>

          </div>
        </div>
    </div>
  </div>
</div></template>

<script setup>
  import { onMounted, ref, getCurrentInstance  } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';

  import DatepickerHelper from '@/plugins/datepickerHelper.js';
  import Utils from '@/plugins/utils.js';
  import eventBus from '@/eventBus/eventBus.js';
  import { useI18n } from "vue-i18n";

  // import component
  import SimpleMap from '@/components/map/simpleMap.vue';
  import CommentArea from '@/components/layout/commentAreaComponent.vue';

  // variable zone
  const router = useRouter();
  const route = useRoute();
  const emit = defineEmits(['callback']);
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const memberData = ref(store.getters['auth/getMember']);

  const instance = getCurrentInstance();
  const { lastTopicID, shareServiceUrl, csiteWebUrl } = instance.appContext.config.globalProperties.$globalVar;

  const i18nLocale = useI18n();
  const { t } = useI18n();
  const dialogRef = ref(null);
  const topicID = ref(0);
  const topicData = ref({
    post_header: '',
    post_detail: '',
    post_create_date: '',
    post_view_count: 0,
    post_like_count: 0,
    tag: [],
    img: [],
    member_img_profile: '',
    member_displayname: '',
    badge: [],
    post_latitude: null,
    post_longitude: null,
    comment: []
  });
  const markerData = ref([]);
  const mapRef = ref(null);
  const isShowMap = ref(false);
  
  const suggestList = ref({});

  const like = ref(0);
  const bookmark = ref(0);
  const follow = ref(0);
  const isMember = ref(false);
  const addCommentBoxRef = ref(null);
  const hadVideo = ref(false);
  const vdoUrl = ref('');
  const vdoPlayer = ref(null);
  const vdoPlaying = ref(false);

  const previousData = ref({
    lastPage: '',
    postID: 0,
    memberID: 0
  });

  const postID = ref(null);
  const translate = ref(false);
  const storeTopic = ref({
    header: null,
    detail: null
  });
  // end variable zone

  // function zone
  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal'); 
  }

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal'); 
    }, 500);
  }

  const playVideo = () => {
    if (vdoPlayer) {
      vdoPlayer.value.play();
      vdoPlaying.value = true;
    }
  }

  const setShowMap = () => {
    isShowMap.value = !isShowMap.value;
    markerData.value = [{
      lat: parseFloat(topicData.value.post_latitude),
      lng: parseFloat(topicData.value.post_longitude)
    }];

    mapRef.value.setMapCenter(parseFloat(topicData.value.post_latitude), parseFloat(topicData.value.post_longitude));
  }

  function resetTopicData () {
    isShowMap.value = false;
    topicData.value = {
      post_header: '',
      post_detail: '',
      post_create_date: '',
      post_view_count: 0,
      post_like_count: 0,
      tag: [],
      img: [],
      member_img_profile: '',
      member_displayname: '',
      badge: [],
      post_latitude: null,
      post_longitude: null,
      comment: []
    };
    markerData.value = [];
    hadVideo.value = false;
    vdoUrl.value = '';
    vdoPlaying.value = false;

    // reset data in comment box
    if (addCommentBoxRef.value) {
      addCommentBoxRef.value.resetCommentData();
    }
  }

  async function getSuggestList () {
    try {
      const currDate = new Date();
      currDate.setDate(currDate.getDate() - 7);
      const datefrom = currDate.toISOString().split('T')[0];   
      const obj = {
        categoryID: [topicData.value.post_category_id],
        datefrom: datefrom,
        dateto: (new Date()).toISOString().split('T')[0],
        postID: topicData.value.post_id,
        page: 0,
        maxPerPage: 6
      }

      const res = await callApi({
        svName: 'publicService',
        fnName: 'getSuggestList',
        param: obj
      });

      if (res && res.status === 200) {
        if (res.data && res.data.result) {
          suggestList.value = res.data.result;
          if (suggestList.value) {
            // replace img url
            if (parseInt(suggestList.value.post_id) > lastTopicID) {
              for (let i = 0; i < suggestList.value.img.length; i++) {
                // const newImgUrl = suggestList.value.img[i].post_img_hd_wm_path.replace(s3DevUrl, s3RedesignUrl);
                const newImgUrl = suggestList.value.img[i].post_img_hd_wm_path;
                suggestList.value.img[i].post_img_hd_path = newImgUrl;
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function initTopicDetail () {
    resetTopicData();

    try {
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getTopicByID',
        param: {
          postID: topicID.value
        }
      });

      if (res && res.status === 200) {
        if (res.data && res.data.result) {
          topicData.value = res.data.result;
          if (topicData.value) {
            like.value = (topicData.value.is_like)?1:0;
            bookmark.value = (topicData.value.is_bookmark)?1:0;
            follow.value = (topicData.value.is_member_follow)?1:0;
            isMember.value = (memberData.value && (Number(memberData.value.member_id) === Number(topicData.value.post_by)))?true:false;
            
            // replace img url
            // if (parseInt(topicData.value.post_id) > lastTopicID) {
            //   for (let i = 0; i < topicData.value.img.length; i++) {
            //     const newImgUrl = topicData.value.img[i].post_img_hd_wm_path.replace(s3DevUrl, s3RedesignUrl);
            //     topicData.value.img[i].post_img_hd_path = newImgUrl;
            //   }
            // }

            if (topicData.value.vdo.length > 0) {
              if (topicData.value.vdo[0].post_video_path !== '') {
                // if (parseInt(topicData.value.post_id) > lastTopicID) {
                //   vdoUrl.value = s3RedesignUrl + '/post_vdo/' + topicData.value.vdo[0].post_video_original_path;
                // } else {
                //   vdoUrl.value = Utils.replaceImageUrl(topicData.value.vdo[0].post_video_path, topicData.value.post_id, 1);
                // }
                vdoUrl.value = topicData.value.vdo[0].post_video_path;
                hadVideo.value = true;
              }
            }
            return true;
          }
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function showModal(id) {
    showLoadingModal();
    topicID.value = parseInt(id);

    if (await initTopicDetail()) {
      await getSuggestList();

      if (dialogRef.value) {
        dialogRef.value.show();
      }

      hideLoadingModal();
    } else {
      alertWarningMsg(t('no_data_found'));
      hideLoadingModal();
    }
  }

  function hideModal() {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  }

  function shareFB () {
    const shareurl = shareServiceUrl + "share2024.php?post_id=" + topicID.value;
    const fbshare = "https://www.facebook.com/sharer/sharer.php?u=" + shareurl;
    window.open(fbshare, "_blank", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600");

    callApi({
      svName: 'publicService',
      fnName: 'addPointSharePost',
      param: {
        postID: topicID.value
      }
    })
      .then(() => {
      });
  }

  async function clickLike () {
    if (memberData.value !== null) {
      showLoadingModal();

      if (like.value === 0) {
        like.value = 1;
      } else {
        like.value = 0;
      }

      try {
        const obj = {
          postID: topicData.value.post_id,
          isLike: like.value
        };

        const res = await callApi({
          svName: 'topicService',
          fnName: 'likeTopic',
          param: obj
        });

        if (res && res.status === 200) {
          topicData.value.post_like_count = res.data.result.post_like_count;

          topicData.value.is_like = (like.value === 1)?true:false;
        }

        hideLoadingModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      alertForLogin();
    }
  }

  async function clickBookmark () {
    if (memberData.value !== null) {
      showLoadingModal();

      if (bookmark.value === 0) {
        bookmark.value = 1;
      } else {
        bookmark.value = 0;
      }

      try {
        const obj = {
          postID: topicData.value.post_id,
          isBookmark: bookmark.value
        };

        const res = await callApi({
          svName: 'topicService',
          fnName: 'bookmarkTopic',
          param: obj
        });

        if (res && res.status === 200) {
          topicData.value.post_bookmark_count = res.data.result.post_bookmark_count;

          topicData.value.is_bookmark = (bookmark.value === 1)?true:false;
        }

        hideLoadingModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      alertForLogin();
    }
  }

  async function followMember () {
    if (memberData.value !== null) {
      showLoadingModal();

      if (follow.value === 0) {
        follow.value = 1;
      } else {
        follow.value = 0;
      }

      try {
        const obj = {
          idFollow: topicData.value.post_by,
          isFollow: follow.value
        };
        
        await callApi({
          svName: 'memberService',
          fnName: 'followMember',
          param: obj
        });

        hideLoadingModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      alertForLogin();
    }
  }

  async function sentCointoTopic () {
    if (memberData.value !== null) {
      const data = {
        member_id: topicData.value.post_by,
        member_displayname: topicData.value.member_displayname,
        member_tel: topicData.value.member_tel,
        member_img_profile: topicData.value.member_img_profile,
        post_id: topicData.value.post_id,
      };
      eventBus.emit('showCoinTransferConfirmModal', data);
    } else {
      alertForLogin();
    }
  }

  function copyLink () {
    const shareurl = shareServiceUrl + "share2024.php?post_id=" + topicID.value;
    navigator.clipboard.writeText(shareurl);
    new window.Swal({
      icon: 'success',
      title: 'แจ้งเตือน',
      text: 'คัดลอกสำเร็จ',
      confirmButtonText: 'ตกลง'
    });
  }

  async function alertForLogin () {
    const confirm = await new window.Swal({
      icon: 'warning',
      title: 'แจ้งเตือน',
      text: 'กรุณาลงชื่อเข้าใช้ หรือสมัครสมาชิก',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    });

    if (confirm.isConfirmed) {
      // call login modal
      eventBus.emit('showLoginModal');
    } 
  }

  const showReporterDetailModal = (memberID) => {
    if (parseInt(topicData.value.post_anonymous) !== 10) {
      hideModal();

      const params = {
        lastPage: 'showNewsDetailModal',
        postID: topicData.value.post_id,
        memberID: memberID
      }

      eventBus.emit('showReporterDetailModal', params);
    }
  };

  const backPage = () => {
    const params = {
      postID: 0,
      memberID: previousData.value.memberID
    };

    hideModal();

    if (previousData.value.lastPage === 'showReporterDetailAddNewsModal') {
      eventBus.emit('showReporterDetailAddNewsModal', params);
    }

    if (previousData.value.lastPage === 'showReporterDetailVDOModal') {
      eventBus.emit('showReporterDetailVDOModal', params);
    }
  };
  
  const translation = async () => {
    try {
      if (!translate.value) { // Translate topic
        // Store value header and detail topic
        storeTopic.header = topicData.value.post_header;
        storeTopic.detail = topicData.value.post_detail;

        const tmp = {
          postID: postID.value,
          langSource: 'th',
          langTarget: i18nLocale.locale.value
        };

        const res = await callApi({
          svName: 'publicService',
          fnName: 'translation',
          param: tmp
        });

        if (res.status === 200 && res?.data?.result) {
          topicData.value.post_header = res.data.result.post_header;
          topicData.value.post_detail = res.data.result.post_detail;
          translate.value = true;
        }
      } else { // Use old topic value 
        topicData.value.post_header = storeTopic.header;
        topicData.value.post_detail = storeTopic.detail;
        translate.value = false;
      }
    } catch (error) {
      alertWarningMsg(t('wrong'));
    }
  };

  const alertWarningMsg = (txt) => {
    new window.Swal({
      icon: 'warning',
      title: t('txt_alert'),
      text: txt,
      confirmButtonText: t('submit')
    });
  };

  const searchByTag = (tag) => {
    if (tag) {
      const searchParams = {
        q: tag
      };

      localStorage.setItem('searchParams', JSON.stringify(searchParams));
      hideModal();

      if (route.name !== 'newslist') {
        router.push({ name: 'newslist' });
      } else {
        window.location.reload();
      }
    }
  }

  const toProtestform = () => {
    const url = csiteWebUrl + '/protestform?id=' + postID.value;
    window.open(url, '_blank');
  }
  // enf function zone

  onMounted(() => {
    // console.log(store.getters['auth/getMember']); // get member data
     
    resetTopicData();
    dialogRef.value = new bootstrap.Modal(document.getElementById('newsfeedModal'), {});

    // init event bus
    eventBus.on('showNewsDetailModal', (obj) => {
      previousData.value = obj;
      postID.value = previousData.value.postID;
  
      if (previousData.value.postID) {
        showModal(previousData.value.postID);
      }
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>