<template>
  <div class="modal fade csiteMenuModal reporterDetailModal authenAreaModal" id="surveyMenuInfoModal" tabindex="-1" aria-labelledby="surveyMenuInfoModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered ">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
          <font-awesome-icon :icon="['fas', 'circle-xmark']" />
        </button>
        <div class="modal-body">
          <img
            v-if="menuData"
            src="../../assets/images/transparent/600x400.png" 
            :style="{ backgroundImage: `url(${Utils.replaceImageUrl(menuData.menu_img_path_url, 0, 1) })` }"
            class="w-100 mb-3 imgBg"
          >
          <h4>{{ menuData?.menu_name }}</h4>
          <p>
            {{ menuData?.menu_description }}
          </p>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div>
            <button 
              type="button"  
              class="btn btn-warning me-1" 
              @click="surveyHistoryModal(menuData)"
              :disabled="!isMember"
              v-if="menuData?.pathname === 'nexusans'"
            >
              <font-awesome-icon :icon="['fas', 'clock-rotate-left']" class="me-1"  /> {{ t('survey_history') }}
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click="toSurveyExplore()"
              :disabled="menuData?.is_view_open === 0"
            >
              <font-awesome-icon :icon="['fas', 'location-dot']" class="me-1" /> {{ t('survey_explore') }}</button>
          </div>
          <button 
            type="button" 
            class="btn btn-primary" 
            @click="toSurveyAnswer()" data-bs-dismiss="modal" aria-label="Close"
            :disabled="menuData?.is_public_answer===0"
            v-if="menuData?.pathname === 'nexusans' || menuData?.pathname === 'surveyanswer'"
          >
            <font-awesome-icon :icon="['fas', 'file-pen']" class="me-1" /> {{ t('survey_ans') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- survey history modal -->
  <SurveyHistoryModal ref="surveyHistoryModalRef" v-if="menuData?.pathname === 'nexusans'"></SurveyHistoryModal>
</template>

<script setup>
  // import zone
  import { onMounted, ref, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';
  import eventBus from '@/eventBus/eventBus.js';
  import Utils from '@/plugins/utils.js';
  import SurveyHistoryModal from '@/components/modal/surveyHistoryModal.vue';
  import { useI18n } from 'vue-i18n';

  // variable zone
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const surveyHistoryModalRef = ref(null);
  const surveyMenuInfoModal = ref(null);
  const menuData = ref(null);
  const menuParams = ref({
    id: 0,
    token: ''
  });
  const instance = getCurrentInstance();
  const { csiteWebUrl } = instance.appContext.config.globalProperties.$globalVar;
  const memberData = ref(store.getters['auth/getMember']);
  const isMember = ref(false);
  const isSurveyNexus = ref(false);
  // end valiable zone

  const showModal= () => {
    isMember.value = (memberData.value)?true:false;

    if (!surveyMenuInfoModal.value) {
      surveyMenuInfoModal.value = new bootstrap.Modal(document.getElementById('surveyMenuInfoModal'), {});
    }
    surveyMenuInfoModal.value.show();
  };

  const hideModal = () => {
    if (surveyMenuInfoModal.value) {
      surveyMenuInfoModal.value.hide();
    }
  };

  const getNexusData = async () => {
    try {
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getNexusbyToken',
        param: menuParams.value
      });
      
      if (res.status === 200) {
        if (res.data.result) {
          const resData = res.data.result;
          menuParams.value.id = parseInt(resData.nexus_id);
          menuData.value.is_view_open = parseInt(resData.is_view_open);
          menuData.value.is_public_answer  = parseInt(resData.is_public_answer );
          menuData.value.nexus_id  = parseInt(resData.nexus_id);
          menuData.value.token_add  = resData.token_add;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };  

  const getSurveyData = async () => {
    try {
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getSurveyByID',
        param: menuParams.value
      });
      
      if (res.status === 200) {
        if (res.data.result) {
          const resData = res.data.result;
          menuParams.value.id = parseInt(resData.survey_id);
          menuData.value.is_view_open = parseInt(resData.is_view_open);
          menuData.value.is_public_answer = parseInt(resData.is_ans_open);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };  

  const setupData = async (data) => {
    menuData.value = data;
    menuData.value.pathname = '';

    // get id or token
    if (menuData.value.menu_url) {
      const urlObj = new URL(menuData.value.menu_url);

      if (urlObj) {
        const pathname = urlObj.pathname?urlObj.pathname.replace('/', ''):urlObj.pathname;
        menuData.value.pathname = pathname;

        if (pathname === 'nexusans') {
          menuParams.value.id = urlObj.searchParams.get('id')?urlObj.searchParams.get('id'):0;
          menuParams.value.token= urlObj.searchParams.get('token')?urlObj.searchParams.get('token'):'';
          isSurveyNexus.value = true;
          await getNexusData();
        } else if (pathname === 'surveyanswer') {
          menuParams.value.id = urlObj.searchParams.get('id')?urlObj.searchParams.get('id'):0;
          isSurveyNexus.value = true;
          await getSurveyData();
        } else {
          isSurveyNexus.value = false;
        }
      }
    }

    showModal();
  };

  const surveyHistoryModal = () => {
    if (menuData.value.menu_url) {
      if (menuData.value.pathname === 'nexusans') {
        hideModal();
        surveyHistoryModalRef.value.showModal(menuParams.value);
      }
    }
  };

  const toSurveyAnswer = async () => {
    let strToken = '';

    if (memberData.value) {
      strToken = `&member_id=${memberData.value.member_id}&member_token=${memberData.value.member_token}`;
    }

    if (menuData.value.pathname === 'nexusans') {
      // nexus
      const url = csiteWebUrl+`/nexusans?token=${menuData.value.token_add}${strToken}`;
      window.open(url, "_blank");
    } else if (menuData.value.pathname === 'surveyanswer') { 
      if (memberData.value) {
        // survey
        const url = csiteWebUrl+`/surveyanswer?id=${menuParams.value.id}&view_ans=0${strToken}`;
        window.open(url, "_blank");
      } else {
        const confirm = await new window.Swal({
          icon: 'warning',
          title: t('txt_alert'),
          text: t('please_login'),
          showCancelButton: false,
          confirmButtonText: t('submit'),
          reverseButtons: true
        });
        if (confirm.isConfirmed) {
          showLoginModal();
        }
      }
    }
  };

  const toSurveyExplore = () => {
    let newUrl = '';
    let strToken = '';

    if (memberData.value) {
      strToken = `&member_id=${memberData.value.member_id}&member_token=${memberData.value.member_token}`;
    }

    if (menuData.value.pathname === 'nexusans') {
      newUrl = csiteWebUrl + `/nexusexplore?id=${menuParams.value.id}`;
    } else if (menuData.value.pathname === 'surveyanswer') {
      newUrl = csiteWebUrl + `/surveyanswer?id=${menuParams.value.id}&view_ans=1${strToken}`;
    } else {
      newUrl = menuData.value.menu_url;
    }
    
    window.open(newUrl, "_blank");
  }

  function showLoginModal () {
    eventBus.emit('showLoginModal');
  }
  // end function zone

  onMounted(() => {
    surveyMenuInfoModal.value = new bootstrap.Modal(document.getElementById('surveyMenuInfoModal'), {});
    eventBus.on('showSurveyMenuInfoModal', (obj) => {
      setupData(obj);
      showModal();
    });

    eventBus.on('hideSurveyMenuInfoModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>

