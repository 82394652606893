<template>
  <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="notificationModalLabel">{{ $t('notification_list') }}</h5>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
        </div>
        <div class="modal-body p-0" style="height: 500px;" @scroll.stop="handleScroll">
          <div style="text-align: center; padding: 20px;" v-if="notiList.length === 0">
            <span>{{ $t('no_notification_list') }}</span>
          </div>
          <div class="notificationModalArea">
            <div v-for="item in notiList">
              <div class="media p-4 mb-0" :class="parseInt(item.is_view) === 0 ? 'notificationNewBg' : ''">
                <div class="media-aside align-self-start">
                  <img src="../../assets/images/c-coin.svg" style="width:22px"
                    v-if="parseInt(item.member_alert_type) === 4">
                  <font-awesome-icon class="text-warning" :icon="['fas', 'bullhorn']" v-else />
                </div>
                <div class="media-body">
                  <div class="data-info hoverUp" @click="clickNoti(item)">
                    <h6 class="">{{ item.member_alert_header }} {{ (parseInt(item.member_alert_type) === 3 ||
                      parseInt(item.member_alert_type) === 4) ? item.member_alert_detail : '' }}</h6>
                    <p class="">{{ Utils.dateAgo(item.member_alert_date) }}</p>
                  </div>
                </div>
                <div class="icon-status hoverUp">
                  <font-awesome-icon :icon="['fas', 'trash']" class="text-warning"
                    @click="removeNoti(item.member_alert_id)" />
                </div>
              </div>
            </div>
            <!-- <div class="media hoverUp">
              <div class="media-aside align-self-start">
                <img src="../../assets/images/c-coin.svg">
              </div>
              <div class="media-body">
                <div class="data-info">
                  <h6 class="">รับ 10 Coin จากการสมัครสมาชิกครับ 10 วัน</h6>
                  <p class="">45 min ago</p>
                </div>
              </div>
            </div>
            <div class="media hoverUp">
              <div class="media-aside align-self-start">
                <font-awesome-icon :icon="['fas', 'bullhorn']" />
              </div>
              <div class="media-body">
                <div class="data-info">
                  <h6>มีข่าวใหม่ใกล้คุณ : เยาวชนชายแดนใต้ กับกมธ.สันติภาพ:จากปัตตานีสู่รัฐสภา</h6>
                  <p>8 hrs ago</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer" v-if="notiList.length !== 0">
          <div class="row d-flex align-items-center justify-content-between text-center">
            <button type="button" class="btn text-danger btn-link btn-sm" @click="removeNotiAll()">
              <font-awesome-icon :icon="['fas', 'trash']" class="me-1" /> {{ $t('delete_all') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import Utils from '@/plugins/datepickerHelper.js';
  import eventBus from '@/eventBus/eventBus.js';
  // variable zone
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const notiList = ref([]);
  const page = ref(1);
  const perpage = ref(10);
  const isLoadSuccess = ref(false);
  const isMoreNoti = ref(true);
  // function zone
  const showModal = async () => {
    notiList.value = [];
    page.value = 1;

    await getNotiList();

    if (dialogRef.value) {
      dialogRef.value.show();
    }
  };

  const hideModal = () => {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal');
  };

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal');
    }, 1000);
  };

  const getNotiList = async () => {
    showLoadingModal();
    if (isMoreNoti.value) {
      try {
        isLoadSuccess.value = false;

        const obj = {
          isExceptCoinType: 0,
          page: page.value - 1,
          maxPerPage: perpage.value
        };

        const res = await callApi({
          svName: 'notiService',
          fnName: 'getNotification',
          param: obj
        });

        if (res?.status === 200) {
          // notiList.value = [];
          for (let i = 0; i < res.data.result.length; i++) {
            notiList.value.push(res.data.result[i]);
          }

          if (res.data.result.length == 0) {
            isMoreNoti.value = false;
          }
          isLoadSuccess.value = true;
        }

        hideLoadingModal();

      } catch (error) {
        alertWarningMsg(t('wrong'));
      }
    }
  };

  const removeNoti = async (id) => {
    try {
      const res = await callApi({
        svName: 'notiService',
        fnName: 'removeNotiByID',
        param: id
      });
      if (res?.status === 200) {
        notiList.value = [];
        page.value = 1;
        await getNotiList();
      }
    } catch (error) {
      alertWarningMsg(t('wrong'));
    }
  };

  const removeNotiAll = async () => {
    showLoadingModal();
    try {
      const res = await callApi({
        svName: 'notiService',
        fnName: 'removeAllNoti'
      });
      if (res.status === 200) {
        hideLoadingModal();
        hideModal();
      }
    } catch (error) {
      alertWarningMsg(t('wrong'));
    }
  };

  const clickNoti = (data) => {
    updateIsView(data);
    if (Number(data.post_id) === 0) {
      if (Number(data.member_alert_type) === 4) {
        eventBus.emit('showCoinHistoryTransferModal');
      }
    } else {
      const obj = {
        postID: data.post_id,
        lastPage: 'notification',
        memberID: data.member_id
      };
      eventBus.emit('showNewsDetailModal', obj);
    }
  };

  const updateIsView = async (data) => {
    try {
      const res = await callApi({
        svName: 'notiService',
        fnName: 'updateIsView',
        param: data.member_alert_id
      });
      if (res.status === 200) {
        data.is_view = 1;
        emit('callback');
      }
    } catch (error) {
      alertWarningMsg(t('wrong'));
    }

  };

  const handleScroll = async (event) => {
    const container = event.target;
    if (container.scrollTop != 0 && container.clientHeight != 0 && container.scrollHeight != 0 && isLoadSuccess.value && isMoreNoti.value) {
      if (((container.scrollTop + container.clientHeight + 2) >= container.scrollHeight)) {
        page.value++;
        await getNotiList();
      }
    }
  };

  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('notificationModal'), {});
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>