import { memberService, publicService, uploadFileService, topicService, notiService } from '../services'; // , surveyService, adminService, masterService

const apiModule = {
  namespaced: true,
  state: {
    memberService: memberService,
    publicService: publicService,
    uploadFileService: uploadFileService,
    topicService: topicService,
    notiService: notiService
    // surveyService: surveyService,
    // adminService: adminService,
    // masterService: masterService
  },
  actions: {
    async apiCall ({ commit }, data) {
      const result = await this.state.api[data.svName][data.fnName](data.param);
      return result;
    }
  }
};

export default apiModule;
