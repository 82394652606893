<template>
  <div class="modal fade csiteMenuModal reporterDetailModal" id="announceModal" tabindex="-1" role="dialog" aria-labelledby="announceModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <img class="imgBg w-100 hoverUp" style="max-height: calc(100vh - 115px);"
              @click="clickLink()" 
              src="../../assets/images/transparent/600x400.png"  
              :style="{ backgroundImage: `url(${announceData.announcePic})` }"
            >

            <div 
              class="position-absolute top-0 left-0 p-1 mt-3 bg-white text-dark bg-opacity-75 px-4" 
              v-show="announceData.announceName || announceData.announceDetail"
            >
              <span v-show="announceData.announceName !== ''">{{ announceData.announceName }}</span>
              <span v-show="announceData.announceDetail !== ''">{{ announceData.announceDetail }}</span>
            </div>
            
            <div class="modal-footer text-center">
              <div class="checkbox-success custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="chk_success" @change="selectAnnounce(showAnnounce)" :value="true" v-model="showAnnounce"/>
                <label class="custom-control-label text-dark" for="chk_success"> {{ $t('not_show_data_again') }} </label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
  import { onMounted, ref } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  // variable zone
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);

  const emit = defineEmits(['callback']);
  const announceModalRef = ref(null);
  const showAnnounce = ref(true);
  const announceData = ref({
    announceID: null,
    announceName: '',
    announceDetail: '',
    announcePic: null,
    announceLink: '',
    announceLinkTxt: ''
  });
  // end variable zone

  // function zone
  const showModal = () => {
    if (announceModalRef.value) {
      announceModalRef.value.show();
    }
    getAnnounce();
  };

  const hideModal = () => {
    if (announceModalRef.value) {
      announceModalRef.value.hide();
    }
  };

  const getAnnounce = async() => {
    try {
      const res = await callApi({
          svName: 'publicService',
          fnName: 'getAnnounce'
      });
      if (res && res.status===200) {
        let tmp = res.data.result;
        announceData.value.announceID = tmp.announce_id;
        announceData.value.announceName = tmp.announce_name?tmp.announce_name:'';
        announceData.value.announceDetail =  tmp.announce_detail?tmp.announce_detail:'';
        announceData.value.announcePic = tmp.announce_pc_pic_path?tmp.announce_pc_pic_path:null;
        announceData.value.announceLink = tmp.announce_link?tmp.announce_link:'';
        announceData.value.announceLinkTxt = tmp.announce_link_text?tmp.announce_link_text:'';
      }  
    } catch (error) {
        console.log(error);
    }
  };

  const selectAnnounce = () => {
    localStorage.setItem('isShowAnnounce', (showAnnounce.value)?0:1); // isShowAnnounce 1=แสดงกล่องม 0=ไม่แสดงกล่อง
  };

  const clickLink = () => {
    if (announceData.value.announceLink) {
      window.open(announceData.value.announceLink, "_blank");
    }
    
  };
  // end function zone

  onMounted(() => {
    announceModalRef.value = new bootstrap.Modal(document.getElementById('announceModal'), {});
    eventBus.on('showAnnounceModal', () => {
      // localStorage.removeItem('isShowAnnounce');
      showAnnounce.value = false;

      if (localStorage.getItem('isShowAnnounce')) {
        if (parseInt(localStorage.getItem('isShowAnnounce')) === 1) {
          showModal();
        }
      } else {
        showModal();
      }
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>