import HttpRequest from './core/http_request';

class TopicProvider extends HttpRequest {
  getStatus () {
    return this.request('get', '/private/status');
  }

  // topic zone ======================================================
  addPrivateTopic (data) {
    return this.request('post', '/private/topic', data);
  }

  getMyTopic (data) {
    return this.request('get', '/private/topic/myTopic', data);
  }

  getMyTopicByID (data) {
    return this.request('get', '/private/topic/id/' + data._id);
  }

  updateMyTopic (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/private/topic/id/' + id, data);
  }

  deleteMyTopic (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/private/topic/delete/' + id, data);
  }

  getMyBookmark (data) {
    return this.request('get', '/private/topic/followtopicList', data);
  }

  getMyHistory (data) {
    return this.request('get', '/private/topic/getMyHistory', data);
  }

  likeTopic (data) {
    return this.request('post', '/private/topic/like', data);
  }

  bookmarkTopic (data) {
    return this.request('post', '/private/topic/bookmark', data);
  }

  addComment (data) {
    return this.request('post', '/private/topic/comment', data);
  }

  editComment (data) {
    const id = data.commentID;
    delete data.commentID;
    return this.request('post', '/private/topic/editComment/' + id, data);
  }

  deleteComment (data) {
    const id = data.commentID;
    delete data.commentID;
    return this.request('post', '/private/topic/deleteComment/' + id, data);
  }

  // vdo
  getMyTopicVDO (data) {
    return this.request('get', '/private/topic/myTopicVdo', data);
  }

  getMyNewsBroascast (data) {
    return this.request('get', '/private/newsbroadcast/myNewsBroascast', data);
  }
}

export default TopicProvider;
