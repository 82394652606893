<template>
  <div class="modal fade" id="redeemDetailModal" tabindex="-1" role="dialog" aria-labelledby="redeemDetailModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title"><font-awesome-icon :icon="['fas', 'circle-info']" class="text-black" /> {{ $t('product_redeem_details') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row g-2 d-flex align-items-center mb-4 productImageAndDetailArea">
            <div class="col-6 m-0 p-0">
              <img 
                src="../../assets/images/transparent/400x400.png" 
                class="card-img-top imgBg" 
                :style="{ backgroundImage: `url(${(rewardData.rewardImg)})` }"
              />
            </div>
            <div class="col-6 h-100 text-center">
              <h5>{{ rewardData.rewardName }}</h5>
              <div class="coinUsageArea d-flex align-items-center">
                <div class="coinImg"><img src="../../assets/images/c-coin.svg"></div>
                <div class="coinUsage">{{ rewardData.rewardPoint }}</div>
              </div>
            </div>
            </div>
            <div class="form-group">
              <label>{{ $t('amount') }}</label>
              <div class="input-group mb-3">
                <input
                  type="number"
                  class="form-control"
                  :placeholder="$t('put') + $t('amount')"
                  v-model.number="redeemData.qty"
                  min="1"
                  @input="checkQty()"
                >
                <span class="input-group-text">{{ $t('1_piece_used') }} {{ rewardData.rewardPoint }} {{ $t('coin') }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('name_surname') }} {{ $t('recipient_address') }}</label>
              <textarea
                class="form-control"
                :placeholder="$t('put') + $t('name_surname') + ' ' + $t('recipient_address')"
                v-model="redeemData.redeemAddress"
              ></textarea>
            </div>
            <div class="form-group">
              <label>{{ $t('contact_number') }}</label>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('put') + $t('recipient_contact_number')"
                v-model="redeemData.tel"
              > 
            </div>
          </div>
        <div class="modal-footer text-right">
          <button
            type="button"
            class="btn btn-success"
            @click="goConfirmRedeem()"
          ><font-awesome-icon :icon="['far', 'circle-check']" class="me-1"/>
            {{ $t('confirm_redeem_detail') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref, watch } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  // variable zone ============================================================================================
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const rewardData = ref({
    rewardID: '',
    rewardName: '',
    rewardImg: '',
    rewardPoint: '',
  });
  const redeemData = ref({
    qty: 1,
    redeemAddress: '',
    tel: ''
  });
  // end variable zone ========================================================================================

  // function zone ============================================================================================
  const showModal = () => {
    if (rewardData.value.type === 1) {
      redeemData.value.qty = 1;
      getMemberAddress();
    }
    if (dialogRef.value) {
      dialogRef.value.show();
    }
  };

  const hideModal = () => {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  };

  const getMemberAddress = async () => {
    try {
      const res = await callApi({
        svName: 'memberService',
        fnName: 'getMemberAddress'
      });
      if (res.status === 200) {
        if (res.data.result) {
          redeemData.value.redeemAddress = res.data.result.member_address;
          redeemData.value.tel = res.data.result.member_tel;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goConfirmRedeem = () => {
    if (Number(redeemData.value.qty) === 0) {
      alertWarningMsg(t('please_specify_the_quantity'));
      redeemData.value.qty = 1;
      return false;
    }

    if ((Number(redeemData.value.qty) * Number(rewardData.value.rewardPoint)) > rewardData.value.myPoint) {
      alertWarningMsg(t('coin_not_enough'));
      redeemData.value.qty = 1;
      return false;
    }

    redeemData.value.rewardID = rewardData.value.rewardID;
    redeemData.value.rewardName = rewardData.value.rewardName;
    redeemData.value.rewardImg = rewardData.value.rewardImg;
    redeemData.value.rewardPoint = rewardData.value.rewardPoint;
    eventBus.emit('showRedeemDetailConfirmModal', redeemData.value);
    hideModal();
  };

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
        console.log(error);
    }
  };

  const checkQty = () => {
    if (Number(redeemData.value.qty) < 0 ) {
      redeemData.value.qty = 1;
    } 
  }
  // end function zone =======================================================================================
  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('redeemDetailModal'), {});
    eventBus.on('showRedeemDetailModal', (data) => {
      rewardData.value = data;
      showModal();
    });

    eventBus.on('hideRedeemDetailModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>