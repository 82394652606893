import AuthProvider from './auth_service';
import MemberProvider from './member_service';
import PublicProvider from './public_service';
import UploadfileProvider from './uploadfile_service';
import TopicProvider from './topic_service';
import NotiProvider from './noti_service';


// import SurveyProvider from './survey_service';
// import AdminProvider from './admin_service';
// import MasterProvider from './master_service';

export const authService = new AuthProvider('auth');
export const memberService = new MemberProvider('member');
export const publicService = new PublicProvider('public');
export const uploadFileService = new UploadfileProvider('uploadfile');
export const topicService = new TopicProvider('topic');
export const notiService = new NotiProvider('noti');


// export const surveyService = new SurveyProvider('survey');
// export const adminService = new AdminProvider('admin');
// export const masterService = new MasterProvider('master');
