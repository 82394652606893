// const lastTopicID = 16873;
// const lastTopicID = 39956;
const lastTopicID = 100000;
const s3DevUrl = 'https://s3-ap-southeast-1.amazonaws.com/com.csitereport.s3.dev';
const s3RedesignUrl = 'https://sgp1.digitaloceanspaces.com/csite-redesign';

class Utils {
  replaceImageUrl (url, topicID, type) {
    // if (url && url !== '') {
    //   if (type === 1) { // 1=topic ing
    //     if (parseInt(topicID) > lastTopicID) {
    //       return url.replace(s3DevUrl, s3RedesignUrl)
    //     } else {
    //       return url;
    //     }
    //   } else if (type === 2) { // 2=profile img
    //     return url.replace(s3DevUrl, s3RedesignUrl)
    //   } else {
    //     return url; 
    //   }
    // } else {
    //   return url;
    // }
    return url;
  }

  displayNumberFormat (number) {
    return Number(number).toLocaleString('en-US');
  }
}

export default new Utils();
