<template>
  <div class="modal fade csiteMenuModal reporterDetailModal " id="coinModal" tabindex="-1"
    aria-labelledby="reporterDetailModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">

      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>
        <div class="row ">
          <div class="col-12">
            <div class="coinDetail">
              <img src="../../assets/images/c-coin.svg" class="w-50">
              <div class="coinTotal">{{ memberPoint }}</div>
            </div>
          </div>
          </div>
          <div class="row g-0">
          <div class="col-6">
            <div class="coinModalBtn" @click="$router.push('/redeemlist')" data-bs-dismiss="modal" aria-label="Close">
              <div class="coinModalBtnImg"><img src="../../assets/images/coin/redeem_btn.png" ></div>
              <div class="coinModalBtnText">Redeem<br>Reward</div>
            </div>
            
          </div>
          <div class="col-6">
            <div 
              class="coinModalBtn darkness"
              @click="clickCoinTransfer()"
            >
              <div class="coinModalBtnImg"><img src="../../assets/images/coin/transfercoin_btn.png"></div>
              <div class="coinModalBtnText">Transfer<br>Coin</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  // variable zone ============================================================================================
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const memberPoint = ref(null);
  // end variable zone ========================================================================================

  // function zone ============================================================================================
  const getCoinData = async() => {
    try {
      const res = await callApi({
        svName: 'memberService',
        fnName: 'getCoin'
      });
      if (res.status === 200) {
        memberPoint.value = res.data.result.member_point;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCoinTransfer = () => {
    eventBus.emit('showCoinTransferModal', true);
  };

  function showModal() {
    getCoinData();
    if (dialogRef.value) {
      dialogRef.value.show();
    }
  }

  function hideModal() {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  }
  // end function zone =======================================================================================
  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('coinModal'), {});
    // init event bus
    eventBus.on('showCoinModal', () => {
      showModal();
    });

    eventBus.on('hideCoinModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>