import HttpRequest from './core/http_request';

class UploadfileProvider extends HttpRequest {
  uploadTopicImg (data) {
    return this.request('upload', '/private/uploadFile/topicImg', data);
  }

  uploadTopicVdo (data) {
    return this.request('upload', '/private/uploadFile/topicVdo', data);
  }

  uploadCommentFile (data) {
    return this.request('upload', '/private/uploadFile/commentFile', data);
  }

  uploadProfileImg (data) {
    return this.request('upload', '/private/uploadFile/profile', data);
  }
}

export default UploadfileProvider;
