<template>
    <div class="modal fade csiteMenuModal reporterDetailModal " id="coinTransferModal" tabindex="-1" aria-labelledby="coinTransferModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>
        <div class="modal-body">
          <div class="row ">
            <div class="col-12">
              <div class="coinDetail">
                <img src="../../assets/images/c-coin.svg" class="w-50">
                <div class="coinTotal">{{ memberPoint }}</div>
              </div>
              <h4 class="text-center mt-3"><font-awesome-icon :icon="['fas', 'magnifying-glass']" class="me-1" /> {{ $t('search_members') }}</h4>
            </div>
            <div class="col-12">
              <div class="form-group mb-2">
                <input type="text" class="form-control" :placeholder="$t('username')" v-model="searchName">
              </div>
              <div class="chooseMemberTransferCoinArea" v-show="memberSearch && memberSearch.length > 0">
                <div 
                  v-for="list in memberSearch"
                  class="memberDetailTransferArea d-flex justify-content-start hoverUp align-items-center"  
                  @click="confirmTransferCoin(list)"
                >
                  <img 
                    src="../../assets/images/transparent/400x400.png" class="imgBg w85" 
                    :style="{ backgroundImage: `url(${Utils.replaceImageUrl(list.member_img_profile, 0, 2) })` }"
                    >
                  <div class="memberDetailTransferText">
                    <div>{{ list.member_displayname }}</div>
                    <div>{{ list.member_tel }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#coinModal">
            <font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> {{ $t('txt_back') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref, watch } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  import debounce from 'lodash.debounce';
  import Utils from '@/plugins/utils.js';

  // variable zone ============================================================================================
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const memberPoint = ref(0);
  const searchName = ref('');
  const memberSearch = ref([]);
  // end variable zone ========================================================================================

  // function zone ============================================================================================
  const findMemberSearch = async () => {
    try {
      if (searchName.value !== '') {
        const res = await callApi({
          svName: 'memberService',
          fnName: 'getMemberForTransfer',
          param: { username: searchName.value }
        });
        if (res.status === 200) {
          memberSearch.value = res.data.result;
        }
      }
      hideLoadingModal();
    } catch (error) {
      console.log(error);
    }
  };

  const getCoinData = async() => {
    try {
      const res = await callApi({
        svName: 'memberService',
        fnName: 'getCoin'
      });
      if (res.status === 200) {
        memberPoint.value = res.data.result.member_point;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmTransferCoin = (data) => {
    data.post_id = 0;
    eventBus.emit('showCoinTransferConfirmModal' , data);
  };

  function showModal() {
    getCoinData();
    if (dialogRef.value) {
      dialogRef.value.show();
    }
  }

  function hideModal() {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  }

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal');
  };

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal');
    }, 1000);
  };

  // end function zone =======================================================================================

  watch(() => searchName.value, debounce((newValue) => {
    showLoadingModal();
    findMemberSearch(newValue);
  }, 500));

  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('coinTransferModal'), {});
    eventBus.on('showCoinTransferModal', (state) => {
      if (state) {
        searchName.value = '';
        memberSearch.value = [];
      }
      showModal();
      eventBus.emit('hideCoinModal');
    });

    eventBus.on('hideCoinTransferModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>