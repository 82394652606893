import axios from 'axios';
import TokenService from './token_service';
import store from '../../store';
// import store from '../../../store';

const axiosInstance = axios.create({
  baseURL: (process.env.VUE_APP_API_URL ?? 'http://localhost:3000') + '/v1',
  timeout: 120000
});

// console.log(process.env.VUE_APP_API_URL);

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = TokenService.getAccessToken();
  if (token) {
    // config.headers['Authorization'] = 'Bearer ' + token;
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, async function (error) {
  // Do something with response error
  // return Promise.reject(error);

  const originalConfig = error.config;
  if (originalConfig.url !== '/public/auth/login' && originalConfig.url !== '/public/auth/refresh-token' && error.response) {
    // Access Token was expired
    if (error.response.status === 401 && !originalConfig._retry && TokenService.isLogin()) {
      originalConfig._retry = true;
      try {
        const rs = await axiosInstance.post('/public/auth/refresh-token', {
          refreshToken: TokenService.getRefreshToken(),
          // userName: TokenService.getMember().userName
          csiteMemberID: TokenService.getMember().member_id,
          csiteToken: TokenService.getMember().csite_token
        });

        // console.log(TokenService.getMember());

        const token = rs.data;
        store.dispatch('auth/refreshToken', token.accessToken);
        TokenService.updateToken(token);
        return axiosInstance(originalConfig);
      } catch (_error) {
        if (!window.navigator.onLine && !_error.response && _error.code === 'ERR_NETWORK') {
          return Promise.reject(_error);
        } else {
          console.log(_error);
          TokenService.clear();
          return Promise.reject(_error);
        }
      }
    }
  }
  return Promise.reject(error);
});

class HttpRequest {
  constructor () {
    this.axios = axios;
  }

  async request (type, url, data) {
    // check jwt is expired before call // donut request
    if (TokenService.isExpired() && url.indexOf('/auth/') === -1 && url.indexOf('/public/') === -1) {
      try {
        const rs = await axiosInstance.post('/auth/refresh-token', {
          refreshToken: TokenService.getRefreshToken(),
          csiteMemberID: TokenService.getMember().member_id,
          csiteToken: TokenService.getMember().csite_token
        });
        const token = rs.data;
        TokenService.updateToken(token);
      } catch (_error) {
        if (!window.navigator.onLine && !_error.response && _error.code === 'ERR_NETWORK') {
          return Promise.reject(_error);
        } else {
          console.log(_error);
          TokenService.clear();
          return Promise.reject(_error);
        }
      }
    }

    let promise = null;

    switch (type) {
      case 'GET':
      case 'get':
        promise = axiosInstance.get(url, { params: data }); break;
      case 'POST':
      case 'post':
        axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
        promise = axiosInstance.post(url, JSON.stringify(data)); break;
      case 'UPLOAD':
      case 'upload':
        axiosInstance.defaults.headers.post['Content-Type'] = 'multipart/form-data;';
        const bodyFormData = new FormData();
        bodyFormData.append('file', data);
        promise = axiosInstance.post(url, bodyFormData); break;
      default :
        promise = axiosInstance.get(url, data); break;
    }
    return promise;
  }
}

export default HttpRequest;
