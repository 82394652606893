/* global localStorage */
import moment from 'moment';

class TokenService {
  getRefreshToken () {
    const token = JSON.parse(localStorage.getItem('token'));
    return token?.refreshToken;
  }

  getAccessToken () {
    const token = JSON.parse(localStorage.getItem('token'));
    return token?.accessToken;
  }

  updateToken (token) {
    localStorage.setItem('token', JSON.stringify(token));
  }

  getMember () {
    return JSON.parse(localStorage.getItem('member'));
  }

  setMember (member) {
    localStorage.setItem('member', JSON.stringify(member));
  }

  clear () {
    localStorage.removeItem('member');
    localStorage.removeItem('token');
    window.location.replace("/home");
  }

  isLogin () {
    const token = JSON.parse(localStorage.getItem('token'));
    return token !== null;
  }

  isExpired () {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token != null) {
      if (moment(token.expiresIn).isAfter()) {
        return false;
      }
    }
    return true;
  }
}
export default new TokenService();
