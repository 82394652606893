<template>
  <div class="modal fade csiteMenuModal reporterDetailModal " id="coinTransferConfirmModal" tabindex="-1" aria-labelledby="coinTransferConfirmModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>
        <div class="modal-body">
          <h4 class="text-center mt-3"><font-awesome-icon :icon="['fas', 'arrow-right-arrow-left']" class="me-2" />{{ $t('enter_quantity') }} {{ $t('and') }} {{ $t('confirm_transfer_coin') }}</h4>
          <div class="row d-flex justify-content-start align-items-center mt-4 mb-3">
            <div class="col-5 text-center">
              <div class="coinDetail">
                <img src="../../assets/images/c-coin.svg" class="w-50">
              <div class="coinTotal f30">{{ coinTransfer }}</div>
            </div>
          </div>
          <div class="col-2 text-center"><h3><font-awesome-icon :icon="['fas', 'arrow-right']" /></h3></div>
            <div class="col-5 text-center">
              <img 
                class="imgBg w120 rounded"
                src="../../assets/images/transparent/400x400.png"  
                :style="{ backgroundImage: `url(${Utils.replaceImageUrl(memberData.member_img_profile, 0, 2) })` }"
              >
              <div class="mt-1" >{{ memberData.member_displayname }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <input type="number" class="form-control" :placeholder="t('enter_quantity')" v-model="coinTransfer" @keypress="checkCoin()">
                <span class="input-group-text">Coin | {{ $t('remaining') }} {{ (memberPoint<coinTransfer)?'':memberPoint - coinTransfer  }} Coin</span>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex" :class="(memberData.post_id===0)?'justify-content-between':''">
          <button type="button" class="btn btn-warning" @click="back()" v-if="memberData.post_id === 0">
            <font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> {{ $t('txt_back') }}
          </button>
          <button type="button" class="btn btn-success" @click="confirmTransfer()">
            <font-awesome-icon :icon="['far', 'circle-check']" class="me-1" /> {{ $t('confirm_transfer_coin') }}
          </button>                
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref, watch } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import debounce from 'lodash.debounce';
  import Utils from '@/plugins/utils.js';
  // variable zone ============================================================================================
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const memberData = ref({});
  const coinTransfer = ref(0);
  const memberPoint = ref(0);
  // end variable zone ========================================================================================

  // function zone ============================================================================================
  const back = () => {
    eventBus.emit('showCoinTransferModal');
    hideModal();
  };

  const checkCoin = () => {
    if (parseInt(coinTransfer.value) <= 0 ) {
      coinTransfer.value = null;
    } 

    if (coinTransfer.value > memberPoint.value) {
      alertWarningMsg(t('coin_not_enough'));
      coinTransfer.value = null;
      return false;
    }
  };

  const confirmTransfer = async () => {
    if (validationTransfer()) {
      try {
        const obj = {
          point: coinTransfer.value,
          postID: memberData.value.post_id,
          receiveMemberID: memberData.value.member_id
        };
        const res = await callApi({
          svName: 'memberService',
          fnName: 'transferCoin',
          param: obj
        });
        if (res.status === 200) {
          const confirm = await new window.Swal({
            icon: 'success',
            title: t('txt_alert'),
            text: t('coin_sent_successfully'),
            showCancelButton: false,
            confirmButtonText: t('submit'),
            reverseButtons: true
          });
          if (confirm.isConfirmed) {
            hideModal();
            if (memberData.value.post_id !== 0) {
              const obj = {
                postID: memberData.value.post_id,
                lastPage: 'profile',
                memberID: memberData.value.member_id
              };
              eventBus.emit('showNewsDetailModal', obj);
            } else {
              window.location.reload();
            }
           
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validationTransfer = () => {
    if (!coinTransfer.value) {
      alertWarningMsg(t('enter_quantity'));
      return false;
    }

    if (parseInt(coinTransfer.value) <= 0) {
      alertWarningMsg(t('must_1_coin'));
      return false;
    }
    
    if (coinTransfer.value > memberPoint.value) {
      alertWarningMsg(t('coin_not_enough'));
      return false;
    }
    return true;
  };

  const getCoinData = async() => {
    try {
      const res = await callApi({
        svName: 'memberService',
        fnName: 'getCoin'
      });
      if (res.status === 200) {
        memberPoint.value = res.data.result.member_point;
      }
    } catch (error) {
      console.log(error);
    }
  };

  function showModal() {
    getCoinData();
    coinTransfer.value = null;
    if (dialogRef.value) {
      dialogRef.value.show();
    }
  }

  function hideModal() {
    if (dialogRef.value) {
      dialogRef.value.hide();
    }
  }

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
        console.log(error);
    }
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal');
  };

  const hideLoadingModal = () => {
    setTimeout(function () {
      eventBus.emit('hideLoadingModal');
    }, 1000);
  };

  // end function zone =======================================================================================
  watch(() => coinTransfer.value, debounce((newValue) => {
    coinTransfer.value = newValue;
    checkCoin();
  }, 500));

  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('coinTransferConfirmModal'), {});
    eventBus.on('showCoinTransferConfirmModal', (data) => {
      memberData.value = data;
      showModal();
      eventBus.emit('hideCoinTransferModal');
    });

    eventBus.on('hideCoinTransferConfirmModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>