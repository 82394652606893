<template>
  <button 
    type="button" 
    class="btn btn-primary"
    @click="uploadVdo()"
    v-show="isView === false"
  >
    <font-awesome-icon :icon="['far', 'circle-play']" class="me-1" /> 
    {{ $t('select_vdo') }}
  </button>

  <input
    type="file"
    :id="idInputfile"
    @change="onFileChange($event, vdoList)"
    class="btn btn-default"
    multiple
    style="display: none;"
    accept="video/*"
  >

  <!-- Multiple image -->
  <div
    class="addpostimg"
    v-if="vdoList && Array.isArray(vdoList)"
  >
    <div
      class="boxImage"
      v-for="(item, key) in vdoList"
      :key="key"
    >
      <div class="previewImage vdo">
        <div class="previewVdo">
          <video
            class=""
            :src="item.url"
            width="200"
            height="200"
            controls
            v-show="vdoList.length !== 0"
          />
        </div>
        <div
          class="closeObj"
        >
          <a 
            href="javascript:void(0);"
            class="delete-item"
            @click="removeVdo(vdoList, key)"
            v-show="isView === false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Single image -->
  <div
    class="addpostimg"
    v-if="vdoList && !Array.isArray(vdoList) && vdoList.url"
  >
    <div
      class="boxImage">

      <div class="previewImage vdo">
        <div class="previewVdo">
          <video
            class=""
            :src="vdoList.url"
            width="200"
            height="200"
            controls
            v-show="vdoList !== null"
          />
        </div>
        <div
          class="closeObj" 
        >
          <a 
            href="javascript:void(0);"
            class="delete-item"
            @click="removeVdo(vdoList, -1)"
            v-show="isView === false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';

  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);

  const name = 'PreviewvdoUpload';
  const props = defineProps({
    idInputfile: String,
    vdoList: Array,
    isView: Boolean,
    fnUploadName: String,
    fnServicedName: String,
    maxFileUpload: Number,
    countFileUpload: Number,
  });

  const emit = defineEmits(['resRemoveFile']);

  // upload file zone
  function uploadVdo () {
    document.getElementById(props.idInputfile).click();
  }

  async function onFileChange (event, ansData) {
    // alert('#onFileChange');
    const files = event.target.files;
    let notFileType = false;
    let hadBigFile = false;

    if (files.length > 0) {
      if ((props.countFileUpload + files.length) > props.maxFileUpload) {
        alertWarningMsg('รูปภาพและวีดิโอรวมกันจะต้องไม่เกิน 8');
        return false;
      }
      const reg = /video/;
      for (let i = 0; i < files.length; i++) {
        if (files[i] !== null) {
          if (files[i] !== null) {
            if (reg.test(files[i].type)) {
              notFileType = false;
            } else {
              notFileType = true;
              break;
            }
            if (files[i].size >= 500000000) { // 500MB
              hadBigFile = true;
              break;
            }
          }
        }
      }

      if (notFileType) {
        alertWarningMsg('กรุณาเลือกไฟล์ประเภทวีดีโอ');
      } else if (hadBigFile) {
        alertWarningMsg('ขนาดไฟล์วิดีโอจะต้องไม่เกิน 500MB');
      } else {
        // multiple
        if (Array.isArray(ansData)) {
          const resStatus = await getStatus();
          if (resStatus) {
            let countBomb = false;

            for (let i = 0; i < files.length; i++) {
              if (files[i]) {
                const resUpload = await upLoadFile(files[i]);
                if (resUpload) {
                  const nFile = {
                    fileID: resUpload[0],
                    url: resUpload[1],
                    fileName: resUpload[2],
                  };
                  ansData.push(nFile);
                } else {
                  countBomb = true;
                }
              }
            }

            if (countBomb === true) {
              alertWarningMsg('บางวิดีโออัพโหลดไม่สำเร็จ');
            }
          }
        } else {
          // single
          if (files[0]) {
            const resUpload = await upLoadFile(files[0]);
            if (resUpload) {
              if (ansData.post_video_id) {
                emit('resRemoveFile', ansData.post_video_id);
              }

              // ansData.fileID = resUpload[0];
              ansData.url = resUpload[1];
              ansData.fileName = resUpload[2];
              removeDataNotUse(ansData);
            } else {
              alertWarningMsg('อัพโหลดวิดีโอไม่สำเร็จ');
            }
          }
        }
      }
    }
  }

  async function upLoadFile (files) {
    try {
      let serviceName = 'adminService';
      if (props.fnServicedName) {
        serviceName = props.fnServicedName;
      }

      const obj = {
        svName: serviceName,
        fnName: props.fnUploadName,
        param: files
      }

      const upLoadVdoFile = await callApi(obj);

      if (upLoadVdoFile.status === 200) {
        return [upLoadVdoFile.data.result._id, upLoadVdoFile.data.result.url, upLoadVdoFile.data.result.fileName];
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function getStatus () {
    try {
      await callApi({ svName: 'memberService', fnName: 'getStatus', param: null });
      return true;
    } catch (error) {
      return false;
    }
  }

  function removeVdo (vdo, index) {
    if (index === -1) {
      if (vdo.post_video_id) {
        emit('resRemoveFile', vdo.post_video_id);
      }

      // vdo.file = null;
      vdo.url = null;
      vdo.fileName = null;
      removeDataNotUse(vdo);
    } else {
      if (vdo[index].post_video_id) {
        emit('resRemoveFile', parseInt(vdo[index].post_video_id));
      }

      vdo.splice(index, 1);
    }
  }

  function removeDataNotUse (data) {
    delete data.post_id;
    delete data.post_video_id;
    delete data.post_video_create_date;
    delete data.post_video_original_path;
    delete data.post_video_path;
    delete data.post_video_thumb_path;
  }
  // end upload file zone

  function alertWarningMsg (txt) {
    try {
      new window.Swal({
          icon: 'warning',
          title: 'แจ้งเตือน',
          text: txt,
          confirmButtonText: 'ตกลง'
      });
    } catch (error) {
        console.log(error);
    }
  }

</script>
