<template>
  <div class="modal fade csiteMenuModal reporterDetailModal" id="reporterDetailModal" tabindex="-1"
    aria-labelledby="reporterDetailModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">

      <div class="modal-content">
        <!-- <button 
          type="button" 
          class="btnClose" 
          data-bs-dismiss="modal" 
          aria-label="Close" 
          data-bs-target="#followerListModal" 
          data-bs-toggle="modal"
        >
          <font-awesome-icon :icon="['fas', 'circle-xmark']" />
        </button> -->
        <button 
          type="button" 
          class="btnClose" 
          @click="callbackPrevious()"
        >
          <font-awesome-icon :icon="['fas', 'circle-xmark']" />
        </button>
        <div class="row g-0">
          <div class="col-12 col-md-4 leftArea">

            <div class="profileImage">
              <!-- <img
                src="../../assets/images/transparent/400x400.png"
                :style="{ 'backgroundImage': `url(${Utils.replaceImageUrl(imgProfile.url, 0, 2) || 'image/navbar/profileIconlogin.png'})` }"
              > -->
              <img
                src="../../assets/images/transparent/400x400.png"
                :style="{ 'backgroundImage': `url(${imgProfile.url || 'image/navbar/profileIconlogin.png'})` }"
              >
            </div>
            <div
              class="profileName"
            >
              {{ memberData.displayname }}
            </div>
            <div
              class="profileDetail"
            >
              {{ $t('location') }} {{ memberData.provinceName }} 
              <br v-if="memberData.mediaSkillsLevelName !==''"> {{ memberData.mediaSkillsLevelName }}
            </div>
            <span class="profileBadge" >
              <div class="newsAuthorBadge" >
                <img v-for="list in memberData.badge" :src="list.badge_img_url">
              </div>
            </span>
            <div class="profileSkillCommunicate">
              <h4>{{ $t('communication_skills') }}</h4>
              <font-awesome-icon
                v-for="(icon, index) in commuSkillIcons"
                :key="'commu' + index"
                :icon="icon"
              />
            </div>

            <div class="profileCoin">
              <img src="../../assets/images/usagestat/c-coin.png" class="profileCoinImg">
              <span>{{ Utils.displayNumberFormat(memberData.memberPoint) }}</span>
            </div>

          </div>
          <div class="col-12 col-md-8 rightArea">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="reporterDetail">
                  <h4>{{ $t('interest') }}</h4>
                  <span v-show="memberData.skill.interestDetail.length === 0">-</span>
                  <ul v-show="memberData.skill.interestDetail.length > 0">
                    <li v-for="interest in memberData.skill.interestDetail">
                      {{ interest.interest_name }}
                    </li>
                  </ul>
                </div>

                <!-- <div class="reporterDetail">
                  <h4>ฝึกอบรม CJA MOOC</h4>
                  <ul>
                    <li>การถ่ายภาพอย่างง่าย</li>
                    <li>การรายงานข่าวในสถานการณ์เลือกตั้ง</li>
                  </ul>
                </div> -->
                <div class="reporterDetail">
                  <h4>{{ $t('expertise') }}</h4>
                  <ul class="reporterRatingList">
                    <li>
                      <span>{{ $t('writing') }}</span>
                      <span>
                        <font-awesome-icon
                          v-for="(icon, index) in writingSkillIcons"
                          :key="'writing' + index"
                          :icon="icon"
                        />
                      </span>
                    </li>
                    <li>
                      <span>{{ $t('photography') }}</span>
                      <span>
                        <font-awesome-icon
                          v-for="(icon, index) in photoSkillIcons"
                          :key="'photo' + index"
                          :icon="icon"
                        />
                      </span>
                    </li>
                    <li>
                      <span>{{ $t('video_shooting') }}</span>
                      <span>
                        <font-awesome-icon
                          v-for="(icon, index) in vdoShootSkillIcons"
                          :key="'vdoShoot' + index"
                          :icon="icon"
                        />
                      </span>
                    </li>
                    <li>
                      <span>{{ $t('video_editing') }}</span>
                      <span>
                        <font-awesome-icon
                          v-for="(icon, index) in vdoEditSkillIcons"
                          :key="'vdoEdit' + index"
                          :icon="icon"
                        />
                      </span>
                    </li>
                    <li>
                      <span>{{ $t('reporting') }}</span>
                      <span>
                        <font-awesome-icon
                          v-for="(icon, index) in reportingSkillIcons"
                          :key="'reporting' + index"
                          :icon="icon"
                        />
                      </span>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="reporterWorks">
                  <h4>{{ $t('post') }}</h4>
                  <div class="row g-2">
                    <div class="col-6 col-md-6">
                      <div 
                        class="worksArea hoverUp" 
                        @click="showReporterDetailAddNewsModal()"
                      >
                        <div class="worksCount">{{ memberData.postTotal }}</div>
                        <div class="worksDetail">{{ $t('news') }} C-site</div>
                      </div>
                    </div>
                    <div class="col-6 col-md-6">
                      <div 
                        class="worksArea"
                        @click="showReporterDetailVDOModal()"
                      >
                        <div class="worksCount">{{ memberData.vdoTotal }}</div>
                        <div class="worksDetail">{{ $t('vdo') }}</div>
                      </div>
                    </div>
                  </div>

                  <h4
                    class="mt-3"
                    v-if="memberData.newsBroadcast.length > 0"
                  >{{ $t('broadcast_news_rpt') }}ล่าสุด</h4>
                  <div class="row g-2" v-if="memberData.newsBroadcast.length > 0">
                    <div v-for="(news, index) in memberData.newsBroadcast"
                      :key="index"
                      class="col-4 col-md-3"
                    >
                      <!-- <div class="worksArea newsOnairThumb">
                        <img :src="news.news_broadcast_thumbnail_img_url" class="w-100">
                      </div> -->

                      <div class="worksArea newsOnairThumb" @click="showNewsbroadcastInfoModal(news)">
                        <img src="../../assets/images/transparent/400x400.png" class="w-100"
                          :style="{ 'backgroundImage': `url(${news.news_broadcast_thumbnail_img_url || 'image/transparent/600x400.png'})` }">
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row g-2">
                    <div class="col-4 col-md-3">
                      <div class="worksArea newsOnairThumb">
                        <img src="../../assets/images/transparent/400x400.png" class="w-100"
                          style="background-image:url(https://s3-ap-southeast-1.amazonaws.com/com.csitereport.s3/public_img/3fca432431de9c37d00e2435f6075d8db52874dd.jpg)">
                      </div>
                    </div>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // import zone
  import { onMounted, ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import eventBus from '@/eventBus/eventBus.js';
  import Utils from '@/plugins/utils.js';
  import { useI18n } from 'vue-i18n';

  // variable zone
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const { t } = useI18n();
  
  const reporterDetailModal = ref(null);

  const memberData = ref({
    fullname: null,
    displayname: null,
    memberPoint: 0,
    postTotal: 0,
    vdoTotal: 0,
    badge: [],
    provinceName: '',
    skill: {
      commuLv: 0,
      writingLv: 0,
      photoLv: 0,
      vdoShootLv: 0,
      vdoEditLv: 0,
      reportingLv: 0,
      interestDetail: []
    },
    mediaSkillsLevelName: '',
    newsBroadcast: []
  });

  const imgProfile = ref({
    name: null,
    url: null
  });
  
  const previousData = ref({
    lastPage: '',
    postID: 0,
    memberID: 0
  });

  const commuSkillIcons = computed(() => generateSkillIcons(memberData.value.skill.commuLv));
  const writingSkillIcons = computed(() => generateSkillIcons(memberData.value.skill.writingLv));
  const photoSkillIcons = computed(() => generateSkillIcons(memberData.value.skill.photoLv));
  const vdoShootSkillIcons = computed(() => generateSkillIcons(memberData.value.skill.vdoShootLv));
  const vdoEditSkillIcons = computed(() => generateSkillIcons(memberData.value.skill.vdoEditLv));
  const reportingSkillIcons = computed(() => generateSkillIcons(memberData.value.skill.reportingLv));
  // end valiable zone

  // function zone
  const showReporterDetailAddNewsModal = () =>  {
    hideModal();
    const params = {
      lastPage: 'showReporterDetailModal',
      postID: 0,
      memberID: previousData.value.memberID,
      displayName: memberData.value.displayname
    };
    eventBus.emit('showReporterDetailAddNewsModal', params);
  };

  const showReporterDetailVDOModal = () => {
    hideModal();
    const params = {
      lastPage: 'showReporterDetailModal',
      postID: 0,
      memberID: previousData.value.memberID,
      displayName: memberData.value.displayname
    };
    eventBus.emit('showReporterDetailVDOModal', params);
  };

  const showNewsbroadcastInfoModal = (data) => {
    // hideModal();
    const params = {
      createDate: data.create_date,
      lastPage: 'showReporterDetailModal',
      memberID: data.member_id,
      newsBroadcastUrl: data.news_broadcast_url,
      newsBroadcastDetail: data.news_broadcast_detail,
      newsBroadcastHeader: data.news_broadcast_header,
      newsBroadcastThumbnailImgUrl: data.news_broadcast_thumbnail_img_url
    };
    eventBus.emit('showNewsbroadcastInfoModal', params); 
  };

  const callbackPrevious = () => {
    hideModal();

    // callback to newsdetail modal
    if (previousData.value.lastPage === 'showNewsDetailModal') {
      const obj = {
        postID: previousData.value.postID,
        lastPage: 'showReporterDetailModal',
        memberID: memberData.value.member_id
      };
      eventBus.emit('showNewsDetailModal', obj);
    } else if (previousData.value.lastPage === 'followerListModal') {
      eventBus.emit('showFollowerListModal');
    }
  };

  const showModal = () => {
    if (!reporterDetailModal.value) {
      reporterDetailModal.value = new bootstrap.Modal(document.getElementById('reporterDetailModal'), {});
    }
    reporterDetailModal.value.show();
  };

  const hideModal = () => {
    if (reporterDetailModal.value) {
      reporterDetailModal.value.hide();
    }
  };

  const getDataPostBy = async (postBy) => {
    try {
      const tmp = {
        _id: postBy
      };

      const res = await callApi({
        svName: 'publicService',
        fnName: 'getMemberInfo',
        param: tmp
      });

      if (res.status === 200) {
        initData(res.data?.result)
        showModal();
      }
    } catch (error) {
      const msg = (error.response.data.message !== '')?error.response.data.message:'เกิดข้อผิดพลาด';
      alertWarningMsg(msg);
    }
  };

  const initData = (data) => {
    // Set fullname
    memberData.value.fullname = data.member_fullname ? data.member_fullname: null;

    // Set displayname
    memberData.value.displayname = data.member_displayname ? data.member_displayname: null;

    memberData.value.memberPoint = data.member_point ? data.member_point: 0;
    memberData.value.postTotal = data.post_total ? data.post_total: 0;
    memberData.value.vdoTotal = data.post_vdo_total ? data.post_vdo_total: 0;
    memberData.value.badge = data.badge ? data.badge: [];
    memberData.value.provinceName = data.member_province_name ? data.member_province_name: '';

    // Set skill
    if (data.member_skill) {
      memberData.value.skill.commuLv = data.member_skill.communication_level ? data.member_skill.communication_level : 0;
      memberData.value.skill.writingLv = data.member_skill.writing_level ? data.member_skill.writing_level : 0;
      memberData.value.skill.photoLv = data.member_skill.photography_level ? data.member_skill.photography_level : 0;
      memberData.value.skill.vdoShootLv = data.member_skill.video_shooting_level ? data.member_skill.video_shooting_level : 0;
      memberData.value.skill.vdoEditLv = data.member_skill.video_editing_level ? data.member_skill.video_editing_level : 0;
      memberData.value.skill.reportingLv = data.member_skill.reporting_level ? data.member_skill.reporting_level : 0;
      memberData.value.skill.interestDetail = Array.isArray(data.member_skill.interest_detail) ? data.member_skill.interest_detail : [];
    } else {
      memberData.value.skill.commuLv = 0;
      memberData.value.skill.writingLv = 0;
      memberData.value.skill.photoLv = 0;
      memberData.value.skill.vdoShootLv =  0;
      memberData.value.skill.vdoEditLv =  0;
      memberData.value.skill.reportingLv =  0;
      memberData.value.skill.interestDetail = [];
    }

    // Set img
    imgProfile.value.url = data.member_img_profile ? data.member_img_profile: null;

    // set media skills level name
    memberData.value.mediaSkillsLevelName = (data.media_skills_level_name !== '') ? t('title_status') + ' ' + data.media_skills_level_name : '';

    // Set news broadcast
    memberData.value.newsBroadcast = data.member_news_broadcast;
  };

  const generateSkillIcons = (level) => {
    const filledStars = level;
    const emptyStars = 5 - filledStars;

    const icons = [];
    for (let i = 0; i < filledStars; i++) {
      icons.push(['fas', 'star']);
    }
    for (let i = 0; i < emptyStars; i++) {
      icons.push(['far', 'star']);
    }
    return icons;
  };

  const alertWarningMsg = (txt) => {
    new window.Swal({
      icon: 'warning',
      title: t('txt_alert'),
      text: txt,
      confirmButtonText: t('submit')
    });
  };
  // end function zone

  onMounted(() => {
    reporterDetailModal.value = new bootstrap.Modal(document.getElementById('reporterDetailModal'), {});
    eventBus.on('showReporterDetailModal', (obj) => {
      previousData.value = obj;
      getDataPostBy(previousData.value.memberID);
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>