<template>
  <div class="modal fade csiteMenuModal reporterDetailModal authenAreaModal" id="gameInfoModal" tabindex="-1" aria-labelledby="gameInfoModalLabel" aria-hidden="true">
     <div class="modal-dialog  modal-dialog-centered ">
       <div class="modal-content coinModalDetailArea">
          <!-- <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" />
          </button> -->
          <div class="modal-body">
            <img 
              src="../../assets/images/transparent/600x400.png" 
              :style="{ backgroundImage: `url(${Utils.replaceImageUrl(menuData.menuImg, 0, 1) })` }"
              class="w-100 mb-3 imgBg"
            >
            <h4>{{ menuData.menuName }}</h4>
            <p>
              {{ menuData.menuDetail }}
            </p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="toPlayGame()"
            >
            <font-awesome-icon :icon="['fas', 'circle-play']" class="me-1"  /> เล่นเลย
            </button>
          </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  // import zone
  import { onMounted, ref, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';
  import eventBus from '@/eventBus/eventBus.js';
  import Utils from '@/plugins/utils.js';
  import { useI18n } from 'vue-i18n';
  // variable zone
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const gameInfoModal = ref(null);
  const menuData = ref({
    menuID: 0,
    menuName: '',
    menuDetail: '',
    menuImg: null,
    menuUrl: ''
  });
  const instance = getCurrentInstance();
  const { csiteWebUrl } = instance.appContext.config.globalProperties.$globalVar;
  const memberData = ref(store.getters['auth/getMember']);
  const isMember = ref(false);
  // end valiable zone

  // function zone
  // const setDisabledAns = () => {
  //   if (menuData.value.isSurvey) { 
  //     // survey type
  //     return (parseInt(menuData.value.isAnsOpen) === 0 || (parseInt(menuData.value.isSingle) === 1 && parseInt(menuData.value.hasAnswer) === 1));
  //   } else { 
  //     // nexus type
  //     return (parseInt(menuData.value.isPublicAnswer) === 0);
  //   }
  // };

  const showModal= () => {
    isMember.value = (memberData.value)?true:false;

    if (!gameInfoModal.value) {
      gameInfoModal.value = new bootstrap.Modal(document.getElementById('gameInfoModal'), {});
    }
    gameInfoModal.value.show();
  };

  const hideModal = () => {
    if (gameInfoModal.value) {
      gameInfoModal.value.hide();
    }
  };

  const setupData = (data) => {
    menuData.value = {};
    menuData.value.menuName = data.menu_name;
    menuData.value.menuDetail = data.menu_description;
    menuData.value.menuImg = data.menu_img_path_url;
    menuData.value.menuUrl = data.menu_url;
  };

  const toPlayGame = () => {
    if (menuData.value?.menuUrl) {
      window.open(menuData.value.menuUrl, "_blank");
    }
  }
  // end function zone

  onMounted(() => {
    gameInfoModal.value = new bootstrap.Modal(document.getElementById('gameInfoModal'), {});
    eventBus.on('showGameInfoModal', (obj) => {
      setupData(obj);
      showModal();
    });

    eventBus.on('hideGameInfoModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>

