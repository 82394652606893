<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container  fixed-top">
            <header class="header  navbar navbar-expand-sm">
                <div class="container">
                    <ul class="navbar-item theme-brand flex-row text-center">
                        <li class="nav-item theme-logo">
                            <router-link to="/home">
                                <img src="@/assets/images/navbar/headlogo.svg" class="navbar-logo" alt="logo" />
                            </router-link>
                        </li>
                        
                    </ul>
                    <!-- <div class="horizontal-menu d-none d-md-inline">
                        <a href="javascript:void(0);" class="active">หน้าหลัก</a>
                        <a href="javascript:void(0);">แผนที่ข่าว</a>
                        <a href="javascript:void(0);">Nexus</a>
                        <a href="javascript:void(0);">พื้นที่เรียนรู้</a>
                        <a href="javascript:void(0);">แคมเปญอื่นๆ</a>
                        <a href="javascript:void(0);">Global Report</a>
                    </div> -->
                    
                    <div class="navbar-item flex-row ms-md-auto">
                        <div class="dropdown nav-item notification-dropdown btn-group user-profile-dropdown btn-group mx-1" v-show="isMember">
                            <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" @click="clickShowNoti($event)" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link  hoverUp hovLg">
                            <!-- <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link  hoverUp hovLg"> -->
                                <img src="../../assets/images/alert/alertIcon.png" class="rounded-circle">
                                <div class="notificationRedDot" v-if="newAlertCnt > 0"></div>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" :class="showNoti?'show':''" aria-labelledby="ddlnotify">
                            <!-- <ul class="dropdown-menu dropdown-menu-right show" aria-labelledby="ddlnotify"> -->
                                <li v-if="notiList.length === 0">
                                    <div style="text-align: center; padding: 10px;">{{ $t('no_notification_list') }}</div>
                                </li>
                                <li role="presentation" :class="parseInt(item.is_view)===0?'notificationNewBg':''" v-for="item in notiList">
                                    <a href="javascript:;" class="dropdown-item" >
                                        <div class="media" :class="parseInt(item.member_alert_type)===4?'server-log':''">
                                            <div class="media-aside align-self-start">
                                                <img src="../../assets/images/c-coin.svg" style="width:22px" v-if="parseInt(item.member_alert_type)===4">
                                                <font-awesome-icon :icon="['fas', 'bullhorn']" v-else/>
                                            </div>
                                            <div class="media-body">
                                                <div class="data-info" @click="clickNoti(item)">
                                                    <h6 class="" >{{ item.member_alert_header }} {{(parseInt(item.member_alert_type)===3 || parseInt(item.member_alert_type)===4)?item.member_alert_detail:''}}</h6>
                                                    <p class="">{{ Utils.dateAgo(item.member_alert_date) }}</p>
                                                </div>
                                                <div class="icon-status">
                                                  <font-awesome-icon :icon="['fas', 'trash']" class="text-warning"  @click="removeNoti(item.member_alert_id)"/>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </a>
                                </li>
                                <!-- <li role="presentation">
                                    <a href="javascript:;" class="dropdown-item">
                                        <div class="media">
                                            <div class="media-aside align-self-start">
                                                <font-awesome-icon :icon="['fas', 'bullhorn']" />
                                            </div>
                                            <div class="media-body">
                                                <div class="data-info">
                                                    <h6>มีข่าวใหม่ใกล้คุณ : เยาวชนชายแดนใต้ กับกมธ.สันติภาพ:จากปัตตานีสู่รัฐสภา</h6>
                                                    <p>8 hrs ago</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a href="javascript:;" class="dropdown-item">
                                        <div class="media">
                                            <div class="media-aside align-self-start">
                                                <font-awesome-icon :icon="['fas', 'bullhorn']" />
                                            </div>
                                            <div class="media-body">
                                                <div class="data-info">
                                                    <h6>มีข่าวใหม่ใกล้บริเวณที่คุณกำหนด : เยาวชนชายแดนใต้ กับกมธ.สันติภาพ:จากปัตตานีสู่รัฐสภา</h6>
                                                    <p>8 hrs ago</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li> -->
                                <li class="text-center"><a href="javascript:void(0);"  @click="showNotificationModal();" v-if="notiList.length > 0"> {{ t('more') }}...</a></li>
                            </ul>
                        </div>

                        <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg" v-show="isMember">
                            <a href="javascript:;" class="btn dropdown-toggle btn-icon-only user nav-link" @click="showCoinModal();">
                                <img src="../../assets/images/usagestat/c-coin.png" class="rounded-circle"> 
                            </a>
                        </div>

                        <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
                            <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
                                class="btn dropdown-toggle btn-icon-only user nav-link">
                                <img :src="require(`@/assets/images/flag/${selectedLang.code}.png`)" class="rounded-circle"> 
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                                <li role="presentation" v-for="item in countryList" :key="item.code" @click.prevent="changeLanguage(item)">
                                    <a href="javascript:;" class="dropdown-item">
                                        <div class="rectangleImg"><img :src="require(`@/assets/images/flag/${item.code}.png`)"></div> {{ item.name }}
                                    </a>
                                </li>
                            </ul>
                            <!-- <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                                <li role="presentation">
                                    <router-link to="/profile" class="dropdown-item">
                                        <div class="rectangleImg"><img src="../../assets/images/flag/thai.png"></div> ภาษาไทย
                                    </router-link>
                                </li>

                                <li role="presentation">
                                    <router-link to="/profile" class="dropdown-item">
                                        <div class="rectangleImg"><img src="../../assets/images/flag/eng.png"></div> English
                                    </router-link>
                                </li>

                                <li role="presentation">
                                    <router-link to="/changePassword" class="dropdown-item">
                                        <div class="rectangleImg"><img src="../../assets/images/flag/lao.png"></div> ລາວ
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/changePassword" class="dropdown-item">
                                        <div class="rectangleImg"><img src="../../assets/images/flag/cam.png"></div> ກຳປູເຈຍ
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/changePassword" class="dropdown-item">
                                        <div class="rectangleImg"><img src="../../assets/images/flag/mai.png"></div> ມຽນມາ
                                    </router-link>
                                </li>
                            </ul> -->
                        </div>

                        <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg" v-show="isMember">
                            <router-link to="/profile" class="btn dropdown-toggle btn-icon-only user nav-link">
                                <img src="../../assets/images/navbar/profileIcon.png" class="rounded-circle">
                                <!-- <img :src="(memberData?.picture)?memberData?.picture:'../../assets/images/navbar/profileIcon.png'" class="rounded-circle"> -->
                            </router-link>
                        </div>

                        <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg" v-show="!isMember">
                            <a href="javascript:;" class="btn dropdown-toggle btn-icon-only user nav-link" @click="showLoginModal();">
                                <img src="../../assets/images/navbar/profileIconlogin.png" class="rounded-circle">
                            </a>
                        </div>

                        <!-- <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
                            <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
                                class="btn dropdown-toggle btn-icon-only user nav-link">
                                <img src="../../assets/images/navbar/profileIcon.png" class="rounded-circle">
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                                <li role="presentation">
                                    <router-link to="/profile" class="dropdown-item">
                                        <font-awesome-icon :icon="['far', 'user']" /> จัดการบัญชี
                                    </router-link>
                                </li>

                                <li role="presentation">
                                    <router-link to="/myhistory" class="dropdown-item">
                                        <font-awesome-icon :icon="['fas', 'clock-rotate-left']" /> ประวัติการชมข่าว
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/redeemhistory" class="dropdown-item">
                                        <font-awesome-icon :icon="['fas', 'clock-rotate-left']" /> ประวัติการแลกของรางวัล
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/cointransferhistory" class="dropdown-item">
                                        <font-awesome-icon :icon="['fas', 'clock-rotate-left']" /> ประวัติการ Transfer Coin
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/mypost" class="dropdown-item">
                                        <font-awesome-icon :icon="['fas', 'circle-plus']" /> โพสต์ของฉัน
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/myfollowing" class="dropdown-item">
                                        <font-awesome-icon :icon="['fas', 'heart-circle-plus']" /> ผู้ที่ฉันติดตาม
                                    </router-link>
                                </li>
                                <li role="presentation">
                                    <router-link to="/mybookmark" class="dropdown-item">
                                        <font-awesome-icon :icon="['far', 'bookmark']" />ข่าวที่บันทึก
                                    </router-link>
                                </li>
                                <li class="borderTop" role="presentation">
                                    <router-link to="" @click="signOut" class="dropdown-item">
                                        <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" /> ออกจากระบบ
                                    </router-link>
                                </li>
                            </ul>
                        </div> -->

                        <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
                            <a href="javascript:;" id="ddmenu" data-bs-toggle="dropdown" aria-expanded="false"
                                class="btn dropdown-toggle btn-icon-only user nav-link">
                                <img src="../../assets/images/navbar/hamburger.svg">
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddmenu">
                                <li role="presentation">
                                    <router-link to="/surveylist" class="dropdown-item">
                                        <font-awesome-icon :icon="['far', 'circle']" /> {{ t('survey_project') }}
                                    </router-link>
                                </li>

                                <li role="presentation">
                                    <router-link to="/learning" class="dropdown-item">
                                        <font-awesome-icon :icon="['far', 'circle']" /> {{ t('learning') }}
                                    </router-link>
                                </li>

                                <li role="presentation">
                                    <router-link to="/reportercommunity" class="dropdown-item">
                                        <font-awesome-icon :icon="['far', 'circle']" /> {{ t('reporter_community') }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div
                    class="" 
                    style="
                        position: absolute;
                        z-index: 2;
                        left: calc(50% - (161.5px/2));
                        color: #fff;
                        background-color: #ff0000a8;
                        padding: 2px 15px;
                        border-radius: 0 0 15px 15px;
                        text-align: center;
                    "
                    v-html="announceHeaderText"
                ></div>
            </header>
        </div>
        <!--  END NAVBAR  -->
    </div>
    <div class="addNewsBtn">
        <!-- <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#addNewsModal">
            <font-awesome-icon :icon="['fas', 'circle-plus']" /> <div class="addNewsBtnText">เพิ่มข่าว</div>
        </button> -->
        <button
            type="button"
            class="btn btn-success"
            @click="showAddTopicModal(0)"
            v-if="isShowAddPost"
        >
            <font-awesome-icon :icon="['fas', 'circle-plus']" /> <div class="addNewsBtnText">{{ $t('add_post') }}</div>
        </button>
    </div>
    
    <!-- showNotificationModal modal -->
    <notificationModal ref="notificationModalref" @callback="getNewAlert()"></notificationModal>
    
</template>



<script setup>
    import { onMounted, ref, computed, watch  } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import notificationModal from '@/components/modal/notificationModal.vue';
    import eventBus from '@/eventBus/eventBus.js';
    import Utils from '@/plugins/datepickerHelper.js';

    const { t } = useI18n();
    const showNoti = ref(false);
    const notiList = ref([]);
    const page = ref(1);
    const perpage = ref(3);
    const newAlertCnt = ref(0);
    const store = useStore();
    const router = useRouter();
    const callApi = (param) => store.dispatch('api/apiCall', param);
    const selectedLang = ref({
        code: 'th',
        name: 'name'
    });
    const countryList = ref(store.state.countryList);

    const i18n = useI18n();

    let memberData = ref({
        _id: null,
        username: null,
        picture: null,
    });

    const notificationModalref = ref(null);
    const isMember = ref(false);
    const isShowAddPost = computed(() => {
        return router.currentRoute.value.name !== 'reportercommunity' && router.currentRoute.value.name !== 'newsmapbox';
    });

    function setProfileData() {
        let member = JSON.parse(localStorage.getItem('member'));
        if (member) {
            getNewAlert();
            isMember.value = true;
            // memberData.value._id = member._id;
            // memberData.value.username = member.userName;
            memberData.value._id = member.member_id;
            memberData.value.member_displayname = member.member_displayname;
            memberData.value.picture = (member.member_img_profile)?member.member_img_profile:'/image/anonymous_1.png';
        }
    }

    const toggleMode = (mode) => {
        let currentTheme = 'light';
        if (mode) {
            currentTheme = mode;
            localStorage.setItem('mode', currentTheme);
            window.$appSetting.toggleMode(currentTheme);
        } else {
            currentTheme = (localStorage.getItem('mode')) ? localStorage.getItem('mode') : 'light'
        }

        window.$appSetting.toggleMode(currentTheme);
    };

    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };

    function showCoinModal () {
        eventBus.emit('showCoinModal');
    }

    function showNotificationModal () {
        showNoti.value = false;
        notificationModalref.value.showModal();
    }

    async function showAddTopicModal (topicID) {
        if (isMember.value) {
            const obj = {
                id: topicID,
                lastPage: 'header'
            };
            eventBus.emit('showAddTopicModal', obj);
        } else {
            const confirm = await new window.Swal({
                icon: 'warning',
                title: t('txt_alert'),
                text: t('please_login'),
                showCancelButton: false,
                confirmButtonText: t('submit'),
                reverseButtons: true
            });
            if (confirm.isConfirmed) {
                showLoginModal();
            }
        }
    }
    
    function showLoginModal () {
        eventBus.emit('showLoginModal');
    }

    const initNotiData = async () => {
        try {
            const obj = {
                isExceptCoinType: 0,
                page: page.value-1,
                maxPerPage: perpage.value
            };
            const res = await callApi({
                svName: 'notiService',
                fnName: 'getNotification',
                param: obj
            });
            if (res.status === 200) {
                notiList.value = res?.data?.result;
            }
        } catch (error) {
            alertWarningMsg(t('wrong'));
        }
    }

    const clickShowNoti = async (e) => {
        e.preventDefault();
        await initNotiData();
        showNoti.value = true;
    };

    const clickNoti = (data) => {
        updateIsView(data);
        if (Number(data.post_id)===0) {
            if (Number(data.member_alert_type)===4) {
                eventBus.emit('showCoinHistoryTransferModal'); 
                showNoti.value = false;
            }
        } else {
            const obj = {
                postID: data.post_id,
                lastPage: 'notification',
                memberID: data.member_id
            };
            eventBus.emit('showNewsDetailModal', obj);
            showNoti.value = false;
        }
    };

    const removeNoti = async (id) => {
        try {
            const res = await callApi({
                svName: 'notiService',
                fnName: 'removeNotiByID',
                param: id
            });
            if (res.status === 200) {
                await initNotiData();
                showNoti.value = true;
            }
        } catch (error) {
            alertWarningMsg(t('wrong'));
        }
    };

    const updateIsView = async (data) => {
        try {
            const res = await callApi({
                svName: 'notiService',
                fnName: 'updateIsView',
                param: data.member_alert_id
            });
            if (res.status === 200) {
                data.is_view = 1;
            }
        } catch (error) {
            alertWarningMsg(t('wrong'));
        }
        getNewAlert();
    };

    const getNewAlert = async () => {
        try {
            const res = await callApi({
                svName: 'notiService',
                fnName: 'getnewAlert',
                param: { isExceptCoinType: 0 }
            });
            if (res.status === 200) {
                newAlertCnt.value = res.data.result.new_alert_cnt;
            }
        } catch (error) {
            alertWarningMsg(t('wrong'));
        }
    };

    const alertWarningMsg = (txt) => {
        try {
        new window.Swal({
            icon: 'warning',
            title: t('txt_alert'),
            text: txt,
            confirmButtonText: t('submit')
        });
        } catch (error) {
            console.log(error);
        }
    };

    const announceHeaderText = computed(() => {
        return (router.currentRoute.value.name === 'learning') ? 'หน้าเว็บอยู่ระหว่างการปรับปรุงข้อมูล<br>ขออภัยที่ทำให้ท่านได้รับความไม่สะดวก' : 'อยู่ระหว่างปรับปรุง';
    });
    // end function zone

    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
        setProfileData();
        // getNewAlert();
    });
</script>
