<template>
  <div class="commentAreaModal">
    <div class="reporterArea">
      <div class="row g-2 justify-content-between align-items-start ">
        <div class="col-auto" v-if="memberData">
          <div class="reporterThumb">
            <img src="../../assets/images/transparent/400x400.png"
              :style="{ backgroundImage: `url(${Utils.replaceImageUrl(memberData.member_img_profile, 0, 2) })` }"
            >
          </div>
        </div>
        <div class="col">
          <div class="reporterNameAndFollowBtn px-0 mb-1" v-if="memberData">
            <div class="reporterName">{{ memberData.member_displayname }}</div>
          </div>
          <AddCommentBox
            @callBackCommentlist="getCommentList()"
            :commentID="0"
            :postID="postID"
            :imgData="imgData"
            idInputfile="uploadFileComment1"
            fnUploadName="uploadCommentFile"
            fnServicedName="uploadFileService"
          >
          </AddCommentBox>
        </div>
      </div>
    </div>
    <div class="commentHistoryArea">
      <CommentBox
        v-for="(item , key) in commentList" 
        :item="item"
        :postID="postID"
        @callBackCommentlist="getCommentList()"
        @callBackResetCommentList="resetCommentList(key)"
        @callbackShowReporterDetailModal="callbackShowReporterDetailModal"
      ></CommentBox>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, defineProps, getCurrentInstance, watch  } from 'vue';
  import { useStore } from 'vuex';

  import CommentBox from '@/components/list/commentboxComponent.vue';
  import AddCommentBox from '@/components/list/addCommentBoxComponent.vue';

  import Utils from '@/plugins/utils.js';


  // variable zone
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const store = useStore();
  const emit = defineEmits(['callbackShowReporterDetailModal']);

  const memberData = ref(store.getters['auth/getMember']);
  const props = defineProps({
    postID: Number,
    commentList: Array
  });

  const commentData = ref({
    commentText: '',
    fileName: '',
    realFilename: ''
  });

  const commentList = ref([]);
  const imgData = ref({
    url: '',
    fileName: '',
    realFilename: ''
  });
  // end variable zone

  // function zone
  const callbackShowReporterDetailModal = (memberID) => {
    emit('callbackShowReporterDetailModal', memberID);
  }
  const resetCommentData = () => {
    commentData.value.commentText = '';
  }

  async function getCommentList () {
    try {
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getComment',
        param: {postID: props.postID}
      });
      if (res && res.status === 200) {
        commentData.value.commentText = '';
        commentList.value = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
  }

  function resetCommentList (idx) {
    commentList.value[idx].isEdit = true;
    for (let i = 0; i < commentList.value.length; i++) {
      if (i !== idx) {
          commentList.value[i].isEdit = false;
      }
    }
  }

  // end function zone
  watch(() => props.commentList, (newVal, oldVal) => {
    commentList.value = newVal;
    for (let i = 0 ;i < commentList.value.length;i++) {
      commentList.value[i].isEdit = false;
    }
  });

  onMounted(() => {
  });
  
  defineExpose({     
    resetCommentData
  });
</script>