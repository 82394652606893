import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/newslist/:id?',
    name: 'newslist',
    props: true,
    component: () => import('../views/newslist.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/extcontentlist',
    name: 'extcontentlist',
    component: () => import('../views/extcontentlist.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/vdolist',
    name: 'vdolist',
    component: () => import('../views/vdolist.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/events.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/usagestat',
    name: 'usagestat',
    component: () => import('../views/usagestat.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/mapdemo',
    name: 'mapdemo',
    component: () => import('../views/demo/mapDemo.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/reportercommunity',
    name: 'reportercommunity',
    component: () => import('../views/reportercommunity.vue'),
    meta: { layout: 'app', sidebar: false, footer: false }
  },
  {
    path: '/nandemo',
    name: 'nandemo',
    component: () => import('../views/demo/nanDemo.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/newsmap',
    name: 'newsmap',
    component: () => import('../views/newsmap.vue'),
    meta: { layout: 'app', sidebar: false, footer: false }
  },
  {
    path: '/newsmapbox',
    name: 'newsmapbox',
    component: () => import('../views/newsmapbox.vue'),
    meta: { layout: 'app', sidebar: false, footer: false }
  },
  {
    path: '/learning',
    name: 'learning',
    component: () => import('../views/learning.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile.vue'),
    meta: { layout: 'app', sidebar: false, footer: true, authorize: 'member' }
  },
  {
    path: '/redeemlist',
    name: 'redeemlist',
    component: () => import('../views/redeemlist.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/testlogin',
    name: 'testlogin',
    component: () => import('../views/testlogin.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/logindemo',
    name: 'logindemo',
    component: () => import('../views/demo/loginDemo.vue'),
    meta: { layout: 'app', sidebar: false, footer: false }
  },
  {
    path: '/surveylist',
    name: 'surveylist',
    component: () => import('../views/surveylist.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/surveyans',
    name: 'surveyans',
    component: () => import('../views/surveyans.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/renewpassword/:token?',
    name: 'renewpassword',
    props: true,
    component: () => import('../views/renewpassword.vue'),
    meta: { layout: 'app', sidebar: false, footer: true }
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('../views/aboutus.vue'),
    meta: { layout: 'app', sidebar: false, footer: false }
  }
];

const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  // const publicPages = ['login', 'register', 'pass-recovery','emailverification', 'forgotpassword'];
  // const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('member');
  // const memberData = JSON.parse(loggedIn); // comment
  const token = localStorage.getItem('token');
  const { authorize } = to.meta; // comment

  if (authorize && authorize === 'member' && !loggedIn && !token) {
    next('/home');
  }

  if (to.path === '/') {
    return next('/home');
  }

  if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
    store.commit('setLayout', 'auth');
  } else {
    store.commit('setLayout', 'app');
  }

  next();
});

export default router;
