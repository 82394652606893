const facebookAppId = '243033522176031';

export function initFacebookSdk () {
  window.fbAsyncInit = function () {
    FB.init({
      appId: facebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v16.0'
    });
  };

  // load facebook sdk script
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}
