import moment from 'moment';
require('moment/locale/th'); // Import the Thai locale

class DatepickerHelper {
  dateStr2thFormat (dateStr) {
    if (dateStr) {
      const newDate = moment(dateStr);
      return newDate.format('D MMM', { locale: 'th' }) + ' ' +  newDate.add(543, 'y').format('YYYY');
    } else {
      return dateStr;
    }
  }

  dateStr2DMY (dateStr) {
    return dateStr ? moment(dateStr).format('DD-MM-YYYY') : '';
  }

  dateStr2DMY2 (dateStr) {
    return dateStr ? moment(dateStr).format('DD/MM/YYYY') : '';
  }

  dateStr2hhmm (dateStr) {
    return dateStr ? moment(dateStr).format('HH:mm') : '';
  }

  dateAgo (dateStr) {
    const date = new Date(dateStr);
    const currentDate = new Date();
    const timeDifference = currentDate - date;

    // seconds
    const seconds = Math.floor(timeDifference / 1000);

    // minutes
    const minutes = Math.floor(seconds / 60);

    // hours
    const hours = Math.floor(minutes / 60);

    // days
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  }
}

export default new DatepickerHelper();
