<template>
  <button 
    type="button" 
    class="btn btn-primary" 
    @click="uploadImage()"
    v-show="isView === false"
  >
    <font-awesome-icon :icon="['far', 'images']" class="me-1"/> 
    {{ $t('select_photo') }}
  </button>

  <input
    type="file"
    :id="idInputfile"
    @change="onFileChange($event, imgList)"
    class="btn btn-default"
    multiple
    style="display: none;"
    accept="image/jpeg"
  >

  <!-- Multiple image -->
  <div
    class="addpostimg"
    v-if="imgList && Array.isArray(imgList)"
  >
    <div
      class="boxImage"
      v-for="(item, key) in imgList"
      :key="key"
    >
      <div
        class="previewImage"
        :style="{ backgroundImage: `url(${item.url})` }"
      >
        <div
          class="closeObj"
        >
          <a 
            href="javascript:void(0);"
            class="delete-item"
            @click="removeImage(imgList, key)"
            v-show="isView === false"
          >
            <font-awesome-icon :icon="['fas', 'circle-xmark']" class="text-danger"/>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Single image -->
  <div
    class="addpostimg"
    v-if="imgList && !Array.isArray(imgList) && imgList.url"
  >
  <div
      class="boxImage">
      <div
        class="previewImage"
        :style="{ backgroundImage: `url(${imgList.url})` }"
      >
        <div
          class="closeObj" 
        >
          <a 
            href="javascript:void(0);"
            class="delete-item"
            @click="removeImage(imgList, -1)"
            v-show="isView === false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="red"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- loading area -->
  <!-- <modal-loading :isShowLoading="isShowLoading"></modal-loading> -->
</template>

<script setup>
  import { useStore } from 'vuex';

  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);

  const name = 'PreviewimgUpload';
  const props = defineProps({
    idInputfile: String,
    imgList: Array,
    isView: Boolean,
    fnUploadName: String,
    fnServicedName: String,
    maxFileUpload: Number,
    countFileUpload: Number,
    delID: Array
  });

  const emit = defineEmits(['resRemoveFile']);

  // upload file zone
  function uploadImage () {
    document.getElementById(props.idInputfile).click();
  }

  async function onFileChange (event, ansData) {
    // alert('#onFileChange');
  
    const files = event.target.files;
    let notFileType = false;
    let hadBigFile = false;

    if (files.length > 0) {
      if ((props.countFileUpload + files.length) > props.maxFileUpload) {
        alertWarningMsg('รูปภาพและวีดิโอรวมกันจะต้องไม่เกิน 8');
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i] !== null) {
          if (files[i].type !== 'image/jpeg') {
            notFileType = true;
            break;
          } else if (files[i].size >= 100000000) { // 100MB
            hadBigFile = true;
            break;
          }
        }
      }

      if (notFileType) {
        alertWarningMsg('กรุณาเลือกไฟล์รูปภาพประเภท JPEG');
      } else if (hadBigFile) {
        alertWarningMsg('ขนาดไฟล์ไม่เกิน 100MB');
      } else {
        // multiple
        if (Array.isArray(ansData)) {
          const resStatus = await getStatus();
          if (resStatus) {
            let countBomb = false;

            for (let i = 0; i < files.length; i++) {
              if (files[i]) {
                const resUpload = await upLoadFile(files[i]);
                if (resUpload) {
                  const nFile = {
                    fileID: resUpload[0],
                    url: resUpload[1],
                    fileName: resUpload[2],
                  };
                  ansData.push(nFile);
                } else {
                  countBomb = true;
                }
              }
            }

            if (countBomb === true) {
              alertWarningMsg('บางรูปอัพโหลดไม่สำเร็จ');
            }
          }
        } else {
          // single
          if (files[0]) {
            const resUpload = await upLoadFile(files[0]);
            if (resUpload) {
              if (ansData.post_img_id) {
                emit('resRemoveFile', ansData.post_img_id);
              }
              
              // ansData.fileID = resUpload[0];
              ansData.url = resUpload[1];
              ansData.fileName = resUpload[2];
            } else {
              alertWarningMsg('อัพโหลดรูปไม่สำเร็จ');
            }
          }
        }
      }
    }
  }

  async function upLoadFile (files) {
    try {
      let serviceName = 'adminService';
      if (props.fnServicedName) {
        serviceName = props.fnServicedName;
      }

      const obj = {
        svName: serviceName,
        fnName: props.fnUploadName,
        param: files
      }

      const upLoadImgFile = await callApi(obj);

      if (upLoadImgFile.status === 200) {
        return [upLoadImgFile.data.result._id, upLoadImgFile.data.result.url, upLoadImgFile.data.result.fileName];
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function getStatus () {
    try {
      await callApi({ svName: 'memberService', fnName: 'getStatus', param: null });
      return true;
    } catch (error) {
      return false;
    }
  }

  function removeImage (images, index) {
    if (index === -1) {
      if (images.post_img_id) {
        emit('resRemoveFile', images.post_img_id);
      }

      images.file = null;
      images.url = null;
    } else {
      if (images[index].post_img_id) {
        emit('resRemoveFile', parseInt(images[index].post_img_id));
      }

      images.splice(index, 1);
    }
  }
  // end upload file zone

  function alertWarningMsg (txt) {
    try {
      new window.Swal({
          icon: 'warning',
          title: 'แจ้งเตือน',
          text: txt,
          confirmButtonText: 'ตกลง'
      });
    } catch (error) {
        console.log(error);
    }
  }

</script>
