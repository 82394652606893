import HttpRequest from './core/http_request';

class MemberProvider extends HttpRequest {
  getStatus () {
    return this.request('get', '/private/member/status');
  }

  // Profile zone ======================================================
  getMyProfile () {
    return this.request('get', '/private/member/myProfile');
  }

  linkFacebook (data) {
    return this.request('post', '/private/member/linkFacebook', data);
  }

  unlinkFacebook () {
    return this.request('get', '/private/member/linkFacebook');
  }

  changePassword (data) {
    return this.request('post', '/private/member/updatePassword', data);
  }

  updateProfile (data) {
    return this.request('post', '/private/member/myProfile', data);
  }

  // topic zone ======================================================
  addPrivateTopic (data) {
    return this.request('post', '/private/topic', data);
  }

  getMyTopic (data) {
    return this.request('get', '/private/topic/myTopic', data);
  }

  getMyTopicByID (data) {
    return this.request('get', '/private/topic/id/' + data._id);
  }

  updateMyTopic (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/private/topic/id/' + id, data);
  }

  deleteMyTopic (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/private/topic/delete/' + id, data);
  }

  // bookmark ========================================================
  getMyBookmark (data) {
    return this.request('get', '/private/topic/followtopicList', data);
  }

  getMyHistory (data) {
    return this.request('get', '/private/topic/getMyHistory', data);
  }

  likeTopic (data) {
    return this.request('post', '/private/topic/like', data);
  }

  bookmarkTopic (data) {
    return this.request('post', '/private/topic/bookmark', data);
  }

  followMember (data) {
    return this.request('post', '/private/member/memberFollow', data);
  }

  // coin =============================================================
  getCoin () {
    return this.request('get', '/private/member/getPoint');
  }

  getMemberForTransfer (data) {
    return this.request('get', '/private/transfercoin/memberForTransfer', data);
  }

  transferCoin (data) {
    return this.request('post', '/private/transfercoin/memberForTransfer', data);
  }

  getHistoryTransfer (data) {
    return this.request('get', '/private/transfercoin/transferData', data);
  }

  // Notification
  getNotification () {
    return this.request('get', '/private/member/memberNotification');
  }

  saveNotification (data) {
    return this.request('post', '/private/member/memberNotification', data);
  }

  // redeem reward ====================================================
  getReward (data) {
    return this.request('get', '/private/redeem/', data);
  }

  getMemberAddress () {
    return this.request('get', '/private/redeem/memberAddress');
  }

  addRedeem (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/private/redeem/id/' + id, data);
  }

  getRedeemByMember (data) {
    return this.request('get', '/private/redeem/redeemByMember', data);
  }

  // persona ===========================================================
  isExistPersona () {
    return this.request('get', 'private/persona/isExistPersona');
  }

  addPersona (data) {
    return this.request('post', 'private/persona', data);
  }

  // follower =========================================================
  getFollower () {
    return this.request('get', 'private/member/memberFollow');
  }

  unFollow (data) {
    return this.request('post', 'private/member/memberUnFollow', data);
  }
}

export default MemberProvider;
