<template>
  <div class="modal fade csiteMenuModal reporterDetailModal authenAreaModal" id="loginModal" tabindex="-1" aria-labelledby="reporterDetailModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered ">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>
        <div class="modal-body">
          <div class="panel-body underline-content tabs ">
            <ul class="nav nav-tabs mb-3 " id="lineTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="loginFormTab"
                  data-bs-toggle="tab"
                  href="#loginForm"
                  role="tab"
                  aria-controls="loginForm"
                  aria-selected="true"
                  @click="setActiveTab('loginForm')"
                  :class="{ active: activeTab === 'loginForm' }"
                >
                <font-awesome-icon :icon="['fas', 'shield-heart']" />
                  {{ $t('login') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="registerTab"
                  data-bs-toggle="tab"
                  href="#register"
                  role="tab"
                  aria-controls="register"
                  aria-selected="false"
                  @click="setActiveTab('register')"
                  :class="{ active: activeTab === 'register' }"
                >
                  <font-awesome-icon :icon="['far', 'address-card']" />
                  {{ $t('register') }}
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane fade w-100"
                :class="{ show: activeTab === 'loginForm', active: activeTab === 'loginForm' }"
                id="loginForm"
                role="tabpanel"
                aria-labelledby="loginFormTab"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="form-group" :class="(loginW === 'email')?'':'d-none'">
                      <label for="currentpass">{{ $t('email') }}</label>
                      <input 
                        type="text" 
                        class="form-control mb-2"
                        id="username" 
                        :placeholder="$t('enter_email_regis')" 
                        v-model="loginData.email"
                        @keyup.enter="login()"
                      />
                    </div>
                    <!-- <div class="form-group" :class="(loginW === 'email')?'':'d-none'">
                      <label for="newpass">{{ $t('password') }}</label>
                      <input 
                        :type="password"
                        class="form-control mb-2" 
                        id="password" 
                        :placeholder="$t('enter_pw')"
                        v-model="loginData.password"
                        @keyup.enter="login()"
                      />
                    </div> -->

                    <div class="form-group" :class="(loginW === 'email') ? '' : 'd-none'">
                      <label for="newpass">{{ $t('password') }}</label>
                      <div class="password-input-wrapper d-flex align-items-center">
                        <input 
                            type="password"
                            class="form-control mb-2" 
                            id="password" 
                            :placeholder="$t('enter_pw')"
                            v-model="loginData.password"
                            @keyup.enter="login()"
                        />
                        <!-- <span>
                          <font-awesome-icon 
                            :icon="['far', showPassword ? 'eye' : 'eye-slash']" 
                            @click="setshowPassword"
                            class="toggle-password-icon"
                        />
                        </span> -->
                      </div>
                    </div>



                    <!-- แสดงผลกรอกเบอร์ เมื่อสมาชิกกดเลือก Login with SMS และ ซ่อนการเข้าระบบด้วย email และ password-->
                    <div class="form-group" :class="(loginW === 'sms')?'':'d-none'">
                      <label for="currentpass">{{ $t('mobile') }}</label>
                      <input 
                        type="text" 
                        class="form-control mb-1" 
                        id="mobileno" 
                        :placeholder="$t('put_mobile_for_otp')"
                        v-model="mobileNo"
                        :disabled="OTPRef !== null"
                      />
                    </div>

                    <!-- แสดงผลช่องกรอก OTP Passcode -->
                    <div class="form-group" :class="OTPRef === null ? 'd-none' : (loginW === 'sms' ? '':'d-none')">
                      <label for="currentpass">{{ $t('otp_ref') }} {{OTPRef}}</label>
                      

                      <div class="input-group">
                      <input 
                        type="text" 
                        class="form-control" 
                        id="mobileno" 
                        :placeholder="$t('otp_passcode')"
                        v-model="OTPPasscode"
                      />
                        <button
                          class="btn btn-outline-primary"
                          type="button"
                          @click="OTPRequest()"
                          :disabled="disableRequestOTP"
                        >
                        <font-awesome-icon
                          :icon="['fas', 'arrows-rotate']"
                          class="me-1"
                        />
                          {{ !disableRequestOTP ? $t('resend') : countdownSeconds + ' ' + $t('seconds') }}
                        </button>
                      </div>

                    </div>

                    <!-- Button Login -->
                    <button 
                      type="button" 
                      class="btn btn-primary w-100 mb-2" 
                      :class="(loginW === 'email')?'':'d-none'"
                      @click="login()"
                    >
                      {{ $t('login') }}
                    </button>

                    <!-- แสดงปุ่ม ตรวจสอบรหัส OTP เมื่อมีรหัสอ้างอิง -->
                    <button
                      type="button"
                      class="btn btn-primary w-100 mb-2"
                      :class="OTPRef === null ? 'd-none' : (loginW === 'sms' ? '':'d-none')"
                      @click="validationOTP(0)"
                    >
                    <font-awesome-icon
                      :icon="['far', 'circle-check']"
                      class="me-1"
                    />
                      {{ $t('check_otp') }}
                    </button>
                    
                    <!-- แสดงผลปุ่ม ขอรับ OTP เมื่อสมาชิกกดเลือก Login with SMS และ ซ่อนปุ่ม Login -->
                    <button
                      type="button"
                      class="btn btn-primary w-100 mb-2"
                      :class="(loginW === 'sms')? (OTPRef === null ? '' : 'd-none'):'d-none'"
                      @click="handleReqOTP(0)"
                      :disabled="disableRequestOTP"
                    >
                    <font-awesome-icon
                      v-if="!disableRequestOTP"
                      :icon="['far', 'circle-check']"
                      class="me-1"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'arrows-rotate']"
                      class="me-1"
                    />
                      {{ !disableRequestOTP ? $t('request_otp') : countdownSeconds + ' ' + $t('seconds') }}
                    </button>

                    <div class="d-flex justify-content-between mb-4">
                      <button
                        type="button"
                        class="btn btn-link"
                        :class="(loginW === 'email')?'':'d-none'"
                        @click="loginW='sms'"
                      >
                        {{ $t('login_with_sms') }}
                      </button>
                      
                      <!-- แสดงผลปุ่ม Login With Email เมื่อสมาชิกกดเลือก Login with SMS และ ซ่อนปุ่ม Login With SMS -->
                      <button
                        type="button"
                        class="btn btn-link"
                        :class="(loginW === 'sms')?'':'d-none'"
                        @click="loginW='email'"
                      >
                        {{ $t('login_with_email') }}
                      </button>

                      <button
                        type="button"
                        class="btn btn-link"
                        data-bs-toggle="modal"
                        data-bs-target="#forgotPassword"
                      >
                        {{ $t('forgot_pw') }}
                      </button>
                    </div>
                    <button
                      type="button"
                      class="buttonFacebook btn w-100 text-white"
                      :class="(loginW === 'email')?'':'d-none'"
                      @click="loginFB()"
                    >
                    <font-awesome-icon
                      :icon="['fab', 'facebook-f']"
                      class="me-1"
                    />
                      {{ $t('signin_fb') }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade w-100"
                :class="{ show: activeTab === 'register', active: activeTab === 'register' }"
                id="register"
                role="tabpanel"
                aria-labelledby="registerTab"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="currentpass">{{ $t('displayname') }}</label>
                      <input 
                        type="text" 
                        class="form-control mb-4" 
                        id="displayName" 
                        :placeholder="$t('enter_display_name')" 
                        v-model="regisData.displayname"
                      />
                    </div>
                    <div class="form-group">
                      <label for="currentpass">{{ $t('name_surname') }}</label>
                      <input 
                        type="text" 
                        class="form-control mb-2" 
                        id="name" 
                        :placeholder="$t('enter_name_surname')" 
                        v-model="regisData.name_surname"
                      />
                    </div>
                    <div class="panel-body icon-pill tabs mt-4">
                      <ul class="nav nav-pills mt-3" id="icon-pills-tab" role="tablist">
                        <li class="nav-item" v-if="!regisFB">
                            <a
                              class="nav-link active"
                              id="icon-pills-home-tab"
                              data-bs-toggle="pill"
                              href="#icon-pills-home"
                              role="tab"
                              aria-controls="icon-pills-home"
                              aria-selected="true"
                            >
                              <font-awesome-icon :icon="['far', 'envelope']"  class="me-1" />
                              {{ $t('regis_with_email') }}
                              </a>
                        </li>
                        <li class="nav-item" v-if="!regisFB">
                            <a
                              class="nav-link"
                              data-bs-toggle="pill"
                              href="#icon-pills-profile"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <font-awesome-icon :icon="['fas', 'mobile-screen-button']" class="me-1" />
                              {{ $t('regis_with_mobile') }}
                            </a>
                        </li>
                        <!-- <li class="nav-item" v-if="regisFB">
                            <a
                              class="nav-link"
                              data-bs-toggle="pill"
                              href="#icon-pills-home"
                              role="tab"
                              aria-controls="icon-pills-home"
                              aria-selected="true"
                            >
                              <font-awesome-icon
                                :icon="['fab', 'facebook-f']"
                                class="me-1"
                              />
                                สมัครด้วย Facebook
                              </a>
                        </li> -->
                      </ul>
                      <div class="tab-content" id="icon-pills-tabContent">
                        <!-- Register with email -->
                        <div class="tab-pane fade show active w-100" id="icon-pills-home" role="tabpanel" aria-labelledby="icon-pills-home-tab">
                          <div class="form-group">
                            <label for="currentpass">{{ $t('email') }}</label>
                            <input 
                              type="email"
                              class="form-control mb-1" 
                              id="email" 
                              :placeholder="$t('put_email_for_regis')" 
                              v-model="regisData.email"
                              @keyup="keyUpValidateEmail(regisData.email)"
                              :disabled="regisFB"
                            />
                            <!-- alert Email -->
                            <div class="mt-1" v-show="errText.isErrEmail && regisData.email !== ''">
                              <span class="badge badge-danger w-100">
                                <small id="sh-text7" class="d-block mt-0 text-start">{{ $t('email_not_match') }}</small>
                              </span>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="newpass">{{ $t('password') }}</label>
                            <input 
                              type="password" 
                              class="form-control mb-2" 
                              id="password" 
                              :placeholder="$t('please_specify_pw')" 
                              v-model="regisData.password"
                              @keyup="keyUpValidatePass(regisData.password, confirmPass)"
                            />
                          </div>
                          <div class="form-group">
                            <label for="newpass">{{ $t('confirm_pw') }}</label>
                            <input 
                              type="password" 
                              class="form-control mb-2" 
                              id="cpassword" 
                              :placeholder="$t('enter_pw_again')" 
                              v-model="confirmPass"
                              @keyup="keyUpValidatePass(regisData.password, confirmPass)"
                            />
                          </div>

                          <button 
                            type="button" 
                            class="btn btn-primary w-100 mb-2"
                            @click="confirmRegisByEmail()"
                          >
                            <font-awesome-icon :icon="['far', 'circle-check']" class="me-1" /> {{ $t('confirm_regis') }}
                          </button>
                        </div>
                          
                        <!-- Register with SMS -->
                        <div class="tab-pane fade w-100" id="icon-pills-profile" role="tabpanel" aria-labelledby="icon-pills-profile-tab1">
                          <div class="form-group">
                            <label for="currentpass">{{ $t('mobile') }}</label>
                            <input
                              type="text"
                              class="form-control mb-1"
                              id="mobileno"
                              v-model="mobileNo"
                              :disabled="OTPRef !== null"
                              :placeholder="$t('put_mobile_for_otp')"
                            />
                          </div>

                          <!-- แสดงผลช่องกรอก OTP Passcode -->
                          <div class="form-group" :class="OTPRef === null ? 'd-none' : ''">
                            <label for="currentpass">{{ $t('otp_ref') }} {{OTPRef}}</label>
                            

                            <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control" 
                              id="mobileno" 
                              :placeholder="$t('otp_passcode')"
                              v-model="OTPPasscode"
                            />
                              <button
                                class="btn btn-outline-primary"
                                type="button"
                                @click="OTPRequest()"
                                :disabled="disableRequestOTP"
                              >
                                <font-awesome-icon
                                :icon="['fas', 'arrows-rotate']"
                                class="me-1"
                              />
                                {{ !disableRequestOTP ? $t('resend') : countdownSeconds + ' ' + $t('seconds') }}
                              </button>
                            </div>

                          </div>

                          <!-- แสดงปุ่ม ยืนยันการสมัคร เมื่อมีรหัส OTP -->
                          <button
                            type="button"
                            class="btn btn-primary w-100 mb-2"
                            :class="OTPRef === null ? 'd-none' : ''"
                            @click="validationOTP(1)"
                          >
                          <font-awesome-icon
                            :icon="['far', 'circle-check']"
                            class="me-1"
                          />
                            {{ $t('confirm_regis') }}
                          </button>

                          <button
                            type="button"
                            class="btn btn-primary w-100 mb-2"
                            :class="OTPRef === null ? '' : 'd-none'"
                            @click="handleReqOTP(1)"
                          >
                          <font-awesome-icon
                            :icon="['far', 'circle-check']"
                            class="me-1"
                          />
                            {{ $t('request_otp') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-link"
            @click="showPrivacyPolicyModal()"
          >
            <font-awesome-icon :icon="['fas', 'shield-halved']" class="me-1"  />
            {{ $t('privacy_policy') }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade csiteMenuModal reporterDetailModal authenAreaModal" id="forgotPassword" tabindex="-1" aria-labelledby="forgotPasswordLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered ">
      <div class="modal-content coinModalDetailArea">
        <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon :icon="['fas', 'circle-xmark']" /></button>
        <div class="modal-body">
          <h5>{{ $t('forgot_pw') }}</h5>
          <div class="form-group">
            <input
              type="text"
              class="form-control mb-1"
              id="forgotpassEmail"
              :placeholder="$t('enter_email_regis')"
              v-model="loginData.email"
            />
          </div>
          <span class="badge outline-badge-primary badge-classic">{{ $t('system_sent_email_detail') }}</span>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-warning"
            data-bs-toggle="modal"
            data-bs-target="#loginModal"
          >
            <font-awesome-icon
              :icon="['fas', 'arrow-left']"
              class="me-1"
            />
              {{ $t('txt_back') }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="sendMailForgotPassword(loginData.email)"
          >
            <font-awesome-icon
              :icon="['far', 'circle-check']"
              class="me-1"
            />
              {{ $t('confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // import zone
  import { onMounted, ref, computed } from 'vue';
  import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import eventBus from '@/eventBus/eventBus.js';
  import { useRouter } from 'vue-router';

  // variable zone
  const store = useStore();
  const emit = defineEmits(['callback']);
  const dialogRef = ref(null);
  const loginW = ref('email');
  const { t } = useI18n();
  const router = useRouter();

  const callRegis = (param) => store.dispatch('auth/register', param);
  const callLogin = (param) => store.dispatch('auth/loginMember', param);
  const callOTP = (param) => store.dispatch('auth/requestOTP', param);
  const callvalidateOTP = (param) => store.dispatch('auth/validateOTPLogin', param);
  const callLoginFB = (param) => store.dispatch('auth/loginFB', param);
  const callEmail = (param) => store.dispatch('auth/requestForgetPassword', param);

  let confirmPass = ref(null);
  const regisData = ref({
    device: 'web',
    displayname: '',
    name_surname: '',
    email: '',
    password: ''
  });

  let errText = ref({
    isErrEmail: false,
    isErrPass: false
  });

  // const inputType = computed(() => showPassword.value ? 'text' : 'password');
  // const showPassword = ref(false);

  const loginData = ref({
    email: '',
    password: ''
  });

  const activeTab = ref('loginForm');
  const regisFB = ref(false);
  const regisSMS = ref(null);

  const mobileNo = ref(null);
  const OTPRef = ref(null);
  const OTPPasscode = ref(null);
  const disableRequestOTP = ref(false);
  const countdownSeconds = ref(60);
  const forgotPasswordRef = ref(null);
  // end variable zone

  // function zone
  // const setshowPassword = () => {
  //   showPassword.value = !showPassword.value;
  // };
  const showPrivacyPolicyModal = () => {
    eventBus.emit('showPrivacyModal', { isHideBtnBack: false }); 
  };

  function showModal() {
    regisFB.value = false;
    regisSMS.value = null;
    mobileNo.value = null;
    OTPRef.value = null;
    OTPPasscode.value = null;

    regisData.value.device = 'web';
    regisData.value.displayname = '';
    regisData.value.name_surname = '';
    regisData.value.email = '';
    regisData.value.password = '';

    confirmPass.value = '';
    loginData.value.email = '';
    loginData.value.password = '';
    dialogRef.value?.show();
  }

  function hideModal() {
    dialogRef.value?.hide();
  }

  const setActiveTab = (tab) => {
    activeTab.value = tab;
    regisFB.value = false;
  };

  const generatePassword = () => {
    const length = 6; // Set the desired length of the password
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!^()_+[]{}|;:,.<>'; // Define the characters to use

    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    return password;
  };

  // Register Zone ============================================================================================================
  function validation (regisData) {
    try {
      // Check displayname
      if (regisData.displayname === null || regisData.displayname.trim() === '') {
          alertWarningMsg(t('please_specify_displayname'));
          return false;
      }

      if (mobileNo.value !== null && mobileNo.value !== '') {
        return true;
      }

      // Email not null
      if (regisData.email === null || regisData.email.trim() === '') {
          alertWarningMsg(t('please_specify_email'));
          return false;
      }

      if (regisData.email) {
          // Check format email
          if (!validateEmail(regisData.email)) {
              alertWarningMsg(t('email_not_match'));
              return false;
          } else {
              regisData.email = regisData.email.toLowerCase();
          }
      }

      // Check password
      if (!(regisData.password) || regisData.password.trim() === '' || confirmPass.value.trim() === '') {
          alertWarningMsg(t('put_pass_and_confirm'));
          return false;
      }

      if (errText.value.isErrPass) {
          alertWarningMsg(t('pw_and_confirm_pw_not_match'));
          return false;
      } else if (confirmPass.value.length < 4) {
          alertWarningMsg(t('pw_must_4'));
          return false;
      }

      return true;
    } catch (error) {
        return false;
    }
  }

  async function confirmRegisByEmail () {
    try {
      if (validation(regisData.value)) {
        const confirm = await new window.Swal({
          icon: 'warning',
          title: t('txt_alert'),
          text: t('confirm_regis'),
          showCancelButton: true,
          confirmButtonText: t('submit'),
          cancelButtonText: t('cancel'),
          reverseButtons: true
        });
        if (confirm.isConfirmed) {
          // await callLogin(regisData.value);
          const res = await callRegis(regisData.value);
          if (res.status === 200) {
            const confirm = await new window.Swal({
              icon: 'success',
              title: t('txt_alert'),
              text: t('regis_success'),
              showCancelButton: false,
              confirmButtonText: t('submit'),
              reverseButtons: true,
              allowOutsideClick: false
            });
            if (confirm.isConfirmed) {
              hideModal();
              router.go();
            }
          } else {
            showModal();
            alertWarningMsg(t('wrong'));
          }
        }
      }
    } catch (e) {
        alertWarningMsg(t('wrong'));
    }
  }

  function keyUpValidateEmail(email) {
    try {
        errText.value.isErrEmail = !validateEmail(email);
    } catch (error) {
        return false;
    }
  }

  // Check email
  function validateEmail(email) {
    try {
      // regular expression pattern for email validation
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // use the test() method of the pattern to check if the email matches the pattern
      return (pattern.test(email)) ? true : false;
    } catch (error) {
      return false;
    }
  }

  function keyUpValidatePass(password, confirmPass) {
    try {
        errText.value.isErrPass = !checkConfirmPass(password, confirmPass);
    } catch (error) {
        return false;
    }
  }

  // Check same password
  function checkConfirmPass(password, confirmPass) {
    try {
      if (password === confirmPass) {
          return true;
      } else {
          return false;
      }
    } catch (error) {
      return false;
    }
  }
  // Register Zone ============================================================================================================


  // Login Zone ===============================================================================================================
  function validationLogin () {
    if (loginData.value.email === '' || loginData.value.email === null) {
      alertWarningMsg(t('please_specify_email_tel'));
      return false;
    }

    if (loginData.value.password === '' || loginData.value.email === null) {
      alertWarningMsg(t('please_specify_pw'));
      return false;
    }

    return true;
  }

  async function login () {
    try {
      if (validationLogin(loginData.value)) {
        const res = await callLogin(loginData.value);
        if (res.status === 200) {
          const confirm = await new window.Swal({
            icon: 'success',
            title: t('txt_alert'),
            text: t('login_success'),
            showCancelButton: false,
            confirmButtonText: t('submit'),
            reverseButtons: true,
            allowOutsideClick: false
          });
          if (confirm.isConfirmed) {
            hideModal();
            router.go();
          }
        } else {
          // showModal();
          if (res.data.message) {
            const msg = ($t(res.data.message)) ? $t(res.data.message) : res.data.message;
            alertWarningMsg(msg);
          } else {
            alertWarningMsg(t('wrong'));
          }
        }
      }
    } catch (e) {
        alertWarningMsg(t('email_or_pass_wrong'));
    }
  }

  const loginFB = async () => {
    try {
      const response = await new Promise((resolve) => {
        FB.login(resolve, {
          scope: 'email',
          return_scopes: true
        });
      });

      const fbId = response.authResponse.userID;
      const token = response.authResponse.accessToken;

      if (response.authResponse) {
        const apiResponse = await new Promise((resolve) => {
          FB.api('/' + fbId + '/', { fields: "email,name", access_token: token }, resolve);
        });

        if (apiResponse.email) {
          const memberFBData = {
            facebookID: apiResponse.id,
            facebookEmail: apiResponse.email.toLowerCase(),
            facebookToken: token
          };

          const res = await callLoginFB(memberFBData);

          if (res.data?.member?.type === 3) {
            const confirm = await new window.Swal({
              icon: 'warning',
              title: t('txt_alert'),
              text: t('not_member_yet'),
              showCancelButton: false,
              confirmButtonText: t('submit'),
              reverseButtons: true
            });
            if (confirm.isConfirmed) {
              setActiveTab('register');
              regisFB.value = true;
              regisData.value.email = memberFBData.facebookEmail;
              regisData.value.facebookEmail = memberFBData.facebookEmail;
              regisData.value.facebookID = memberFBData.facebookID;
              regisData.value.facebookToken = memberFBData.facebookToken;
              regisData.value.displayname = apiResponse.name ? apiResponse.name: '';
            }
          } else if (res && res.status === 200) {
            const confirm = await new window.Swal({
              icon: 'success',
              title: t('txt_alert'),
              text: t('login_success'),
              showCancelButton: false,
              confirmButtonText: t('submit'),
              reverseButtons: true,
              allowOutsideClick: false
            });
            if (confirm.isConfirmed) {
              hideModal();
              router.go();
            }
          } else {
            showModal();
            if (res.data.message) {
              const msg = ($t(res.data.message)) ? $t(res.data.message) : res.data.message;
              alertWarningMsg(msg);
            } else {
              alertWarningMsg(t('wrong'));
            }
          }
        } else {
          alertWarningMsg(t('facebook_not_have_mail'));
        }
      }
    } catch (error) {
      // console.log('error login facebook', error);
    }
  };

  // Login Zone ===============================================================================================================

  // OTP Zone ===============================================================================================================
  // Function to get the current timestamp
  const getCurrentTimestamp = () => Math.floor(Date.now() / 1000);

  // Check if the last update timestamp is more than 24 hours ago
  const isLastUpdateMoreThan24HoursAgo = () => {
    const lastUpdateTimestamp = localStorage.getItem('lastUpdateTimestamp');
    if (lastUpdateTimestamp) {
      const currentTime = getCurrentTimestamp();
      return currentTime - lastUpdateTimestamp > 24 * 60 * 60; // 24 hours in seconds
    }
    return true; // If no timestamp is found, consider it as more than 24 hours ago
  };

  // Method to set regisSMS
  const setRegisSMS = (value) => {
    regisSMS.value = value;
  };

  // Method to handle button click
  const handleReqOTP = (isRegis) => {
    // Set regisSMS to 0 or 1
    setRegisSMS(parseInt(isRegis));

    // Call the OTPRequest method
    OTPRequest();
  };

  const countdownInterval = (initialCountdown) => {
    let countdown = initialCountdown;

    const countdownInterval = setInterval(() => {
      countdownSeconds.value = countdown;
      countdown--;

      if (countdown < 0) {
        clearInterval(countdownInterval);
        disableRequestOTP.value = false;
        countdownSeconds.value = 60;
      }
    }, 1000);
  };
  
  const validationMobile = (phoneNumber) => {
    // Check if the phone number is exactly 10 digits
    if (/^\d{10}$/.test(phoneNumber)) {
      return true;
    } else {
      // Invalid phone number
      alertWarningMsg(t('tel_not_match'));
      return false;
    }
  }

  const validationPasscode = (passcode) => {
    // Check if the phone number is exactly 10 digits
    if (/^\d{6}$/.test(passcode)) {
      return true;
    } else {
      // Invalid Passcode
      alertWarningMsg(t('invalid_passcode'));
      return false;
    }
  }
  
  const OTPRequest = async () => {
    try {
      if (!disableRequestOTP.value) {
        const telephone = mobileNo.value;

        if (validationMobile(telephone)) {
          let requestCnt = parseInt(localStorage.getItem('requestCnt')) || 0;

          // Reset requestCnt if last update is more than 24 hours ago
          if (isLastUpdateMoreThan24HoursAgo()) {
            requestCnt = 0;
            localStorage.setItem('requestCnt', requestCnt); // Reset requestCnt
          } else {
            if (requestCnt > 10) {
              alertWarningMsg(t('limit_otp'));
              return;
            }
          }

          const tmp = {
            telephone: telephone,
            isRegis: regisSMS.value,
            otpRef: '',
            requestCnt: requestCnt
          };

          const res = await callOTP(tmp)

          // const res = {
          //   status: 200,
          //   data: {
          //     result: {
          //       otp_ref: 'test'
          //     },
          //     message: 'test yoo'
          //   }
          // };

          if (res.status === 200 && res.data && res.data.result) {
            OTPRef.value = res.data.result.otp_ref;

            // Update the last update timestamp
            localStorage.setItem('lastUpdateTimestamp', getCurrentTimestamp());

            // Disable the button for 60 seconds
            disableRequestOTP.value = true;

            // Start countdown
            countdownInterval(60);

            if (requestCnt && OTPRef.value !== '') {
              requestCnt++;
              localStorage.setItem('requestCnt', requestCnt);
            } else {
              requestCnt = 1;
              localStorage.setItem('requestCnt', requestCnt);
            }
          } else {
            if (res.data?.message === 'not found') {
              const confirm = await new window.Swal({
                icon: 'warning',
                title: t('txt_alert'),
                text: t('tel_not_regis'),
                showCancelButton: false,
                confirmButtonText: t('submit'),
                reverseButtons: true
              });
              if (confirm.isConfirmed) {
                document.getElementById('registerTab').click();
              }
            } else {
              if (res.data.message) {
                const msg = ($t(res.data.message)) ? $t(res.data.message) : res.data.message;
                alertWarningMsg(msg);
              } else {
                alertWarningMsg(t('wrong'));
              }
            }
          }
        }
      } 
    } catch (error) {
      // console.log(error);
    }
  }

  const validationOTP = async (isRegis) => {
    try {
      const passcode = OTPPasscode.value;
      if (validationPasscode(passcode)) {
        const tmp = {
          telephone: mobileNo.value,
          isRegis: parseInt(isRegis),
          otpRef: OTPRef.value,
          otpPasscode: passcode
        };
 
        const res = await callvalidateOTP(tmp)

        if (res.status === 200) {
          if (tmp.isRegis === 0) {
            const confirm = await new window.Swal({
              icon: 'success',
              title: t('txt_alert'),
              text: t('login_success'),
              showCancelButton: false,
              confirmButtonText: t('submit'),
              reverseButtons: true,
              allowOutsideClick: false
            });
            if (confirm.isConfirmed) {
              hideModal();
              router.go();
            }
          } else if (tmp.isRegis === 1) {
            regisData.value.telephone = mobileNo.value;
            regisData.value.password = generatePassword();
            await confirmRegisByEmail();
          }
        } else {
          showModal();
          if (res.data.message) {
            const msg = ($t(res.data.message)) ? $t(res.data.message) : res.data.message;
            alertWarningMsg(msg);
          } else {
            alertWarningMsg(t('wrong'));
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
  // OTP Zone ===============================================================================================================

  // Send mail Zone ===============================================================================================================

  const sendMailForgotPassword = async (mail) => {
    if (validateEmail(mail)) {
      const tmp = {
        email: mail
      };

      const result = await callEmail(tmp);
      if (result && result.status === 200) {
        alertWarningMsg(t('system_sent_email_detail'));
        // alain.gusarea@gmail.com
        hideForgotPasswordModal();
      } else {
        alertWarningMsg(t('email_not_found'));
      }
    } else {
      alertWarningMsg(t('email_not_match'));
    }
  };

  const hideForgotPasswordModal = () => {
    if (forgotPasswordRef.value) {
      forgotPasswordRef.value.hide();
    }
  }

  // Send mail Zone ===============================================================================================================

  function alertWarningMsg (txt) {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
        // console.log(error);
    }
  }

  onMounted(() => {
    dialogRef.value = new bootstrap.Modal(document.getElementById('loginModal'), {});
    forgotPasswordRef.value = new bootstrap.Modal(document.getElementById('forgotPassword'), {});

    // init event bus
    eventBus.on('showLoginModal', () => {
      showModal();
    });

    eventBus.on('hideLoginModal', () => {
      hideModal();
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>