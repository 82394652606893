<template>
  <!-- Add news Modal-->
  <div class="modal fade" id="addNewsModal" tabindex="-1" role="dialog" aria-labelledby="addNewsModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <font-awesome-icon :icon="['far', 'square-plus']" class="text-black" /> {{
              (topicID === 0) ? $t('add_post') : $t('edit_post') }}
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="hideModal()"></button>
        </div>
        <div class="modal-body">
          <div class="btn-group mb-4" role="group">
            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio1"
              autocomplete="off"
              :checked="topicData.publish === 0 && topicData.anonymous === 0" @click="setStutus(0, 0)"
              :disabled="isAnonymous"
            >
            <label class="btn btnGroupRadio" for="btnradio1">{{ $t('post_public') }}</label>

            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio2"
              autocomplete="off"
              :checked="topicData.publish === 0 && topicData.anonymous === 10" @click="setStutus(0, 10)"
              :disabled="isAnonymous"
            >
            <label class="btn btnGroupRadio" for="btnradio2">{{ $t('public_anonymous') }}</label>

            <input
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio3"
              autocomplete="off"
              :checked="topicData.publish === 10 && topicData.anonymous === 0" @click="setStutus(10, 0)"
              :disabled="isAnonymous"
            >
            <label class="btn btnGroupRadio" for="btnradio3">{{ $t('post_private') }}</label>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="example_lable">{{ $t('post_header') }}</label>
            <div>
              <input type="text" id="example_lable" class="form-control" :placeholder="$t('post_header')"
                v-model="topicData.header" />
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="example_lable">{{ $t('detail') }}</label>
            <div>
              <textarea class="form-control" :placeholder="$t('detail')" v-model="topicData.detail"></textarea>
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="add_news_gps">{{ $t('select_location') }}</label>
            <div>
              <input type="text" class="pac-target-input form-control mb-2" :placeholder="$t('put_search_location')"
                ref="placeInput" />

              <div class="input-group mb-2">
                <span class="input-group-text">{{ $t('search_from') }} {{ $t('lat') }}</span>
                <input
                  type="text"
                  class="form-control"
                  v-model.number="topicData.latitude"
                  @keyup.enter="updateMarkerFromInput"
                />
                <span class="input-group-text">{{ $t('lng') }}</span>
                <input
                  type="text"
                  class="form-control"
                  v-model.number="topicData.longitude"
                  @keyup.enter="updateMarkerFromInput"
                />
              </div>

              <div :id="mapID" style="width: 100%; height:300px;"></div>
              <!-- <iframe style="width:100%; height:300px; border: 0;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.106111594403!2d100.43476707587457!3d13.892602886514092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e28ffe817c2555%3A0xa09ddf08e754ba9!2sGus%20Area%20Co.%2CLtd.!5e0!3m2!1sth!2sth!4v1704688375827!5m2!1sth!2sth" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="example_lable">{{ $t('category') }}</label>
            <div>
              <select v-model="topicData.categoryID" class="form-select">
                <option v-for=" (list, index) in category" :value="list.category_id">{{ list.category_name }}</option>
              </select>
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="add_news_hashtag">{{ $t('hashtag') }}</label>
            <div>
              <input type="text" id="add_news_hashtag" class="form-control" :placeholder="$t('hashtag_placeholder')"
                v-model="hashtag" @keydown="addHashtag" />
            </div>

            <div v-if="topicData.tag && topicData.tag.length > 0" v-for="(list, key) in topicData.tag"
              class="d-inline me-1">
              <span class="badge badge-pills badge-primary px-2 imgClick">{{ list }}
                <font-awesome-icon :icon="['fas', 'times']" @click="removeHashtag(key)" />
              </span>
            </div>
          </div>


          <div class="mb-4">
            <label
              class="col-form-label"
              for="add_news_hashtag"
            >
              Upload {{ $t('photo') }}
            </label>
            <div class="row d-flex align-items-center justify-content-between">
              <div class="col-12">
                <div class="uploadImageArea">
                  <PreviewimgUpload
                    idInputfile="upload_img_topic"
                    :imgList="topicImg"
                    :isView="false"
                    fnUploadName="uploadTopicImg"
                    fnServicedName="uploadFileService"
                    :maxFileUpload="maxFileUpload"
                    :countFileUpload="topicImg.length + (topicVdo.fileName === null ? 0 : 1)" @resRemoveFile="resRemoveImg">
                  </PreviewimgUpload>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="add_news_hashtag">Upload {{ $t('vdo') }}</label>
            <div class="row d-flex align-items-center justify-content-between">
              <div class="col-12">
                <div class="uploadImageArea">
                  <PreviewvdoUpload
                    idInputfile="upload_vdo_topic"
                    :vdoList="topicVdo"
                    :isView="false"
                    fnUploadName="uploadTopicVdo"
                    fnServicedName="uploadFileService"
                    :maxFileUpload="maxFileUpload"
                    :countFileUpload="topicImg.length + (topicVdo.fileName === null ? 0 : 1)" @resRemoveFile="resRemoveVdo">
                  </PreviewvdoUpload>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer d-block">
          <div class="row d-flex align-items-center justify-content-between text-center">
            <div class="col-auto">
              <button type="button" class="btn btn-danger" @click="deleteTopicData()" v-show="topicID != 0">
                <font-awesome-icon :icon="['fas', 'trash']" class="me-1" /> {{ $t('delete') }}
              </button>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-success" @click="saveTopicData()">
                <font-awesome-icon :icon="['fas', 'floppy-disk']" class="me-1" /> {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Add news Modal-->
</template>

<script setup>
  import { onMounted, ref, getCurrentInstance, watch } from 'vue';
  import PreviewimgUpload from '@/components/PreviewTopicimgUpload.vue';
  import PreviewvdoUpload from '@/components/PreviewTopicvdoUpload.vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';

  import eventBus from '@/eventBus/eventBus.js';
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const emit = defineEmits(['showLoadingModal', 'hideLoadingModal']);

  // valiable zone
  const addTopicModalRef = ref(null);
  let gmap = null;
  let mapID = 'mapID_' + Math.floor(Math.random() * 1000);
  let autocomplete = null;
  const mapOptions = {
    center: {
      lat: 13.770413145765712,
      lng: 100.52020971523046
    },
    zoom: 6,
    disableDefaultUI: true,
    mapId: '73e4ca36a9ac7c3d'
  };
  const placeInput = ref(null);
  let currentLatlng = ref({ lat: null, lng: null });
  let markerPosition = ref(null);
  const mapCenter = ref({ lat: 13.770413145765712, lng: 100.52020971523046 });

  const topicData = ref({
    categoryID: 1,
    header: '',
    detail: '',
    latitude: null,
    longitude: null,
    publish: null,
    anonymous: null,
    img: [],
    tag: []
  });

  const category = ref([]);

  const topicImg = ref([]);
  const topicVdo = ref({
    fileID: null,
    url: null,
    fileName: null
  });
  const maxFileUpload = ref(8);

  const hashtag = ref('');

  const maxCountFunc = ref(1);
  const countFunc = ref(0);

  const topicID = ref(0);

  const instance = getCurrentInstance();
  const { lastTopicID, s3DevUrl, s3RedesignUrl } = instance.appContext.config.globalProperties.$globalVar;
  const imgDelID = ref([]);
  const vdoDelID = ref('');

  const previousData = ref({
    id: null,
    lastPage: ''
  });
  const isAnonymous = ref(false);
  // end valiable zone

  // function zone
  const showModal = (postID) => {
    topicID.value = postID;
    showLoadingModal();
    getCategory();
    initTopicData();

    if (topicID.value === 0) {
      const lat = 13.770413145765712;
      const lng = 100.52020971523046;
      updateMarkerPosition(lat, lng);
      gmap.setCenter(new google.maps.LatLng(lat, lng));
    }

    if (addTopicModalRef.value) {
      addTopicModalRef.value.show();
    }
  };

  const hideModal = () => {
    if (addTopicModalRef.value) {
      addTopicModalRef.value.hide();
    }

    if (topicID.value && topicID.value > 0) {
      if (previousData.value.lastPage === 'myTopic') {
        eventBus.emit('showMyTopiclistModal'); // show my topic modal
      } else {
        eventBus.emit('showMyPostVdoListModal'); // show my vdo modal
      }
    }
  };

  // map zone ===========================================================================================
  const initMapLoader = () => {
    try {
      const instance = getCurrentInstance();
      if (instance) {
        const googleMapsLoader = instance.appContext.config.globalProperties.$googleMapsLoader;

        googleMapsLoader.load().then(() => {
          gmap = new google.maps.Map(document.getElementById(mapID), mapOptions);

          const options = {
            fields: ["formatted_address", "geometry", "name"],
            // fields: ["geometry", "name", "address", "establishment"],
            // fields: [],
            strictBounds: false,
            types: ["establishment"],
            componentRestrictions: { country: ["th"] },
          };

          autocomplete = new google.maps.places.Autocomplete(placeInput.value, options);

          google.maps.event.addListener(autocomplete, "place_changed", () => {
            placesearch(autocomplete.getPlace());
          });

          const latlng = new google.maps.LatLng(mapCenter.value.lat, mapCenter.value.lng);
          markerPosition.value = new google.maps.Marker({
            position: latlng,
            map: gmap
          });

          // handle event click map
          gmap.addListener("click", (mapsMouseEvent) => {
            changeMarkerPosition(mapsMouseEvent);
          });

          currentLocation();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      if (position && position.coords) {
        currentLatlng.value.lat = position.coords.latitude;
        currentLatlng.value.lng = position.coords.longitude;
      }
    });
  };

  const placesearch = (place) => {
    if (place.geometry) {
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
      updateMarkerPosition(lat, lng);
      updateMapCenter(lat, lng);
      setLatLngModalVal(lat, lng);
    }
  };

  const changeMarkerPosition = (e) => {
    let lat = parseFloat(e.latLng.lat().toFixed(7));
    let lng = parseFloat(e.latLng.lng().toFixed(7));

    updateMarkerPosition(lat, lng);
    setLatLngModalVal(lat, lng);
  };

  const updateMarkerPosition = (lat, lng) => {
    if (markerPosition.value) {
      const latlng = new google.maps.LatLng(lat, lng);
      markerPosition.value.setPosition(latlng);
    }
  };

  const updateMapCenter = (lat, lng) => {
    const latlng = new google.maps.LatLng(lat, lng);
    gmap.setCenter(latlng);
    gmap.setZoom(13);
  };

  const setLatLngModalVal = (lat, lng) => {
    topicData.value.latitude = lat;
    topicData.value.longitude = lng;
  };

  const updateMarkerFromInput = () => {
    updateMarkerPosition(topicData.value.latitude, topicData.value.longitude);
    updateMapCenter(topicData.value.latitude, topicData.value.longitude);
  }
  // end map zone ===========================================================================================

  // Hashtag zone =======================================================================================
  const addHashtag = (event) => {
    if (event.keyCode === 32) {
      if (hashtag.value && hashtag.value.trim() !== '') {
        let trimHashtag = hashtag.value.trim();
        if (!trimHashtag.startsWith('#')) {
          trimHashtag = '#' + trimHashtag;
        }
        topicData.value.tag.push(trimHashtag);
      }
      hashtag.value = '';
    }
  };

  const removeHashtag = (idx) => {
    topicData.value.tag.splice(idx, 1);
  };
  // end Hashtag zone =======================================================================================

  // topic zone =============================================================================================
  const initTopicData = async () => {
    let dataSet = null;
    try {
      if (topicID.value !== 0) {
        const res = await callApi({
          svName: 'topicService',
          fnName: 'getMyTopicByID',
          param: { _id: topicID.value }
        });

        if (res.status == 200) {
          dataSet = res.data.result;
          setDataTopicAddEdit(dataSet);
        }
      } else {
        setDataTopicAddEdit(dataSet);
      }
      setTimeout(function () {
        eventBus.emit('hideLoadingModal'); 
    }, 500);
    } catch (e) {
      console.log(e);
      alertWarningMsg(t('wrong'));
    }
  };

  const cleanData = () => {
    placeInput.value.value = null;
    topicData.value._id = null;
    topicData.value.categoryID = 0;
    topicData.value.header = '';
    topicData.value.detail = '';
    topicData.value.latitude = null;
    topicData.value.longitude = null;
    topicData.value.publish = 0;
    topicData.value.anonymous = 0;
    topicData.value.img = [];
    topicData.value.tag = [];

    imgDelID.value = [];
    vdoDelID.value = '';
    topicImg.value = [];
    topicVdo.value = {
      fileID: null,
      url: null,
      fileName: null
    };

    delete topicData.value.post_img_del_req;
    delete topicData.value.post_vdo_del_req;
    isAnonymous.value = false;
  };

  const setDataTopicAddEdit = (obj) => {
    cleanData();
    if (obj) {
      topicData.value._id = topicID.value;
      topicData.value.categoryID = obj.post_category_id;
      topicData.value.header = obj.post_header;
      topicData.value.detail = obj.post_detail;
      topicData.value.latitude = obj.post_latitude;
      topicData.value.longitude = obj.post_longitude;

      topicData.value.publish = Number(obj.post_publish);
      topicData.value.anonymous = Number(obj.post_anonymous);
      topicData.value.img = obj.img;
      topicData.value.vdo = obj.vdo[0];

      for (let tag = 0; tag < obj.tag.length; tag++) {
        if (topicData.value.tag.length < obj.tag.length) {
          topicData.value.tag.push(obj.tag[tag].post_tag_detail);
        }
      }

      for (let j = 0; j < topicData.value.img.length; j++) {
        topicData.value.img[j].url =  topicData.value.img[j].post_img_thumb_path;
        topicData.value.img[j].fileName = topicData.value.img[j].post_img_original_file;
      }

      if (topicData.value.vdo) {
        topicData.value.vdo.url = topicData.value.vdo.post_video_path;
        topicData.value.vdo.fileName = topicData.value.vdo.post_video_original_path;
        topicVdo.value = topicData.value.vdo;
      }

      topicImg.value = topicData.value.img;

      if (topicData.value.latitude && topicData.value.longitude) {
        const lat = topicData.value.latitude;
        const lng = topicData.value.longitude;
        updateMarkerPosition(lat, lng);
        gmap.setCenter(new google.maps.LatLng(lat, lng));
      }

      if (topicData.value.publish === 0 && topicData.value.anonymous === 10) {
        isAnonymous.value = true;
      }
    }
  };

  const saveTopicData = async () => {
    if (validatedataTopic()) {
      try {
        // set data image for save
        topicData.value.img = [];
        if (topicImg.value.length > 0) {
          for (let i = 0; i < topicImg.value.length; i++) {
            if (!topicImg.value[i].post_id) {
              const obj = topicImg.value[i].fileName;
              topicData.value.img.push(obj);
            }
          }
        }

        if (topicData.value.img.length === 0) {
          delete topicData.value.img;
        }

        // set data image for save
        topicData.value.vdo = ''
        if (topicVdo.value) {
          if (!topicVdo.value.post_id && topicVdo.value.fileName) {
            topicData.value.vdo = topicVdo.value.fileName;
          }
        }

        if (topicData.value.vdo === '') {
          delete topicData.value.vdo;
        }

        if (imgDelID.value.length > 0) {
          topicData.value.post_img_del_req = imgDelID.value;
        }

        if (vdoDelID.value !== '') {
          topicData.value.post_vdo_del_req = vdoDelID.value;
        }

        const fnName = topicData.value._id == null ? 'addPrivateTopic' : 'updateMyTopic';
        const obj = {
          svName: 'topicService',
          fnName,
          param: topicData.value
        }
        const confirm = await new window.Swal({
          icon: 'warning',
          title: t('txt_alert'),
          text: t('confirm_save'),
          showCancelButton: true,
          confirmButtonText: t('submit'),
          cancelButtonText:  t('cancel'),
          reverseButtons: true
        });

        if (confirm.isConfirmed) {
          showLoadingModal();

          const result = await callApi(obj);
          if (result.status === 200) {
            if (addTopicModalRef.value) {
              addTopicModalRef.value.hide();
            }

            if (topicID.value === 0) {
              window.location.reload();
            } else {
              if (previousData.value.lastPage === 'myTopic') {
                eventBus.emit('showMyTopiclistModal'); // show my topic modal
              } else {
                eventBus.emit('showMyPostVdoListModal'); // show my vdo modal
              }
            }
          } else {
            alertWarningMsg(t('wrong'));
          }
          hideLoadingModal();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validatedataTopic = () => {
    if (topicData.value.header === null || topicData.value.header.trim() === '') {
      alertWarningMsg(t('please_specify_header'));
      return false;
    }

    if (topicData.value.detail === null || topicData.value.detail.trim() === '') {
      alertWarningMsg(t('please_specify_detail'));
      return false;
    }

    if (topicData.value.latitude === null || topicData.value.latitude === '') {
      alertWarningMsg(t('please_specify_latlng'));
      return false;
    }

    if (topicData.value.longitude === null || topicData.value.longitude === '') {
      alertWarningMsg(t('please_specify_latlng'));
      return false;
    }

    if (topicData.value.categoryID === 0) {
      alertWarningMsg(t('please_specify_category'));
      return false;
    }

    if (topicImg.value.length == 0) {
      alertWarningMsg(t('must_add_photo'));
      return false;
    }

    const totalImgVdo = (topicVdo.value === null) ? topicImg.value.length : topicImg.value.length + 1;
    if (totalImgVdo > 8) {
      alertWarningMsg(t('upload_max_8'));
      return false;
    }

    return true;
  };

  const deleteTopicData = async () => {
    try {
      if (topicID.value) {
        const query = {
          _id: topicID.value
        }
        const confirm = await new window.Swal({
          icon: 'warning',
          title: t('txt_alert'),
          text: t('confirm_del'),
          showCancelButton: true,
          confirmButtonText: t('submit'),
          cancelButtonText: t('cancel'),
          reverseButtons: true
        });
        if (confirm.isConfirmed) {
          const res = await callApi({
            svName: 'topicService',
            fnName: 'deleteMyTopic',
            param: query
          });
          if (res.status == 200) {
            new window.Swal({
              icon: 'success',
              title: t('txt_alert'),
              text: t('delete_success'),
              confirmButtonText: t('submit')
            });
            setTimeout(function () { hideModal(); }, 1000);
            if (previousData.value.lastPage === 'myTopic') {
              eventBus.emit('showMyTopiclistModal'); // show my topic modal
            } else {
              eventBus.emit('showMyPostVdoListModal'); // show my vdo modal
            }
          } else {
            alertWarningMsg(t('wrong'));
          }
        }
      }
    } catch (e) {
      console.log(e);
      alertWarningMsg(t('wrong'));
    }
  };

  const setStutus = (publish, anonymous) => {
    topicData.value.publish = publish;
    topicData.value.anonymous = anonymous;
  };

  // end topic zone =========================================================================================
  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      const res = await callApi({
        svName: 'publicService',
        fnName: 'getCategory'
      });
      if (res && res.status === 200) {
        category.value[0] = {
          category_id: 0,
          category_name: t('please_specify_category')
        }
        for (let i = 0; i < res.data.result.length; i++) {
          const obj = res.data.result[i];
          category.value.push(obj);
        }
        countFunc.value++;
        checkCount();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showLoadingModal = () => {
    eventBus.emit('showLoadingModal');
  };

  const hideLoadingModal = () => {
    eventBus.emit('hideLoadingModal'); 
  };

  const checkCount = () => {
    if (countFunc.value === maxCountFunc.value) {
      setTimeout(() => {
        hideLoadingModal();
      }, 500);
    }
  };

  const resRemoveVdo = (fileID) => {
    if (fileID) {
      vdoDelID.value = fileID;
    }
  };

  const resRemoveImg = (fileID) => {
    if (fileID) {
      imgDelID.value.push(fileID);
    }
  };
  // end function zone

  onMounted(() => {
    addTopicModalRef.value = new bootstrap.Modal(document.getElementById('addNewsModal'), {});
    initMapLoader();

    // init event bus
    eventBus.on('showAddTopicModal', (obj) => {
      previousData.value = obj;
      showModal(previousData.value.id);
    });
  });

  defineExpose({
    showModal,
    hideModal
  });
</script>